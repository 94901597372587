// React
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// External libraries
import CalculateIcon from '@mui/icons-material/Calculate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button } from 'antd';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Local imports
import { GlobalContextType, User } from '../@types/user';
import { Axios } from '../Axios';
import { globalData } from '../context/globalContext';
import { RootState } from '../redux/store';
import { logout } from '../redux/userSlice';
import logout_logo from '../svg/logout.svg';
import Flynaut from './branding/Flynaut';
import ModalBox from './modal';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {

    const [endpoint] = useState(window.location.pathname);
    const { activeTab, setActiveTab } = useContext(globalData) as GlobalContextType;
    const user = useSelector((state: RootState) => state.user.user) as User | null;
    const [ mobMenuState, setMobMenuState ] = useState<boolean | null>(null); // for opening or closing menu
    const [ signoutConfirm, setSignoutConfirm ] = useState(false);
    const menuHolder = useRef<HTMLDivElement>(null);
    const innerMenuHolder = useRef<HTMLDivElement>(null);
    const [dropDownMenu,setDropDownMenu] = useState(false);
    const [openChangePassword, setOpenchangePassword] = useState(false);
    const [currPass,setCurrPass] = useState("");
    const [newPass,setNewPass] = useState("");
    const [cnfPass,setCnfPass] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // signout function
    const signOut = ()=>{

        Axios.post(`/api/v1/signout`,{},{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=> {

        })
        .catch(err=>{

        })

        dispatch( logout() );
    }


    // hiding active mobile menu when switched to pc mode
    useEffect(()=>{

        window.addEventListener("resize",resizeFunc);

        return ()=>{
            window.removeEventListener("resize",resizeFunc);
        }

    },[]);


    const resizeFunc = ()=>{
        if(window.innerWidth >=800){
            setMobMenuState(false);
        }
    }

    // hiding or showing menu based on mobMenuState value.
    useEffect(()=>{
        if(mobMenuState === null) return;

        if(mobMenuState && menuHolder.current && innerMenuHolder.current){
            menuHolder.current && (menuHolder.current.style.display = "flex");
            setTimeout(()=>{
                innerMenuHolder.current && (innerMenuHolder.current.style.transform = "translateX(0)");
            },100);
        }
        else if(!mobMenuState && menuHolder.current && innerMenuHolder.current){
            innerMenuHolder.current && (innerMenuHolder.current.style.transform = "translateX(-100%)");
            setTimeout(()=>{
                menuHolder.current && (menuHolder.current.style.display = "none");
            },500);
        }

    },[mobMenuState]);
    

    // Redirection function.
    const redirectHandler = (num:number)=>{
       
        setActiveTab(num);

        if(num === 0){
            navigate("/home");
        }
        else if(num === 1){
            navigate("/histories");
        }
        else if(num === 2){
            navigate("/users");
        }
        else if(num === 3){
            navigate("/settings");
        }

        
    }

    return(
        <>
        <div id="header">
            {/* <img className="logo" src="/images/cco-small.png" alt="brand_logo" /> */}

            <Button className='menuBtn' onClick={()=> setMobMenuState(true)}>
                    <MenuIcon />
            </Button>


            <Flynaut className="logo" />

            <div className="pcLinks">
                <ul>
                    <li className={endpoint === "/home" ? `active` : ''} onClick={()=> redirectHandler(0) }>
                        <CalculateIcon />
                        QL inputs
                    </li>
                    <li className={endpoint === "/histories" ? `active` : ''} onClick={()=> redirectHandler(1) }>
                        <ManageHistoryIcon />
                        Histories
                    </li>
                </ul>

                <div className='authUserDisplayCnt'>
                    <div className='authUserDisplayTop' onClick={()=> setDropDownMenu(prev=> !prev)}>
                        <p>{user?.name}</p>
                        <KeyboardArrowDownIcon />
                    </div>
                    {
                        dropDownMenu &&
                        <div className='authUserDisplayBot'>
                            <Button type='text' className='authUserDisplayBotTile' onClick={()=> redirectHandler(3) }>
                                <SettingsIcon />
                                <p>Settings</p>
                            </Button>
                            <Button type='text' className='authUserDisplayBotTile logoutRedBtn' onClick={()=> setSignoutConfirm(true)}>
                                <LogoutIcon />
                                <p>Logout</p>
                            </Button>
                        </div>

                    }
                   
                </div>

                {/* <MenuOutlined className='menuBtn' onClick={()=> setMobMenuState(true)  } /> */}
        
            </div>            

        </div>

        <div id='mobMenuCnt' ref={menuHolder}>
            <div id='mobMenuPanel' ref={innerMenuHolder}>
            <div className='mobMenuPanelTop'>
                <img className="mobmenulogo" src="/images/cco-small.png" alt="brand_logo" />
                <img className="logoutBtn" src={logout_logo} alt="logout_btn" onClick={()=> setSignoutConfirm(true)} />
            </div>

            <Button 
            className={`mobMenuItem ${activeTab == 0 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(0) }
            type='text'>
                <CalculateIcon />
                QL Inputs
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 1 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(1) }
            type='text'>
                <ManageHistoryIcon />
                Histories
            </Button>
            <Button 
            className={`mobMenuItem ${activeTab == 2 && 'mobItemActive'}`}
            onClick={()=> redirectHandler(2) }
            type='text'>
               <SettingsIcon />
                Settings
            </Button>

            </div>

            <div id='mobMenuClose' onClick={()=> setMobMenuState(false) } >
                
            </div>

        </div>
        {
            signoutConfirm &&
            <ModalBox
            open={signoutConfirm}
            onClose={()=> setSignoutConfirm(false)}
            header='Confirm !'
            message='Are you sure you want to Log out !'
            onOk={()=>{
                signOut()
            }}
            onCancel={()=> setSignoutConfirm(false) }
            />
        }
        </>
    );

}

export default Header;