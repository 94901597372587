import { Typography } from '@mui/material';
import { Switch } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { User } from '../../@types/user';
import { Axios } from '../../Axios';
import { RootState } from '../../redux/store';
import { updateUser } from '../../redux/userSlice';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';

const Security: React.FC = () => {

    const user = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch();
    const [twoFactorAuth,setTwoFactorAuth] = useState(user?.two_factor_auth || false);
    const [loadTwoFactorAuth,setLoadTwoFactorAuth] = useState(false);

    const updateTwoFactorAuth = (e:boolean)=>{

        setLoadTwoFactorAuth(true);

        Axios.post(`/api/v1/update-profile`,{
            two_factor_auth:e
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            e?
            toast.success("Two-factor authentication enabled")
            :
            toast.info("Two-factor authentication disabled");
            
            let prep = {...user, two_factor_auth:e};
            dispatch(updateUser(prep as User ));
            setTwoFactorAuth(e);
        })
        .catch(err=>{
            let errMsg = "";
            if(err.response){
                errMsg = err.response.data.message;
            }
            else{
                errMsg = "Network Error";   
            }

            toast.error(errMsg);
        })
        .finally(()=>{
            setLoadTwoFactorAuth(false);
        })
    }

    const groupStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: '35px 0 45px 0',
        maxWidth:"555px",
    };

    const headingStyle: React.CSSProperties = {
        color:"black",
        fontSize:"18px",
        fontWeight:"600",
    };

    const descriptionStyle: React.CSSProperties = {
        fontSize:"15px",
        fontWeight:"400",
        color:"#666666",
    };

    return (
        <AuthenticatedLayout>
            <Typography variant="h6" gutterBottom={true}>Security Settings</Typography>

            <div style={groupStyle}>
                <p style={headingStyle}>2 Factor Authentication</p>
                <p style={descriptionStyle}>Two-factor authentication adds an extra layer of security to your account by 
                    requiring both your password and a verification code sent to your mobile device or email 
                    when signing in from a new device or browser.</p>
                <Switch
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    defaultChecked={twoFactorAuth}
                    checked={twoFactorAuth}
                    onChange={(e) => {
                        // setTwoFactorAuth(prev=> !prev);
                        updateTwoFactorAuth(e);
                    }}
                    loading={loadTwoFactorAuth}
                    style={{
                        marginLeft: 0,
                        width: "55px"
                    }}
                />
             
            </div>
            
        </AuthenticatedLayout>
    );
};

export default Security;