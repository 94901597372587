import { CloseOutlined } from "@ant-design/icons"
import { Input } from "antd"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useTouchHandler from "../../hooks/useTouchHandler"
import { removeTab, setActiveTab, updateTabName } from "../../redux/calculatorSlice"
import { RootState } from "../../redux/store"

interface TopTabProps {
    index: number;
    name: string;
    tabLength: number;
}

const TopTab = ({
    index,
    name,
    tabLength
}: TopTabProps): JSX.Element => {
    const dispatch = useDispatch();
    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    
    const [nameEdit, setNameEdit] = useState<boolean>(false);
    const [touchAndHoldHandler, touchEnd] = useTouchHandler();

    const handleTabClick = (): void => {
        dispatch(setActiveTab(index));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(updateTabName(e.target.value));
    };

    const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (!e.target.value) {
            dispatch(updateTabName('untitled'));
        }
        setNameEdit(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            setNameEdit(false);
        }
    };

    const handleCloseClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        dispatch(removeTab(index));
    };

    return (
        <div 
        className={`tab ${activeTab === index ? 'activeTab' : 'deactiveTab'}`}
        >
            {
                nameEdit &&
                <Input 
                type="text"
                style={{color:"black",width:"180px"}}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                onKeyDownCapture={handleKeyDown}
                autoFocus
                />
            }
            {
                !nameEdit &&
                <p 
                onDoubleClick={()=> setNameEdit(true)}
                onTouchStart={()=> touchAndHoldHandler(()=> setNameEdit(true) )}
                onTouchEnd={()=> touchEnd() }
                className="tabName" 
                onClick={handleTabClick}
                >
                    {name}
                </p>
            }
                {
                tabLength > 1 &&
                <CloseOutlined 
                className="tabCloseBtn"
                onClick={handleCloseClick}
                />
                }
        </div>
    )
}

export default TopTab;