// React
import React, { createContext, useEffect, useState } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Local imports
import { GlobalContextType } from '../@types/user';
import { Axios } from '../Axios';
import ModalBox from '../components/modal';
import { setAlreadySigned, setUnAuthorized } from '../redux/globalSlice';
import { RootState } from '../redux/store';
import { login, logout } from '../redux/userSlice';

export const globalData = createContext<GlobalContextType | null>(null);

interface GlobalContextProps {
  children: React.ReactNode;
}

export const GlobalContext: React.FC<GlobalContextProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  
  // State for active menu tab and authorization
  const [activeTab, setActiveTab] = useState<number>(0);
  const [allow, setAllow] = useState(false);
  
  // Redux state
  const unauthorized = useSelector((state: RootState) => state.global.unauthorized);
  const alreadySigned = useSelector((state: RootState) => state.global.alreadySignedIn);

  // Update active tab based on location
  useEffect(() => {
    const path = location.pathname;
    
    if (path === '/home') {
      setActiveTab(0);
    } else if (path === '/histories') {
      setActiveTab(1);
    } else if (path === '/users') {
      setActiveTab(2);
    }
  }, [location]);

  // Auto-authenticate user if previously logged in
  useEffect(() => {
    const userData = localStorage.getItem('ccouser') || sessionStorage.getItem('ccouser');

    if (userData) {
      const user = JSON.parse(userData);

      Axios.post('/api/v1/background-authentication', {}, {
        headers: {
          Authorization: user?.token
        }
      })
        .then(() => {
          dispatch(login(JSON.parse(userData)));
          setAllow(true);
        })
        .catch(() => {
          setAllow(true);
        });
    } else {
      setAllow(true);
    }
  }, [dispatch]);

  const handleUnauthorizedClose = (): void => {
    dispatch(logout());
    dispatch(setUnAuthorized(false));
    setAllow(true);
  };

  const handleAlreadySignedClose = (): void => {
    dispatch(logout());
    dispatch(setAlreadySigned(false));
    setAllow(true);
  };

  const handleSetActiveTab = (num: number): void => {
    setActiveTab(num);
  };

  return (
    <globalData.Provider value={{ activeTab, setActiveTab: handleSetActiveTab }}>
      {allow && children}

      <ModalBox
        open={unauthorized}
        onClose={() => {}}
        header="Restricted!"
        message="Login session expired. Please Login again!"
        onOk={handleUnauthorizedClose}
      />

      <ModalBox
        open={alreadySigned}
        header="Restricted!"
        message="You are already Signed-in to another device. Please login again to continue with this device!"
        onOk={handleAlreadySignedClose}
      />
    </globalData.Provider>
  );
};
