// React
import { memo, useCallback, useEffect, useRef, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Material UI
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton } from '@mui/material';

// Local imports
import JobBar from '../components/bottomBar/JobBar';
import ScenarioBar from '../components/bottomBar/ScenarioBar';
import Calculator from '../components/Calculator';
import Header from '../components/Header';
import AuthenticatedLayout from '../components/layouts/AuthenticatedLayout';
import TopTab from '../components/tabs/TopTab';
import { insertTab, setTimer } from '../redux/calculatorSlice';
import { RootState } from '../redux/store';

const Home: React.FC = () => {

    const [allowView,setAllowView] = useState<boolean>(false);
    const calcCnt = useRef<HTMLDivElement>(null);

    const tabs = useSelector((state: RootState) => state.calculator.tabs);
    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    const activeScenario = useSelector((state: RootState) => state.calculator.activeScenario);
    const dispatch = useDispatch();

    const getRealTime = useCallback((): string => {
        const date = new Date().toDateString();
        const time = new Date().toLocaleTimeString();
        return `${date}, ${time}`;
    }, []);

    useEffect(()=>{
        if(tabs.length < 1){
            dispatch(insertTab());
        }
        setAllowView(true);

        const timer = setInterval(()=>{
            dispatch( setTimer(getRealTime()) )
        },1000);

        return()=>{
            clearInterval(timer);
        }
    },[dispatch, getRealTime, tabs.length]);

    const mainContainerStyle: React.CSSProperties = {
        paddingTop: "80px",
    };

    return(
        <AuthenticatedLayout>
        {
            allowView &&
            <>
            <div id="main" style={mainContainerStyle}>
                <Header />

                <div className="barCnt">
                    <div className="barScrollCnt barScrollCollapsed">
                        {
                            tabs.map((elem,index)=>(
                                <TopTab
                                key={elem.key}
                                index={index}
                                tabLength={tabs.length}
                                name={elem.tabName}
                                />
                            ))
                        }
                    </div>
                    <IconButton className="bcAdd" onClick={()=> {dispatch(insertTab()) } }>
                        <ControlPointIcon
                        sx={{
                            color:"black"
                        }}
                        />
                    </IconButton>

                </div>

                <JobBar
                key={`${activeScenario}${activeTab}`}
                />
                
                <ScenarioBar 
                />

                

                <div className="calcScreenCnt" ref={calcCnt}>
                   {
                    
                     <Calculator 
                     key={`${activeTab}${activeScenario}`}
                     className="calcScreen"
                     />
                   }
                </div>

            </div>
            </>
        }
        </AuthenticatedLayout>
    )
}

export default memo(Home);