import { useState } from 'react';

type TouchStartFunction = (func?: () => void) => void;
type TouchEndFunction = () => void;
type TouchHandlerReturn = [TouchStartFunction, TouchEndFunction];

export default function useTouchHandler(): TouchHandlerReturn {
  const [touchTimeOut, setTouchTimeOut] = useState<NodeJS.Timeout | undefined>();

  const touchStart: TouchStartFunction = (func) => {
    setTouchTimeOut(
      setTimeout(() => {
        if (func) func();
      }, 500)
    );
  };

  const touchEnd: TouchEndFunction = () => {
    clearTimeout(touchTimeOut);
  };

  return [touchStart, touchEnd];
}
