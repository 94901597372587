// External dependencies
import { PercentageOutlined } from "@ant-design/icons";
import { Alert, Input, InputRef } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Types and interfaces
import { newFormInpPropType } from "../../@types/user";

// Redux and state management
import { decisiveInputchange } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";

// Components
import HintGuide from "../guide/HintGuide";

// Utilities and helpers
import NumberFormat from "../../utils/NumberFormat";

// Assets
import question_ico from "../../svg/question.svg";


const Decisive: React.FC<newFormInpPropType> = ({ activeIndex, index, data, outerIndex, currency }) => {
  const version = useSelector((state: RootState) => 
    state.calculator.tabs[state.calculator.activeTab].version
  );

  const myCurrency = useSelector((state: RootState) => 
    state.calculator.activeScreen?.currency || "USD"
  );
  
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const msgCnt = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLImageElement>(null);
  const [decisive, setDecisive] = useState<"yes" | "no">(data?.value ? "yes" : "no");
  const isFocusable = !data?.value;
  const decisiveHolder = useRef<InputRef>(null);
  const decisiveSelect = useRef<HTMLSelectElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setDecisive(data?.value ? "yes" : "no");
  }, [data?.value]);

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      if (msgCnt.current && msgRef.current) {
        if (!msgCnt.current.contains(e.target as Node) && !msgRef.current.contains(e.target as Node)) {
          setShowMsg(false);
        }
      }

      if (container.current && decisiveHolder.current) {
        if (!container.current.contains(e.target as Node)) {
          const inputValue = decisiveHolder.current.input?.value;
          if (inputValue === undefined || inputValue === null || inputValue === "") {
            setDecisive("no");
          }
        }
      }
    };

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, []);

  useEffect(() => {
    if (decisive === "yes" && isFocusable && decisiveHolder.current) {
      decisiveHolder.current.focus();
    }
  }, [decisive, isFocusable]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "0") {
      e.target.value = "";
    }

    dispatch(decisiveInputchange({
      value: NumberFormat.toNumber(e.target.value),
      outerIndex,
      innerIndex: index
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "no") {
      const event = { ...e, target: { ...e.target, value: "" } };
      handleChange(event as unknown as React.ChangeEvent<HTMLInputElement>);
    }
    setDecisive(e.target.value as "yes" | "no");
  };

  const renderMessage = () => {
    if (!showMsg) return null;

    const versionNumber = parseInt(version?.replaceAll("v", "") || "0", 10);

    if (versionNumber > 10) {
      return (
        <HintGuide
          onClose={() => setShowMsg(false)}
          targetElem={msgRef?.current}
          heading={data?.name}
          msg={data?.msg}
        />
      );
    }

    return (
      <div className="formMsg" ref={msgCnt}>
        {versionNumber >= 3 ? (
          Array.isArray(data?.msg) && data?.msg.map((elem, idx) => (
            <p key={`msg${idx}`}>{elem}</p>
          ))
        ) : (
          <p>{data?.msg}</p>
        )}
      </div>
    );
  };

  return (
    <div className="formMainCnt">
      <p className="formTitle">{data?.name}</p>
      <div className={`formInputCnt ${data?.error ? "error" : ""}`} ref={container}>
        {decisive === "yes" && (
          <>
            <Input
              className="mainInput"
              type="text"
              style={{ flex: "1" }}
              value={
                myCurrency === "INR"
                  ? NumberFormat.toINDstandard(String(data?.value || ""))
                  : NumberFormat.toUSstandard(String(data?.value || ""))
              }
              onChange={handleChange}
              min={1}
              ref={decisiveHolder}
            />
            {data?.percent && <PercentageOutlined className="percentIcon" />}
          </>
        )}

        <select
          ref={decisiveSelect}
          className="mainInput myselect"
          value={decisive}
          onChange={handleSelectChange}
        >
          <option value="no">No</option>
          <option value="yes">Yes</option>
        </select>

        <img
          className="formQuestion"
          src={question_ico}
          alt="show input field description button"
          onClick={() => setShowMsg(true)}
          ref={msgRef}
        />
      </div>

      {data?.error && (
        <Alert
          style={{ fontSize: "12px", padding: "5px 10px" }}
          description="Mandatory Field"
          type="error"
          showIcon={false}
        />
      )}
      {renderMessage()}
    </div>
  );
};

export default Decisive;