// React
import { useEffect, useState } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Ant Design
import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

// Material UI
import { Box, IconButton, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// Local imports
import { Search } from '@mui/icons-material';
import { Axios } from '../../Axios';
import FSLoader from '../../components/FSLoader';
import InputBox from '../../components/input';
import AuthenticatedLayout from '../../components/layouts/AuthenticatedLayout';
import ModalBox from '../../components/modal';
import { RootState } from '../../redux/store';
import PassToggle from '../passToggle/PassToggle';
import TripleDotMenu from '../tripledotMenu/TripleDotMenu';

interface User {
  id: string;
  name: string;
  email: string;
  password: string;
  status: number;
}

interface ConfirmDialog {
  id: string;
  type: number;
  msg: string;
}

const Access = ()=>{

    const user = useSelector((state: RootState) => state.user.user );
    const [allowView,setAllowView] = useState<boolean>(true);
    const [loading,setLoading] = useState<boolean>(false)
    const [prevLoad,setPrevLoad] = useState<boolean>(false)
    const [nextLoad,setNextLoad] = useState<boolean>(false)
    const [data,setData] = useState<User[]>([]);
    const [next,setNext] = useState<boolean>(false);
    const [prev,setPrev] = useState<boolean>(false);
    const [page,setPage] = useState<number>(1);
    const [search,setSearch] = useState<string>("");
    const [timeLimit, setTimeLimit] = useState<NodeJS.Timeout | undefined>();
    const [createUserPopup, setCreateUserPopup] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error,setError] = useState("");
    const [confirm,setConfirm] = useState<ConfirmDialog>({id:"",type:0,msg:""});

    useEffect(()=>{

        handleApiCall();

    },[page])

    const handleApiCall = async (): Promise<void> => {
        setLoading(true);

        try {
            const result = await Axios.get(`/api/v1/admin/users?page=${page}&search=${search || ""}`,{
                headers:{
                    Authorization:user?.token
                }
            });

            setAllowView(true);
            const users = result.data.data.users.map((elem:any)=> ({
                id:elem._id || elem.id,
                name: elem.name,
                email: elem.email,
                password: elem.visible_password,
                status: elem.account_status
            }) );

            setData(users);
            setNext(result.data.data.next);
            setPrev(result.data.data.prev);
        } catch (err) {
            setAllowView(true);
        } finally {
            setLoading(false);
            setPrevLoad(false);
            setNextLoad(false);
        }
    }

    useEffect(()=>{

        clearTimeout(timeLimit);
        setTimeLimit(setTimeout(()=>{

            handleApiCall();

        },500))

    },[search]);

    const handleCreateUser = async (): Promise<void> => {
        try {
            await Axios.post(`/api/v1/admin/users`,{
                name: name,
                email: email,
                password: password
            },{
                headers:{
                    Authorization:user?.token
                }
            })

            handleApiCall();
            setCreateUserPopup(false);
            setEmail("");
            setPassword("");
            setName("");

        } catch (err: any) {
            setError(err.response.data.message);
        }
    }

    const handleGeneratePassword = async (): Promise<void> => {
        try {
            const result = await Axios.get(`/api/v1/admin/random-sequence`,{
                headers:{
                    Authorization:user?.token
                }
            })

            setPassword(result.data.data);

        } catch (err: any) {
            setError(err.response.data.message);
        }
    }


    const handleRevokeAccess = async (id:string): Promise<void> => {
        try {
            await Axios.put(`/api/v1/admin/users/access/revoke`,{
                id: id
            },{
                headers:{
                    Authorization:user?.token
                }
            })

            handleApiCall();
            setConfirm({id:"",type:0,msg:""});

        } catch (err: any) {
            setError(err.response.data.message);
        }
    }
    const handleGrantAccess = async (id:string): Promise<void> => {
        try {
            await Axios.put(`/api/v1/admin/users/access/grant`,{
                id: id
            },{
                headers:{
                    Authorization:user?.token
                }
            })

            handleApiCall();
            setConfirm({id:"",type:0,msg:""});
        } catch (err: any) {
            setError(err.response.data.message);
        }
    }

    const handleShareCredentials = async (id:string): Promise<void> => {
        try {
            await Axios.post(`/api/v1/admin/users/credential/share/${id}`,{},{
                headers:{
                    Authorization:user?.token
                }
            })

        } catch (err: any) {
            setError(err.response.data.message);
        }

        setConfirm({id:"",type:0,msg:""});
    }

    return(
        <>
        <AuthenticatedLayout>
        {
            createUserPopup &&
            <Modal
            className="modal"
            open={createUserPopup}
            onClose={()=> setCreateUserPopup(false)}
            >
                    <div className="modalPopUp">

                    <CloseOutlined
                    className="popupClose" 
                    onClick={()=> setCreateUserPopup(false)}
                    />
                    <div>

                    <InputBox
                    type="string"
                    placeholder={"Name"}
                    value={name}
                    onChange={(e:any)=>{
                        setName(e.target.value)
                    }}
                    />

                    <InputBox
                    type="string"
                    placeholder={"Email"}
                    value={email}
                    onChange={(e:any)=>{
                        setEmail(e.target.value)
                    }}
                    />

                    </div>

                    <div className="mpuBot">

                    <Button className="mybtn-blue" type="text" onClick={()=>{
                        handleCreateUser();
                    
                    }}>
                        Create <RightOutlined />
                    </Button>

                    </div>


                    </div>
            </Modal>
        }
        {
            <ModalBox
            open={error? true: false}
            header="Error !"
            message={error}
            onOk={()=> setError("")}
            />
        }
        {
            // Confirm Box
            <ModalBox
            open={confirm.msg? true: false}
            header="Alert !"
            message={confirm.msg}
            onOk={()=> confirm.type === 1 ? handleRevokeAccess(confirm.id) : confirm.type === 2 ? handleGrantAccess(confirm.id) : handleShareCredentials(confirm.id) }
            onCancel={ ()=> setConfirm({id:"",type:0,msg:""}) }
            />
        }
        {
            allowView &&
            <Box sx={{position:"relative"}}> 
                 {
                loading &&
                <FSLoader>
                    <p className="loaderTxt">Loading...</p>
                </FSLoader>
                }


                <Typography variant="h6" gutterBottom={true}>Access Settings</Typography>
                {/* Add your notification settings here */}
                <Box sx={{ maxWidth:"1620px",margin:"0 auto"}}>

                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",margin:"0 auto 15px auto"}}>
                <div style={{
                display:"flex",
                alignItems:"center",
                justifyContent:"flex-start",
                height:"48px",
                flex:"1",
                marginBottom:"20px"
                }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "25px",
                    border: "1px solid lightgray",
                    overflow: "hidden",
                    height:"48px",
                    width:"100%",
                    maxWidth:"400px",
                    background:"white",
                    padding:"0 5px"
                }}>
                    <Input 
                    type="search" 
                    style={{
                        flex:"1",
                        height:"100%"
                    }} 
                    placeholder="Search..."
                    value={search}
                    variant="borderless"
                    onChange={(e)=> {
                        setSearch(e.target.value ? e.target.value : "")
                    }}
                    />

                    <IconButton size="medium" onClick={handleApiCall}>
                        <Search />
                    </IconButton>

                </div>
                </div>
                    <Button type="text" className="histbtn-blue" onClick={()=> setCreateUserPopup(true)}>
                            Create User
                    </Button>
                </div>
                <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Password</TableCell>
                        <TableCell>status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {/* Add your table rows here */}
                    {
                        data.length > 0 ?
                        data.map((elem,index)=>(
                            <TableRow key={index}>
                                <TableCell>
                                    {
                                        elem.name
                                    }
                                </TableCell>
                                <TableCell>
                                    {elem.email}
                                </TableCell>
                                <TableCell>
                                    <PassToggle 
                                    value={elem.password}
                                    />
                                </TableCell>
                                <TableCell style={{textAlign:"center"}}>
                                    {
                                        elem.status == 1 &&
                                        <div className="unveri">unverified</div>
                                    }
                                    {
                                        elem.status == 2 &&
                                        <div className="veri">Active</div>
                                    }
                                    {
                                        elem.status == 3 &&
                                        <div className="blocked">Blocked</div>
                                    }
                                    {
                                        elem.status == 4 &&
                                        <div className="deleted">Deleted</div>
                                    }
                                </TableCell>
                                <TableCell>
                                    <TripleDotMenu
                                    rowId={elem.id}
                                    items={[
                                        <div 
                                        onClick={()=> elem.status !== 2 ? setConfirm({
                                            id:elem.id,
                                            msg:"Are you sure you want to Grant Access to this person ?",
                                            type:2
                                        }) : setConfirm({
                                            id:elem.id,
                                            msg:"Are you sure you want to Revoke Access of this person ?",
                                            type:1
                                        }) }
                                        >
                                            {elem.status !== 2 ? "Grant Access" : "Revoke Access"}
                                        </div>
                                    ]}
                                    />
                                        
                                </TableCell>
                            </TableRow>
                        ))
                        :
                        <TableRow>
                            <TableCell colSpan={5} style={{textAlign:"center"}}>No data found</TableCell>
                        </TableRow>
                    }
                
                    </TableBody>
                </Table>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 1 }}>
                {/* <Button
                    variant="contained"
                    startIcon={<NavigateBefore />}
                >
                    Previous
                </Button>
                <Button
                    variant="contained"
                    endIcon={<NavigateNext />}
                >
                    Next
                </Button> */}
                </Box>
                <div style={{
                    margin:"50px 0 20px 0",
                    display:"flex",
                    justifyContent:"flex-end"
                }}>
                    <Button 
                    disabled={!prev} 
                    className="histbtn-gray"
                    type="text" 
                    size="large" 
                    style={{margin:"0 5px"}}
                    onClick={()=> {setPage(prev=> prev-1); setPrevLoad(true)} }
                    loading={prevLoad}
                    >
                        <LeftOutlined />
                        Back
                    </Button>
                    <Button 
                    disabled={!next} 
                        className="histbtn-blue bk-blue"
                    type="text" 
                    size="large" 
                    style={{margin:"0 5px"}}
                    onClick={()=> {setPage(prev=> prev+1); setNextLoad(true) }}
                    loading={nextLoad}
                    >
                        Next
                        <RightOutlined />
                    </Button>
                </div>
                </Box>

            </Box> 
        }       
        </AuthenticatedLayout> 
        </>
    )

}

export default Access;