import { FC, ReactNode } from 'react';

interface FSLoaderProps {
  children: ReactNode;
}

const FSLoader: FC<FSLoaderProps> = ({ children }) => {
  const containerStyle = {
    position: 'fixed' as const,
    top: 0,
    right: 0,
    background: 'rgba(255,255,255,.9)',
    zIndex: 9999999,
    height: '100vh',
    width: '100vw',
    display: 'flex' as const,
    alignItems: 'center' as const,
    justifyContent: 'center' as const
  };

  const textStyle = {
    fontSize: '42px'
  };

  return (
    <div style={containerStyle}>
      <p style={textStyle}>{children}</p>
    </div>
  );
};

export default FSLoader;