// React
import { useMemo } from 'react';

// Components
import OutputScreen from '../components/output/OutputScreen';
import OutputScreenV10 from '../components/output/v10/OutputScreen';
import OutputScreenV11 from '../components/output/v11/OutputScreen';
import OutputScreenV12 from '../components/output/v12/OutputScreen';
import OutputScreenV13 from '../components/output/v13/OutputScreen';
import OutputScreenV14 from '../components/output/v14/OutputScreen';
import OutputScreenV9 from '../components/output/v9/OutputScreen';

type OutputScreenComponent = typeof OutputScreen | typeof OutputScreenV9 | typeof OutputScreenV10 | 
  typeof OutputScreenV11 | typeof OutputScreenV12 | typeof OutputScreenV13 | typeof OutputScreenV14;

function useOutputByVersion(version: string | undefined): [OutputScreenComponent] {
  const v = useMemo(() => parseInt(version?.replaceAll('v', '') || '0'), [version]);

  if (v === 14) {
    return [OutputScreenV14];
  } else if (v === 13) {
    return [OutputScreenV13];
  } else if (v === 12) {
    return [OutputScreenV12];
  } else if (v === 11) {
    return [OutputScreenV11];
  } else if (v === 10) {
    return [OutputScreenV10];
  } else if (v === 9) {
    return [OutputScreenV9];
  } else {
    return [OutputScreen];
  }
}

export default useOutputByVersion;
