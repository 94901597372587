import { useEffect, useRef } from 'react';

interface PopupMenuProps {
  targetElem: any;
  onCloseTrigger: () => void;
  children?: React.ReactNode;
}

const PopupMenu = ({ targetElem, onCloseTrigger, children }: PopupMenuProps): JSX.Element => {
  const shareCnt = useRef<HTMLDivElement>(null);

  const getScroll = (): number => {
    const doc = document.documentElement;
    const body = document.body;
    return (doc.clientHeight ? doc : body).scrollTop;
  };

  const setPosition = (): void => {
    const curr = shareCnt.current;
    if (!curr) return;

    const bound = targetElem?.getBoundingClientRect();
    const elemHeight = targetElem?.offsetHeight;
    const elemWidth = targetElem?.offsetWidth;
    const elemX = bound?.left;
    const elemY = bound?.top;
    const cntWidth = curr.offsetWidth;
    const cntHeight = curr.offsetHeight;

    // Handle horizontal positioning
    if (elemX + elemWidth + cntWidth > window.innerWidth && cntWidth > (elemX + elemWidth)) {
      curr.style.left = '0px';
    } else if (elemX + elemWidth + cntWidth > window.innerWidth) {
      curr.style.left = `${elemX - cntWidth}px`;
    } else {
      curr.style.left = `${elemX + elemWidth}px`;
    }

    // Handle vertical positioning
    if (bound.top + elemHeight + cntHeight > window.innerHeight) {
      curr.style.top = `${elemY - cntHeight}px`;
    } else {
      curr.style.top = `${elemY + elemHeight}px`;
    }
  };

  const handleWindowClick = (e: MouseEvent): void => {
    const target = e.target as Node;
    if (!shareCnt.current?.contains(target) && !targetElem?.contains(target)) {
      onCloseTrigger();
    }
  };

  // Initial position setup
  useEffect(() => {
    setPosition();
  }, []);

  // Event listeners setup
  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('resize', setPosition);

    return () => {
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('resize', setPosition);
    };
  }, []);

  return (
    <div ref={shareCnt} className="popupMenuCnt">
      {children}
    </div>
  );
};

export default PopupMenu;