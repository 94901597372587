// External libraries
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// React
import React, { useEffect } from 'react';

// Local imports
import { RootState } from '../../redux/store';

interface NonAuthLayoutProps {
    children: React.ReactNode;
}

const NotAuthenticatedLayout = ({ children }: NonAuthLayoutProps): JSX.Element => {
    const user = useSelector((state: RootState) => state.user.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/home');
        }
    }, [user, navigate]);

    return (
        <>
            {!user && children}
        </>
    );
};

export default NotAuthenticatedLayout;