// React
import { useState } from 'react';

// React Router
import { Link, useNavigate } from 'react-router-dom';

// Ant Design
import { Alert } from 'antd';
import Button from 'antd/es/button';

// Local imports
import { Axios } from '../Axios';
import InputBox from '../components/input';
import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';
import Style from '../styles/login.module.css';
import email_prefix from '../svg/email-prefix.svg';

const Forget: React.FC = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [allowView, setAllowView] = useState<boolean>(false);

    const handleApiCall = () => {
        setLoading(true);
        setError(undefined);

        Axios.post('/api/v1/forget-password', { email })
            .then((result) => {
                setLoading(false);
                navigate(`/verify?key=${result.data.data.refData}`);
            })
            .catch((err) => {
                setLoading(false);
                if (err.response) {
                    setError(err.response.data.message || err.response.data.error);
                }
                console.log(err);
            });
    };

    const rightSectionStyle: React.CSSProperties = {
        background: 'url("/images/forget_bk.png")',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };

    const inputContainerStyle: React.CSSProperties = {
        margin: '20px 0 0 0',
    };

    const errorAlertStyle: React.CSSProperties = {
        minHeight: '20px',
        margin: '5px 0 20px 0',
        fontSize: '12px',
    };

    const loginLinkStyle: React.CSSProperties = {
        color: '#656A72',
        fontSize: '12px',
        margin: '30px 0 0 0',
    };

    return (
        <NotAuthenticatedLayout>
            <div id="main">
                <div className={Style.container}>
                    <div className={Style.left}>
                        <div className={Style.form}>
                            <img src='/images/cco-small.png' alt="logo" />
                            <h1 className={Style.title}>Sign in</h1>
                            <h4 className={Style.subtitle}>Sign in with your email</h4>

                            <div style={inputContainerStyle}>
                                <InputBox 
                                type='text' 
                                image={<img src={email_prefix} 
                                alt='logo' />} 
                                placeholder='Your email'
                                value={email}
                                onChange={(e)=> setEmail(e.target.value) }
                                />
                            </div>

                            {
                                error &&
                                <Alert 
                                type='error' 
                                message={error} 
                                showIcon 
                                style={errorAlertStyle}
                                />
                            }
                         

                            <Button className='btn' type='text' loading={loading} onClick={handleApiCall}>Send verification code</Button>

                            <p style={loginLinkStyle}>
                                I think I remember my password. 
                                <Link to="/" style={{textDecoration:"none"}}>
                                    <span style={{color:"#2B80FF"}}> Login</span>
                                </Link>
                            </p>

                        </div>

                    </div>

                    <div className={Style.right} style={rightSectionStyle} />

                </div>

            </div>
        </NotAuthenticatedLayout>
    )
}

export default Forget;