// External libraries
import { useDispatch, useSelector } from 'react-redux';

// React
import { useRef, useState } from 'react';

// UI Components & Icons
import { CloseOutlined, RightOutlined } from '@ant-design/icons';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Modal } from '@mui/material';
import { Button } from 'antd';

// Local imports
import { createSplitScreen, insertJob } from '../../redux/calculatorSlice';
import { RootState } from '../../redux/store';
import InputBox from '../input';
import PopupMenu from '../menu/PopupMenu';
import ModalBox from '../modal';
import JobTab from '../tabs/JobTab';

const JobBar = ()=>{

    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs);
    const [showMenu,setShowMenu] = useState(false);
    const mBtn = useRef<any>();
    const [showNewJobCreate,setShowNewJobCreate] = useState(false);
    const [showSplitScreenCreate,setShowSplitScreenCreate] = useState(false);
    const [selectedNewJob,setSelectedNewJob] = useState("0");
    const [newJobName,setNewJobName] = useState("");
    const [newJobDesc,setNewJobDesc] = useState("");
    const [error,setError] = useState("");
    const [selectedJobsForSplit,setSelectedJobsForSplit] = useState([]);

    const dispatch = useDispatch();


    const confirmNewJob = ()=>{
        setShowNewJobCreate(true);
        setShowMenu(false);
    }
    
    const confirmNewSplitScreen = ()=>{
        setShowSplitScreenCreate(true);
        setShowMenu(false);
    }

    const addNewJob = ()=>{
        if(!newJobName){
            setError("Please enter a name for new Job !");
            return;
        }
        if(!newJobDesc){
            setError("Please enter a description for new Job !");
            return;
        }

        dispatch( insertJob({
            from:parseInt(selectedNewJob),
            myname:newJobName,
            mydesc:newJobDesc
        }));

   
        setShowNewJobCreate(false);
        setSelectedNewJob("0");
        setNewJobName("");
        setNewJobDesc("");
    }

    const verifySplitScreen = ()=>{
        if(selectedJobsForSplit.length < 2){
            setError("There must be at least two jobs selected to work in split screen !");
            return;
        }

        dispatch( createSplitScreen(selectedJobsForSplit));

        setShowSplitScreenCreate(false);

    }

    return(
        <>
        <div className="jobBar">
        
            <div className="barScrollCnt barScrollCollapsed">

            {
                activeTabJobs.map((elem,index)=>(
                    <JobTab
                    key={`jobt${index}`}
                    name={
                        elem.type === 0 ? 
                        elem.name :
                        elem.splitData!.map((ielem,iind)=> activeTabJobs[parseInt(ielem)].name  ).join(" <-> ")
                    }
                    index={index}
                    type={elem.type}
                    />
                ))
            }

            </div>

            <IconButton 
            ref={mBtn}
            sx={{margin:"0 15px 0 0",height:"27px",width:"27px"}}
            onClick={()=> setShowMenu(prev=> !prev )}
            >
                <MoreVertIcon sx={{color:"black"}}/>
            </IconButton>

        </div>

        {/* Popup Menu Box */}
        {
            showMenu &&
            <PopupMenu
            targetElem={mBtn.current}
            onCloseTrigger={()=> setShowMenu(prev=> !prev)}
            >

                <ul>
                    <li onClick={confirmNewJob}>Add New Job</li>
                    {/* <li onClick={confirmNewSplitScreen}>New Split Screen</li> */}
                </ul>
            </PopupMenu>
        }

        {/* New Job Creation Modal */}
        {
            showNewJobCreate &&
            <Modal
            className="modal"
            open={true}
            onClose={()=> {
                setShowNewJobCreate(false);
            }}
           >
   
           <div className="modalPopUp">
   
                   <CloseOutlined
                   className="popupClose" 
                   onClick={()=> {
                    setShowNewJobCreate(false);
                   }}
                   />
                       <div className="mpuTop">
   
                       <div>
                       
                        <InputBox
                               type="select"
                               placeholder={"New Job From"}
                               value={selectedNewJob}
                               options={
                                [
                                    {value:"-1",label:"Blank"},
                                    ...activeTabJobs.filter(elem=> elem.type === 0).map((elem,index)=> ({value:`${index}`, label : elem.name}) )
                                ]
                            }
                               onChange={(e:any)=>{
                                 setSelectedNewJob(e.target.value);
                               }}
                               />
                           <InputBox
                               type="string"
                               placeholder={"Job Name"}
                               value={newJobName}
                               onChange={(e:any)=>{
                                 setNewJobName(e.target.value)
                               }}
                               />
   
                           <InputBox
                               type="textarea"
                               placeholder={"Job Description"}
                               value={newJobDesc}
                               onChange={(e:any)=>{
                                 setNewJobDesc(e.target.value);
                               }}
                               />
   
                       </div>
                 
   
                       </div>
   
                       <div className="mpuBot">
   
                       <Button className="mybtn-blue" type="text" onClick={()=>{
   
                            addNewJob();
                       }}>
                           Add Job <RightOutlined />
                       </Button>
   
                       </div>
   
                   </div>        
           </Modal>
        }

        {/* New Split Screen Modal */}
        {
            showSplitScreenCreate &&
            <Modal
            sx={{
                zIndex:100
            }}
            className="modal"
            open={true}
            onClose={()=> {
                setShowSplitScreenCreate(false);
            }}
           >
   
           <div className="modalPopUp">
   
                   <CloseOutlined
                   className="popupClose" 
                   onClick={()=> {
                    setShowSplitScreenCreate(false);
                   }}
                   />
                       <div className="mpuTop">
   
                       <div>
                       
                        <InputBox
                            type="multiSelect"
                            placeholder={"Select Jobs"}
                            options={activeTabJobs.filter(elem=> elem.type === 0).map((elem,index)=> ({value:`${index}`, label : elem.name}) )}
                            onChange={(e:any)=>{
                                setSelectedJobsForSplit(e);
                            }}
                            />
   
                       </div>
                 
   
                       </div>
   
                       <div className="mpuBot">
   
                       <Button className="mybtn-blue" type="text" onClick={verifySplitScreen}>
                           Split Screen <RightOutlined />
                       </Button>
   
                       </div>
   
                   </div>        
           </Modal>
        }

{
            error &&
            <ModalBox
            open={error ? true :false}
            header={"Job Warning !"}
            message={error}
            onClose={()=>{
                setError("");
            }}
            onOk={()=>{
                setError("");
            }}
            />
        }
        </>
    )

}

export default JobBar;