// External libraries
import type { InputRef } from 'antd';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// React
import { useRef, useState } from 'react';

// Icons
import { CloseOutlined } from '@ant-design/icons';

// Local imports
import useTouchHandler from '../../hooks/useTouchHandler';
import { removeScenario, setActiveScenario, updateScenarioName } from '../../redux/calculatorSlice';
import { RootState } from '../../redux/store';
import ModalBox from '../modal';

interface BottomTabProps {
  type: number;
  name: string;
  index: number;
  showClose: boolean;
}

const BottomTab = ({
  type,
  name,
  index,
  showClose
}: BottomTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const activeScenario = useSelector((state: RootState) => state.calculator.activeScenario);
  
  const [nameEdit, setNameEdit] = useState<boolean>(false);
  const [removeScenarioAlert, setRemoveScenarioAlert] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  
  const inp = useRef<InputRef>(null);
  const [touchAndHoldHandler, touchEnd] = useTouchHandler();

  const handleTabClick = (): void => {
    dispatch(setActiveScenario(index));
  };

  const handleDoubleClick = (): void => {
    if (nameEdit) return;
    if (type !== 0) {
      setNameEdit(true);
    } else {
      setError('Changing name is not allowed for default Scenarios !');
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(updateScenarioName(e.target.value));
  };

  const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (!e.target.value) {
      dispatch(updateScenarioName('untitled'));
    }
    setNameEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      setNameEdit(false);
    }
  };

  const handleCloseClick = (event: React.MouseEvent): void => {
    event.stopPropagation();
    setRemoveScenarioAlert(true);
  };

  const handleRemoveTab = (): void => {
    dispatch(removeScenario(index));
    setRemoveScenarioAlert(false);
  };

  const clearError = (): void => {
    setError('');
  };

  return (
    <div
      className={`bottomTab ${activeScenario === index ? 'btActive' : 'btnDeactive'}`}
      onClick={handleTabClick}
      onDoubleClick={handleDoubleClick}
      onTouchStart={() => touchAndHoldHandler(handleDoubleClick)}
      onTouchEnd={()=> touchEnd()}
    >
      {nameEdit && type !== 0 ? (
        <Input
          type="text"
          ref={inp}
          style={{ color: 'black', minWidth: '180px' }}
          value={name}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onKeyDownCapture={handleKeyDown}
          autoFocus
        />
      ) : (
        <p className="tabName">{name}</p>
      )}

      {showClose && (
        <CloseOutlined
          className="tabCloseBtn"
          onClick={handleCloseClick}
        />
      )}

      {removeScenarioAlert && (
        <ModalBox
          open={removeScenarioAlert}
          header="Scenario Removal !"
          message={`Are you sure you want to delete ${name} permanently ?`}
          onClose={() => setRemoveScenarioAlert(false)}
          onOk={handleRemoveTab}
          onCancel={() => setRemoveScenarioAlert(false)}
        />
      )}

      {error && (
        <ModalBox
          open={Boolean(error)}
          header="Alert !"
          message={error}
          onClose={clearError}
          onOk={clearError}
        />
      )}
    </div>
  );
};

export default BottomTab;