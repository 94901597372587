import MoreIcon from '@mui/icons-material/MoreVert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

interface propsType{
  leftButtons:any[] | undefined
}   

export default function DynamicNavbar({leftButtons}:propsType) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
            leftButtons?.map((elem,ind)=> (
            <Box key={ind} sx={ 
              ind === 0 ?
              { display: { xs: 'none' } } :
              ind === 1 ?
              { display: { xs: 'none' } }
              :
              (ind === 2) ?
              { display: { xs: 'flex', lg: 'none' } }
              :
              { display: { xs: 'flex', xl: 'none' } }
              }>
                  <MenuItem>
                    {elem}
                  </MenuItem>
            </Box> 
          ))
          }

    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'transparent',boxShadow: 'none' }}>
        <Toolbar>

          <Box sx={{ flexGrow: 1 }} />

          {
            leftButtons?.map((elem,ind)=> (
            <Box key={ind} sx={ 
              ind === 0 ?
              { display: { xs: 'flex' } } :
              ind === 1 ?
              { display: { xs: 'flex' } }
              :
              (ind === 2) ?
              { display: { xs: 'none', lg: 'flex' } }
              :
              { display: { xs: 'none', xl: 'flex' } }
              }>
              {elem}
            </Box> 
          ))
          }

          <Box sx={{ display: { xs: 'flex', xl: 'none' } }}>
            <IconButton
              size="small"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              sx={{ backgroundColor: 'whitesmoke' }}
            >
              <MoreIcon sx={{ color: '#24336D' }} />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}