// External dependencies
import { Alert, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

// Redux and state management
import { updateTextInput } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";

// Types and interfaces
import { newFormInpPropType } from "../../@types/user";

// Components
import HintGuide from "../guide/HintGuide";

// Utilities and helpers
import StringOptimizer from "../../utils/StringOptimizer";

// Assets
import question_ico from "../../svg/question.svg";

const Text: React.FC<newFormInpPropType> = ({
  activeIndex,
  index,
  data,
  currency,
  outerIndex
}) => {
  const version = useSelector((state: RootState) => 
    state.calculator.tabs[state.calculator.activeTab].version
  );
  
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const msgCnt = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLImageElement>(null);
  
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      if (msgCnt.current && msgRef.current) {
        if (!msgCnt.current.contains(e.target as Node) && !msgRef.current.contains(e.target as Node)) {
          setShowMsg(false);
        }
      }
    };

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTextInput({
      value: e.target.value,
      outerIndex,
      innerIndex: index
    }));
  };

  const renderMessage = () => {
    if (!showMsg) return null;

    const versionNumber = parseInt(version?.replaceAll("v", "") || "0", 10);

    if (versionNumber > 10) {
      return (
        <HintGuide
          onClose={() => setShowMsg(false)}
          targetElem={msgRef?.current}
          heading={StringOptimizer.updateCurrencyFormat(data?.name || "", currency!)}
          msg={data?.msg}
        />
      );
    }

    return (
      <div className="formMsg" ref={msgCnt}>
        {versionNumber >= 3 ? (
          Array.isArray(data?.msg) && data?.msg.map((elem: string, idx: number) => (
            <p key={`msg${idx}`}>{elem}</p>
          ))
        ) : (
          <p>{data?.msg}</p>
        )}
      </div>
    );
  };

  return (
    <div className="formMainCnt">
      <p className="formTitle">
        {StringOptimizer.updateCurrencyFormat(data?.name || "", currency!)}
      </p>
      <div className={`formInputCnt ${data?.error ? "error" : ""}`}>
        <Input
          className="mainInput"
          type="text"
          style={{ flex: "1" }}
          value={data?.value}
          onChange={handleChange}
          disabled={data?.disabled}
        />

        <img
          className="formQuestion"
          src={question_ico}
          alt="show input field description button"
          onClick={() => setShowMsg(true)}
          ref={msgRef}
        />
      </div>

      {data?.error && (
        <Alert
          style={{ fontSize: "12px", padding: "5px 10px" }}
          description="Mandatory Field"
          type="error"
          showIcon={false}
        />
      )}
      {renderMessage()}
    </div>
  );
};

export default Text;