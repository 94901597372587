import { Button } from "antd";
import Style from "./output.module.css";
import { filteredResulttype, Payload, resultType, submitScenarioType } from "../../../@types/user";
import React, { memo, useEffect, useState } from "react";
import NumberFormat from "../../../utils/NumberFormat";
import GraphScreen from "../GraphScreen";
import WrapperOutput from "../WrapperOutput";
import { Modal } from "@mui/material";
import ModalBox from "../../modal";
import ScrollerWrapper from "../../scrollerWrapper";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import CompareIcon from '@mui/icons-material/Compare';
import Flynaut from "../../branding/Flynaut";
import PrintBtn from "../../PrintBtn";
import AnalysisScreen from "../AnalysisScreen";
import CurrencyIconRenderer from "../../CurrencyIconRendered";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import LoanScreen from "./LoanScreen";
import SingleLoanScreen from "./SingleLoanScreen";
import LoanNameFormatter from "../../../utils/LoanNameFormatter";
import ComparedScreen from "./ComparedScreen";
import FSLoader from "../../FSLoader";
import TableScreen from "./TableScreen";


type outputScreenPropType={
    result:resultType,
    compareJobs:boolean,
    myCurrency:string,
    setResult:(res:resultType)=> void,
    submitableScenario:submitScenarioType[],
    payload:Payload,
    setPayload:(val:Payload)=> void,
    loading:boolean,
    reCalc:(val:Payload)=> void
}

const OutputScreen = ({result,compareJobs,myCurrency,setResult,submitableScenario,payload,setPayload,loading,reCalc}:outputScreenPropType)=>{

    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs); 
    const [myresult,setMyresult] = useState<filteredResulttype[]>([]);
    const [compared,setCompared] = useState<filteredResulttype[]>([]);
    const [prep,setPrep] = useState<{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][]>([]);
    const [mapper,setMapper] = useState<{name:string,data:string[]}[]>([]);
    const [showGraph,setShowGraph] = useState(false);
    const [showTable,setShowTable] = useState(false);
    const [comparePopup,setComparePopup] = useState(false);
    const [showAnalysis,setShowAnalysis] = useState(false);
    const [showAmmort,setShowAmmort] = useState(false);
    const [compareList,setCompareList] = useState<{
        scenario:{name:string,key:string}[],
        base : {name:string,key:string}[],
        comparable : {name:string,key:string}[]
    }>({
        scenario:[],
        base : [],
        comparable : []
    });
    const [compareEnabled,setCompareEnabled] = useState(false);
    const [error,setError] = useState("");

    const [selectedLoan,setSelectedLoan] = useState("");
    const [selectedLoanResult,setSelectedLoanResult] = useState<resultType>([]);


    const onDragEnd = (result:any)=>{
        
        if (!result.destination) {
            return;
        }

        if(result.destination.droppableId === "base" && compareList.base.length === 1){
            return;
        }

        const source = result.source.droppableId === "scenarios" ? [...compareList.scenario || []] : result.source.droppableId === "base" ? [...compareList.base || []] : [...compareList.comparable || []];
        const desti = result.destination.droppableId === "scenarios" ? [...compareList.scenario  || []] : result.destination.droppableId === "base" ? [...compareList.base  || []] : [...compareList.comparable || []];

        if(JSON.stringify(source) === JSON.stringify(desti)){
            const [removed] = source.splice(result.source.index, 1);
            source.splice(result.destination.index, 0, removed);

            (result.source.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: source}));
            
            (result.source.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: source}));
            
            (result.source.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: source}));
        }
        else{

            const [removed] = source.splice(result.source.index, 1);
            desti.splice(result.destination.index, 0, removed);

            (result.source.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: source}));
            
            (result.source.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: source}));
            
            (result.source.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: source}));

            (result.destination.droppableId === "scenarios") &&
            setCompareList(prev=> ({...prev,scenario: desti}));
            
            (result.destination.droppableId === "base") &&
            setCompareList(prev=> ({...prev,base: desti}));
            
            (result.destination.droppableId === "comparable") &&
            setCompareList(prev=> ({...prev,comparable: desti}));
            
        }
        

        
        
    };

    const getFormatedYears = (years:number)=>{
        let x= years.toFixed(2).toString();
        let y = x.split(".");


        return { years:parseInt(y[0]), months:y.length > 1 ? parseFloat(y[1])/100 : 0};
    }

    useEffect(()=>{

        setMyresult([result.map(elem=> ({name:elem.name,cashFlow:elem.output.cashflowTable,output:elem.output.result.map(el=> ({...el,data:el.data.map(ell=> ({...ell,value:parseFloat( ell.value?.toFixed(2) )}))}))}) )]);

    },[result]);

    useEffect(()=>{

        window.scrollTo(0,0);       

        let mapper = result[0].output.result.map(elem=> ({name:elem.name,data:elem.data.map(el=> el.name)}) );
        setMapper(mapper);
    },[])

 

    useEffect(()=>{

        if(myresult.length < 1) return;

        console.log("Myresult",myresult);

        setCompareList({scenario: myresult[0].map((el,ind)=> ({key:`${ind}`,name:el.name}) ), base:[], comparable:[] });

        let localPrep : {
            name:string,
            data:{
                name:string,
                value:string[]
            }[],
            servicableYear:number,
            yearsRemaining:number
        }[][] = [];

        myresult.forEach((elem,index) => {

            let x:{
                name:string,
                data:{name:string,value:string[]}[],
                servicableYear:number,
                yearsRemaining:number,
            }[] = [];


            elem.forEach((element,ind) => {

                let inflation = (element.cashFlow.map(elem=> elem.inflation).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let tax_rate = (element.cashFlow.map(elem=> elem.tax).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let wacc = (element.cashFlow.map(elem=> elem.wacc).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let volume = (element.cashFlow.map(elem=> elem.volume).reduce((a,b)=> a+b) || 0);
                let month = (element.cashFlow.map(elem=> elem.tables.sv.length).reduce((a,b)=> a>b ? a : b));

                let fay = getFormatedYears( element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b) );
                let fry = getFormatedYears( element.cashFlow.map(elem=> elem.years_remaining).reduce((a,b)=> a>b ? a : b) );

                let sy = fay.years;
                let ry = fry.years;

                if(fay.months !== 0) sy++;
                if(fry.months !== 0) ry++;
    
                let Volume:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > sy ? "0" : (volume ? volume.toString() : "0") );
                let Inflation:string[] = Array.from({length:month}).map((elem,ind)=> ind <= 1 || ind > sy ? "0" : (inflation ? inflation.toString() : "0") );
                let Lta:string[] = Array.from({length:month}).map((elem,ind)=> ("0" ) );
                let Tax:string[] = Array.from({length:month}).map((elem,ind)=> (ind > sy ? "0" : (tax_rate ? tax_rate.toString() : "0") ) );
                let Wacc:string[] = Array.from({length:month}).map((elem,ind)=> (ind > sy ? "0" : (wacc ? wacc.toString() : "0" ) ) );
    
                x = [
                    ...x,
                    {
                        name:element.name,
                        data:[
                            {
                                name:'Volume',
                                value:Volume
                            },
                            {
                                name:'Inflation',
                                value:Inflation
                            },
                            {
                                name:'Long term agreement',
                                value:Lta
                            },
                            {
                                name:'Tax Rate',
                                value:Tax
                            },
                            {
                                name:'WACC',
                                value:Wacc
                            }
                        ],
                        servicableYear: sy,
                        yearsRemaining : ry
                    }
                ]
            
            });
            
            localPrep.push(x);
        });
        
        setPrep(localPrep);

    },[myresult]);

    const initCompareScenarios = ()=>{
       
        if(compareList.base.length === 0){
            return setError("Base scenario can't be empty !");
        }
        if(compareList.comparable.length === 0){
            return setError("Comparable scenario can't be empty !");
        }

        compare();
    }

    const subTwoArray = (arr:number[][])=>{

        let louter = arr.length;
        let larr = arr.map(elem=> elem.length).reduce((a,b)=> a > b ? a : b);
    
        let newArr:number[] = [];
    
        for(let i=0; i< louter; i++){
            for(let j=0; j<larr; j++){
                if(newArr[j]){
                    newArr[j] -= (arr[i][j] || 0);
                }
                else{
                    newArr[j] = (arr[i][j] || 0);
                }
            }
        }
    
        return newArr;
    
    }

    const addTwoArray = (arr:number[][])=>{

        let louter = arr.length;
        let larr = arr.map(elem=> elem.length).reduce((a,b)=> a > b ? a : b);
    
        let newArr:number[] = [];
    
        for(let i=0; i< louter; i++){
            for(let j=0; j<larr; j++){
                if(newArr[j]){
                    newArr[j] -= (arr[i][j] || 0);
                }
                else{
                    newArr[j] = (arr[i][j] || 0);
                }
            }
        }
    
        return newArr;
    
    }

    const compare = ()=>{   
        
        let x:filteredResulttype[] = [];

        compareList.comparable.forEach(element => {

            let first = myresult[0][parseInt(compareList.base[0].key)];
            let second = myresult[0][parseInt(element.key)];

            console.log("first", first)
            console.log("second", second)

            let res = {
                ...first, 
                name:`${first.name} <-> ${second.name}`,
                output:first.output.map((elem,index)=> ({...elem, data:elem.data.map((el,ind)=> {

                    // if(index === 2 && ind === elem.data.length-1 && (el.value == 11011 || second.output[index].data[ind].value == 11011 ) ){
                    //     return {...el,value: 11011 }
                    // }

                    return {...el,value: parseFloat( ((el.value || 0 )- (second.output[index].data[ind].value || 0)).toFixed(2) ) }
                    
                })}) ),
                cashFlow: [
                   { 
                    atrosRate : first.cashFlow.map(el=> el.atrosRate).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.atrosRate).reduce((a,b)=> a+b),
                    cavities : first.cashFlow.map(el=> el.cavities).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.cavities).reduce((a,b)=> a+b),
                    enterpriseProfitLoss: first.cashFlow.map(el=> el.enterpriseProfitLoss).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.enterpriseProfitLoss).reduce((a,b)=> a+b),
                    greatestTimeFrame: first.cashFlow.map(el=> el.greatestTimeFrame).reduce((a,b)=> a+b) > second.cashFlow.map(el=> el.greatestTimeFrame).reduce((a,b)=> a+b) ?
                    first.cashFlow.map(el=> el.greatestTimeFrame).reduce((a,b)=> a+b)
                    :
                    second.cashFlow.map(el=> el.greatestTimeFrame).reduce((a,b)=> a+b),
                    greatestTotalTimeFrame:first.cashFlow.map(el=> el.greatestTotalTimeFrame).reduce((a,b)=> a+b) > second.cashFlow.map(el=> el.greatestTotalTimeFrame).reduce((a,b)=> a+b) ?
                    first.cashFlow.map(el=> el.greatestTotalTimeFrame).reduce((a,b)=> a+b)
                    :
                    second.cashFlow.map(el=> el.greatestTotalTimeFrame).reduce((a,b)=> a+b),
                    inflation: first.cashFlow.map(el=> el.inflation).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.inflation).reduce((a,b)=> a+b),
                    irrRate:first.cashFlow.map(el=> el.irrRate).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.irrRate).reduce((a,b)=> a+b),
                    oh_inflation: first.cashFlow.map(el=> el.oh_inflation).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.oh_inflation).reduce((a,b)=> a+b),
                    abc_oh_inflation: first.cashFlow.map(el=> el.abc_oh_inflation!).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.abc_oh_inflation!).reduce((a,b)=> a+b),
                    ot_above_x: first.cashFlow.map(el=> el.ot_above_x).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.ot_above_x).reduce((a,b)=> a+b),
                    ot_above_y: first.cashFlow.map(el=> el.ot_above_y).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.ot_above_y).reduce((a,b)=> a+b),
                    scrapRate: first.cashFlow.map(el=> el.scrapRate).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.scrapRate).reduce((a,b)=> a+b),
                    scrapSales: first.cashFlow.map(el=> el.scrapSales).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.scrapSales).reduce((a,b)=> a+b),
                    sellPrice: first.cashFlow.map(el=> el.sellPrice).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.sellPrice).reduce((a,b)=> a+b),
                    tax: first.cashFlow.map(el=> el.tax).reduce((a,b)=> a+b),
                    totalCost: first.cashFlow.map(el=> el.totalCost).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.totalCost).reduce((a,b)=> a+b),
                    totalInv: first.cashFlow.map(el=> el.totalInv).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.totalInv).reduce((a,b)=> a+b),
                    volume: first.cashFlow.map(el=> el.volume).reduce((a,b)=> a+b) - second.cashFlow.map(el=> el.volume).reduce((a,b)=> a+b),
                    wacc: first.cashFlow.map(el=> el.wacc).reduce((a,b)=> a+b),
                    years_remaining: first.cashFlow.map(el=> el.years_remaining).reduce((a,b)=> a+b) > second.cashFlow.map(el=> el.years_remaining).reduce((a,b)=> a+b) ?
                    first.cashFlow.map(el=> el.years_remaining).reduce((a,b)=> a+b)
                    :
                    second.cashFlow.map(el=> el.years_remaining).reduce((a,b)=> a+b),
                    tables: {
                        bv: subTwoArray([addTwoArray(first.cashFlow.map((el,ind)=> el.tables.bv )),addTwoArray(second.cashFlow.map((el,ind)=> el.tables.bv ))]),
                        ds: subTwoArray([addTwoArray(first.cashFlow.map((el,ind)=> el.tables.ds )),addTwoArray(second.cashFlow.map((el,ind)=> el.tables.ds ))]),
                        sv: subTwoArray([addTwoArray(first.cashFlow.map((el,ind)=> el.tables.sv )),addTwoArray(second.cashFlow.map((el,ind)=> el.tables.sv ))])
                    }
                    }
                ]
               
            }



            x.push([{...first},{...second},{...res}]);
            
        });
        

        setComparePopup(false);
        setCompared(x);
        setCompareEnabled(true);
    }

    return(
        <div className="outputScreen">
        {
            (!showGraph && !showTable && !compareEnabled && !showAnalysis && !showAmmort) &&
            <WrapperOutput
            name={["Home","Output"]}
            onBackPress={()=> setResult([])}
            leftButtons={[
               <PrintBtn />,
               <Button
               type="text" 
               className={`${Style.rtBtn}`}
               onClick={()=> setShowAmmort(true) }
               >
                   <MonetizationOnIcon />
                   Loans
               </Button>,
               <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowAnalysis(true) }
                >
                    <MonetizationOnIcon />
                    SP Analysis
                </Button>,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowTable(true) }
                >
                    <MonetizationOnIcon />
                    Cash Flows
                </Button>,                
                <Button 
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowGraph(true) }
                >
                    <BarChartIcon />
                    Graph
                </Button>,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setComparePopup(true) }
                >
                    <CompareIcon />
                    Compare
                </Button>
            ]}
            >
                <div className={`${Style.resHolder} printable`}>
                {
                    loading &&
                    <FSLoader>
                        <p style={{
                            fontSize:"42px"
                        }}>Calculating...</p>
                    </FSLoader>
                }
                {
                    myresult.map((aelem,aindex)=>(
                        <ScrollerWrapper>
                    <>

                    {
                        mapper.map((outerElem,outerInd)=>(
                            <div key={outerInd} className={Style.groupcntwrapper}>
                                <div className={Style.groupcnt}>
                                    <div className={Style.gcHeader}>
                                        <p>{outerElem.name}</p>
                                    </div>

                                    <div className={`${Style.gcContent}`}>

                                        <div className={Style.inpTrow}>
                                            <div className={Style.inpTcell}>

                                            </div>
                                            {
                                                aelem.map((elem,ind)=>(
                                                    <div 
                                                    className={Style.inpTcell}
                                                    key={`fieldsname-${outerInd}${ind}`}
                                                    >
                                                        <div className={Style.scenarioSelector}>
                                                        {elem.name}
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                            
                                        {
                                             outerElem.data.map((innerElem,innerInd)=>(
                                                <React.Fragment key={`fieldsname-${outerInd}${innerInd}`}>
                                                    {
                                                        ((outerInd === 0 && innerInd === 1) || (outerInd === 0 && innerInd === 2)) ?
                                                        <></>
                                                        :
                                                        <div className={
                                                            `
                                                            ${Style.inpTrow}
                                                            ${
                                                                (
                                                                    (outerInd === 0 && innerInd === 0) ||
                                                                    (outerInd === 0 && innerInd === 7) ||
                                                                    (outerInd === 0 && innerInd === 9) ||
                                                                    (outerInd === 0 && innerInd === 11) ||
                                                                    (outerInd === 0 && innerInd === 15) ||
                                                                    (outerInd === 0 && innerInd === 17) ||
                                                                    (outerInd === 0 && innerInd === 19) ||
                                                                    (outerInd === 0 && innerInd === 21) ||
                                                                    (outerInd === 0 && innerInd === 23) ||
                                                                    (outerInd === 0 && innerInd === 25) ||
    
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 0) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 5) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 7) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 9) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 11) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 13) ||
    
                                                                    (outerInd === 3 && innerInd === 1) ||
                                                                    (outerInd === 3 && innerInd === 3) ||
                                                                    (outerInd === 3 && innerInd === 5) ||
                                                                    (outerInd === 3 && innerInd === 7) ||
                                                                    (outerInd === 3 && innerInd === 8)
                                                                ) ?
                                                                Style.rowDivider
                                                                :
                                                                ""
                                                            }
                                                            `
                                                        }>
                                                        <div className={Style.inpTcell}>
                                                            <div className={`${Style.resultInnerLeft}`}>
                                                                {innerElem}
                                                            </div>
                                                        </div>
                                                    {
                                                        aelem.map((reselem,resind)=>(
                                                            <div 
                                                            className={Style.inpTcell}
                                                            key={`fieldsname-${outerInd}${resind}`}
                                                            >
                                                                <div className={`
                                                                inputCnt
                                                                ${Style.resultBox} 
                                                                ${
                                                                    (outerInd === 0 && innerInd ===0) &&
                                                                    ((
                                                                        (reselem.output[outerInd].data[2].value != 0 && reselem.output[outerInd].data[innerInd].value < reselem.output[outerInd].data[2].value) || 
                                                                        (reselem.output[outerInd].data[1].value != 0 && reselem.output[outerInd].data[innerInd].value > reselem.output[outerInd].data[1].value )
                                                                    )
                                                                        ?
                                                                        "danger"
                                                                        :
                                                                        "green"
                                                                    )
                                                                }
                                                                ${
                                                                    (
                                                                        (outerInd === 0 && innerInd ===8) ||
                                                                        (outerInd === 0 && innerInd ===9) ||
                                                                        (outerInd === 0 && innerInd ===10) ||
                                                                        (outerInd === 0 && innerInd ===11) ||
                                                                        (outerInd === 0 && innerInd ===19) ||
                                                                        (outerInd === 0 && innerInd ===20) ||
                                                                        // (outerInd === 0 && innerInd ===16) ||
                                                                        // (outerInd === 0 && innerInd ===17) ||
                                                                        // (outerInd === 0 && innerInd ===21) ||
                                                                        (outerInd === 1 && innerInd ===6) ||
                                                                        (outerInd === 1 && innerInd ===7) ||
                                                                        (outerInd === 1 && innerInd ===8) ||
                                                                        (outerInd === 1 && innerInd ===9) ||
                                                                        (outerInd === 1 && innerInd ===10) ||
                                                                        (outerInd === 1 && innerInd ===11) ||
                                                      
                                                                        (outerInd === 2 && innerInd ===6) ||
                                                                        (outerInd === 2 && innerInd ===7) ||
                                                                        (outerInd === 2 && innerInd ===8) ||
                                                                        (outerInd === 2 && innerInd ===9) ||
                                                                        (outerInd === 2 && innerInd ===10) ||
                                                                        (outerInd === 2 && innerInd ===11) 
                                                                    ) &&
                                                                    (
                                                                        reselem.output[outerInd].data[innerInd].value < 0 ? "danger" : "green"
                                                                    )
                                                                } 

                                                                ${
                                                                    
                                                                    (outerInd === 0 && (innerInd === 23 || innerInd === 24 || innerInd === 25 || innerInd === 26 )) && 
                                                                    ((((reselem.output[0].data[innerInd].value/reselem.output[0].data[0].value) * 100 > ( (reselem.cashFlow.map(elem=> elem.atrosRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length ) ) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }
                                                                ${
                                                                    
                                                                    ((outerInd === 1 || outerInd === 2) && (innerInd === 12 || innerInd === 13 || innerInd === 14 || innerInd === 15)) && 
                                                                    ((((reselem.output[0].data[innerInd+11].value/reselem.output[0].data[0].value) * 100 > ( (reselem.cashFlow.map(elem=> elem.atrosRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length ) ) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }
                                                                ${
                                                                    
                                                                    (outerInd === 2 && innerInd ===16) &&
                                                                    (reselem.output[outerInd].data[innerInd].value < ( (reselem.cashFlow.map(elem=> elem.irrRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  ? "danger" : reselem.output[outerInd].data[innerInd].value != 11011 && "green")
                                                                    
                                                                }

                                                                ${
                                                                    
                                                                    ((outerInd === 3 && innerInd ===0) || (outerInd === 3 && innerInd ===1)) &&
                                                                    (
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > ( (reselem.cashFlow.map(elem=> elem.ot_above_x).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  && 
                                                                            reselem.output[outerInd].data[innerInd].value <= ( (reselem.cashFlow.map(elem=> elem.ot_above_y).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length ) 
                                                                        )? "danger" :
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > ( (reselem.cashFlow.map(elem=> elem.ot_above_y).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  && 
                                                                            reselem.output[outerInd].data[innerInd].value <= 8500
                                                                        ) ? "superdanger" : 
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > 8500 && "notpossiblevalue"
                                                                        )
                                                                    )
                                                                    
                                                                }                                                            

                                                                `}>
                                                                    {
                                                                        reselem.output[outerInd].data[innerInd].type === "currency" &&
                                                                            <CurrencyIconRenderer type={myCurrency} className="currencyIcon" />
                                                                    }
                                                                    
                                                                    {
                                                                        (reselem.output[outerInd].data[innerInd].value) ? 
                                                                        <>
                                                                        {
                                                                            (outerInd === 2 && innerInd === 16) ?
                                                                            <>
                                                                            {
                                                                                (reselem.output[outerInd].data[innerInd].value != 11011) ?
                                                                                myCurrency === "INR" ?
                                                                                    NumberFormat.toINDstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :
                                                                                    NumberFormat.toUSstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :

                                                                                "NA"
                                                                            }
                                                                            </>
                                                                            :
                                                                            ((outerInd === 3 && innerInd === 2 )|| (outerInd === 3 && innerInd === 3)) ?
                                                                            <>
                                                                            {
                                                                                (outerInd === 3 && innerInd === 2) &&
                                                                                  <input 
                                                                                  className="outputScreenInput"
                                                                                  value={
                                                                                      myCurrency === "INR" ?
                                                                                      NumberFormat.toINDstandard((payload[resind].data.abcAnnualHours))
                                                                                      :
                                                                                      NumberFormat.toUSstandard((payload[resind].data.abcAnnualHours))
                                                                                  }
                                                                                  onChange={(e)=>{
                                                                                      let x = [...payload];
                                                                                      x[resind].data.abcAnnualHours = NumberFormat.toNumber(e.target.value);
                                                                                      setPayload(x);
                                                                                      reCalc(x);
                                                                                  }}
                                                                                  />
                                                                            }
                                                                            {
                                                                                (outerInd === 3 && innerInd === 3) &&
                                                                                  <input 
                                                                                  className="outputScreenInput"
                                                                                  value={
                                                                                      myCurrency === "INR" ?
                                                                                      NumberFormat.toINDstandard((payload[resind].data.abcAnnualHoursNc))
                                                                                      :
                                                                                      NumberFormat.toUSstandard((payload[resind].data.abcAnnualHoursNc))
                                                                                  }
                                                                                  onChange={(e)=>{
                                                                                      let x = [...payload];
                                                                                      x[resind].data.abcAnnualHoursNc = NumberFormat.toNumber(e.target.value);
                                                                                      setPayload(x);
                                                                                      reCalc(x);
                                                                                  }}
                                                                                  />
                                                                            }
                                                                            
                                                                            </>
                                                                            :
                                                                            myCurrency === "INR" ?
                                                                                NumberFormat.toINDstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :
                                                                                NumberFormat.toUSstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                            
                                                                        }
                                                           
                                                                        </>
                                                                        :
                                                                        <>
                                                                        {
                                                                            ((outerInd === 3 && innerInd === 2 )|| (outerInd === 3 && innerInd === 3)) ?
                                                                            <>
                                                                            {
                                                                                (outerInd === 3 && innerInd === 2) &&
                                                                                  <input 
                                                                                  className="outputScreenInput"
                                                                                  value={
                                                                                      myCurrency === "INR" ?
                                                                                      NumberFormat.toINDstandard((payload[resind].data.abcAnnualHours))
                                                                                      :
                                                                                      NumberFormat.toUSstandard((payload[resind].data.abcAnnualHours))
                                                                                  }
                                                                                  onChange={(e)=>{
                                                                                      let x = [...payload];
                                                                                      x[resind].data.abcAnnualHours = NumberFormat.toNumber(e.target.value);
                                                                                      setPayload(x);
                                                                                      reCalc(x);
                                                                                  }} 
                                                                                  />
                                                                            }
                                                                            {
                                                                                (outerInd === 3 && innerInd === 3) &&
                                                                                <input 
                                                                                className="outputScreenInput"
                                                                                value={
                                                                                    myCurrency === "INR" ?
                                                                                    NumberFormat.toINDstandard((payload[resind].data.abcAnnualHoursNc))
                                                                                    :
                                                                                    NumberFormat.toUSstandard((payload[resind].data.abcAnnualHoursNc))
                                                                                }
                                                                                onChange={(e)=>{
                                                                                    let x = [...payload];
                                                                                    x[resind].data.abcAnnualHoursNc = NumberFormat.toNumber(e.target.value);
                                                                                    setPayload(x);
                                                                                    reCalc(x);
                                                                                }} 
                                                                                />
                                                                            }
                                                                            
                                                                            </>
                                                                            :
                                                                            0
                                                                        }
                                                                      
                                                                        </>
                                                                            
                                                                    }
                                                                   
                                                                    {
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].type === "perc" && 
                                                                            reselem.output[outerInd].data[innerInd].value != 11011 
                                                                        ) &&
                                                                        <span className="signs">
                                                                        %
                                                                        </span>
                                                                    }
                                                                    </div>
                                                                
                                                            </div>
                                                        ))
                                                    }
                                                    </div>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                    </div>

                                </div>
                            </div>
                        ))
                    }

                    </>
                </ScrollerWrapper>
                    ))
                }
                
                </div>
            </WrapperOutput>
        }
        {
            
            (showGraph && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowGraph(false) }
            name={["Home","Output","Graphs"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <GraphScreen 
                mapper={mapper.map((aelem,aind)=> ({ ...aelem, data: aelem.data.filter((belem,bind)=> (aind === 0 && (bind === 1 || bind === 2) ) ? false : true ) }) )}
                myresult={ myresult.map((elem,ind)=>  elem.map((el,ii)=> ({...el,output: el.output.map((inner,innerInd)=> ( {...inner,data:inner.data.filter((sin,sinInd)=> (innerInd === 0 && (sinInd === 1 || sinInd === 2)) ? false : true )} ) ) }) ) ) }
                />
            </WrapperOutput>
            
            
        }
        {
            (showTable && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowTable(false) }
            name={["Home","Output","Cash Flows"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <TableScreen
                    myresult={myresult}
                    compareJobs={compareJobs}
                    prep={prep}
                    setPrep={setPrep}
                    submitableScenario={submitableScenario}
                    payload={payload}
                />
            </WrapperOutput>
        }
        {
            (showAnalysis && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowAnalysis(false) }
            name={["Home","Output","SP Analysis"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
    
                <AnalysisScreen
                myresult={myresult[0]}
                />
            </WrapperOutput>
        }
        {
            ((showAmmort && !selectedLoan) && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setShowAmmort(false) }
            name={["Home","Output","Loans"]}
            leftButtons={[
         
            ]}
            >
                
                <LoanScreen 
                result={result} 
                myCurrency={myCurrency} 
                selectedLoan={selectedLoan} 
                setSelectedLoan={setSelectedLoan} 
                setSelectedLoanResult={setSelectedLoanResult} 
                compareJobs={compareJobs}
                />
                
            </WrapperOutput>
        }
        {
            ((showAmmort && selectedLoan) && !compareEnabled) &&
            <WrapperOutput
            onBackPress={()=> setSelectedLoan("") }
            name={["Home","Output","Loans",LoanNameFormatter.format(selectedLoan)]}
            leftButtons={[
         
            ]}
            >
                
                <SingleLoanScreen 
                result={selectedLoanResult} 
                myCurrency={myCurrency} 
                selectedLoan={selectedLoan}  
                compareJobs={compareJobs}
                />
                
            </WrapperOutput>
        }
        {
            compareEnabled &&             
            <ComparedScreen 
            result={compared}
            setCompareEnabled={setCompareEnabled}
            myCurrency={myCurrency}
            mapper={mapper}
            submitableScenario={submitableScenario}
            />
        }
        {
            comparePopup &&
            <Modal
            className="modal"
            open={comparePopup}
            onClose={()=> {
                setComparePopup(false);
            }}
            >
    
            <div className="modalPopUp">

                <CloseOutlined
                className="popupClose" 
                onClick={()=> {
                    setComparePopup(false);
                }}
                />
                <Flynaut />

                <DragDropContext onDragEnd={onDragEnd}>

                <div className={Style.cmpListCnt}>
                        <div className={Style.cmpListTop}>
                        
                            <div className={Style.listCnt}>
                                <p>Options</p>
                                <Droppable droppableId="scenarios" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.scenario?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                
                                                            <div className={Style.listTile}>
                                                            {elem.name}
                                                            </div>
                                                            
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>
                            </div>
                    

                        </div>
                        <div className={Style.cmpListBot}>

                            <div className={Style.listCnt}>

                                <p>Base Scenario</p>
                                <Droppable  droppableId="base" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.base?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                        
                                                        <div className={Style.listTile}>
                                                                {elem.name}
                                                            </div>
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>

                            </div>
                            <div className={Style.listCnt}>
                                <p>Comparable Scenario</p>
                                <Droppable droppableId="comparable" type="scenarios">
                                    {(provided, snapshot) => {
                                    return (
                                        <div
                                        className={Style.mainListCnt}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        >
                                        {
                                            compareList.comparable?.map((elem,ind)=>(
                                                <Draggable key={elem.key} draggableId={`${elem.key}`} index={ind}>
                                                    {(provided, snapshot) => {
                                                        return (
                                                        <div
                                                        className={Style.listTileCnt}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                        
                                                            <div className={Style.listTile}>
                                                                {elem.name}
                                                            </div>
                                                        
                                                        </div>
                                                        );
                                                    }}
                                                    </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                        </div>
                                    );
                                    }}
                                </Droppable>
                            </div>

                        </div>
                    </div>
                </DragDropContext>
                
                <div className="mpuBot">

                <Button className="histbtn-blue" type="text" onClick={initCompareScenarios}>
                    Compare <RightOutlined />
                </Button>

                </div>
            </div>      
            </Modal>
        }
        {
            error &&
            <Modal
            open={error ? true : false}
            onClose={()=> {
                setError("");
            }}
            >
                <ModalBox
                open={error ? true : false}
                onClose={()=> setError("") }
                header="Restricted !"
                message={error} 
                onOk={()=>{
                    setError("")
                }}
                />
            </Modal>
        }
        </div>
    )
}

export default memo(OutputScreen);