import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { User } from '../@types/user'

export interface userState {
    user:null | User
}

const initialState: userState = {
  user: null
}


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers:{
    login : (state, action: PayloadAction<User>)=>{
        state.user = action.payload
    },
    logout:(state)=>{
        state.user = null;
        sessionStorage.removeItem("ccouser");
        localStorage.removeItem("ccouser");
        sessionStorage.removeItem("activeMenu");
    },
    updateUser : (state, action: PayloadAction<User>)=>{
      let prep = {...state.user, ...action.payload};
      state.user = prep as User;
      if(localStorage.getItem("ccouser")){
        localStorage.setItem("ccouser", JSON.stringify(prep));
      }
      else{
        sessionStorage.setItem("ccouser", JSON.stringify(prep));
      }
    }, 
  }
})

// Action creators are generated for each case reducer function
export const { 
    login,
    logout,
    updateUser
   } = userSlice.actions

export default userSlice.reducer