import React, { ReactElement, useEffect, useState } from "react";

interface ModalBox2Props {
    header: string;
    message: ReactElement;
    buttons: ReactElement[];
    open: boolean;
    onClose?: () => void;
}

const ModalBox2 = ({
    header,
    message,
    buttons,
    open,
    onClose
}: ModalBox2Props): JSX.Element => {
    const [active, setActive] = useState<boolean>(open);

    useEffect(() => {
        setActive(open);
    }, [open]);

    const handleClose = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (onClose) {
            setActive(false);
            onClose();
        }
    };

    const handleModalClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
    };

    if (!active) {
        return <></>;
    }

    return (
        <div className="modalBox" onClick={handleClose}>
            <div className="modalInner" onClick={handleModalClick}>
                <div 
                    className={`modalHeader `}
                >
                    <p>{header}</p>
                </div>
                <div className="modalContent">
                    {message}
                </div>
                <div className="modalBtnCnt">
                    {buttons.map((button, index) => (
                        <React.Fragment key={index}>
                            {button}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ModalBox2;