import { Typography } from '@mui/material';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { User } from '../../@types/user';
import { Axios } from '../../Axios';
import { RootState } from '../../redux/store';
import { updateUser } from '../../redux/userSlice';
import InputBox from '../input';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';

const Profile: React.FC = () => {

    const user = useSelector((state: RootState) => state.user.user);
    const dispatch = useDispatch();
    const [fullName,setFullName] = useState(user?.name || "");
    const [currPass,setCurrPass] = useState("");
    const [newPass,setNewPass] = useState("");
    const [cnfPass,setCnfPass] = useState("");
    const [loadName,setLoadName] = useState(false);
    const [loadPass,setLoadPass] = useState(false);

    const submitChangePassword = ()=>{

        setLoadPass(true);

        Axios.post(`/api/v1/change-password`,{
            currPass,
            newPass,
            cnfPass
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=> {

            toast.success("Password updated successfully");
            setCurrPass("");
            setNewPass("");
            setCnfPass("");

        })
        .catch(err=>{
            let errMsg = "";
            if(err.response){

                errMsg = err.response.data.message;
            }
            else{
                errMsg = "Network Error";
            }

            toast.error(errMsg);
        })
        .finally(()=>{
            setLoadPass(false);
        })
    }

    const submitUpdateName = ()=>{

        setLoadName(true);

        Axios.post(`/api/v1/update-profile`,{
            name:fullName
        },{
            headers:{
                Authorization:user?.token
            }
        })
        .then(result=>{
            toast.success("Name updated successfully");
            let prep = {...user, name:fullName};
            dispatch(updateUser(prep as User ));
        })
        .catch(err=>{
            let errMsg = "";
            if(err.response){
                errMsg = err.response.data.message;
            }
            else{
                errMsg = "Network Error";   
            }

            toast.error(errMsg);
        })
        .finally(()=>{
            setLoadName(false);
        })
    }

    const groupStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: '35px 0 45px 0',
        maxWidth:"555px",
    };

    const headingStyle: React.CSSProperties = {
        color:"black",
        fontSize:"18px",
        fontWeight:"600",
    };

    const descriptionStyle: React.CSSProperties = {
        fontSize:"15px",
        fontWeight:"400",
        color:"#666666",
    };

    const inputContainerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        margin: "5px 0 0 0"
    };

    return (
        <AuthenticatedLayout>
            <Typography variant="h6" gutterBottom={true}>Profile Settings</Typography>

            <div style={groupStyle}>
                <p style={headingStyle}>User Name</p>
                <p style={descriptionStyle}>Update your display name</p>
                <div style={inputContainerStyle}>
                <InputBox
                containerStyle={{maxWidth:"300px"}}
                type="text"
                placeholder={"Full Name"}
                value={fullName}
                onChange={(e)=> {
                    setFullName(e.target.value);
                }}
                    />
                <Button
                loading={loadName}
                onClick={submitUpdateName}
                    type="primary"
                    style={{
                        width: '155px',
                        height: '35px',
                        backgroundColor: '#1976d2'
                    }}
                >
                    Update Name
                </Button>

                </div>
         
               
             
            </div>

            <div style={groupStyle}>
                <p style={headingStyle}>Update Password</p>
                <p style={descriptionStyle}>Change your password to keep your account secure. Use a strong password that includes numbers, letters, and special characters.</p>
                <div style={inputContainerStyle}>
                <InputBox
                containerStyle={{maxWidth:"300px"}}
                type="password"
                placeholder={"Current Password"}
                value={currPass}
                onChange={(e)=> {
                    setCurrPass(e.target.value);
                }}
                    />
                {/* <br /> */}

                <InputBox
                containerStyle={{maxWidth:"300px"}}
                type="password"
                placeholder={"New Password"}
                value={newPass}
                onChange={(e)=> {
                    setNewPass(e.target.value);
                }}
                    />

                <InputBox
                containerStyle={{maxWidth:"300px"}}
                type="password"
                placeholder={"Confirm Password"}
                value={cnfPass}
                onChange={(e)=> {
                    setCnfPass(e.target.value);
                }}
                    />
                <Button
                loading={loadPass}
                onClick={submitChangePassword}
                type="primary"
                style={{
                    width: '155px',
                    height: '35px',
                    backgroundColor: '#1976d2'
                }}
                >
                    Update Password
                </Button>
                
                </div>
               
            </div>
            
        </AuthenticatedLayout>
    );
};

export default Profile;