// External dependencies
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { CircleNotifications } from "@mui/icons-material";
import { Modal } from "@mui/material";
import { Alert, Button } from "antd";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Types and interfaces
import { CalculatorChildType, newFormInpPropType } from "../../@types/user";

// Redux and state management
import { boolClose, boolInnerInputChange, boolInputchange, boolOk } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";

// Components
import HintGuide from "../guide/HintGuide";
import InputBox from "../input";
import ModalBox from "../modal";

// Assets
import question_ico from "../../svg/question.svg";

interface EntryType {
  name: string;
  value: string;
  type: string;
  currency?: boolean;
}

interface CalculationResult {
  csp: number;
  cbc: number;
}


const BoolDrop: React.FC<newFormInpPropType> = ({
  calcList,
  activeIndex,
  index,
  data,
  outerIndex
}) => {
  const version = useSelector((state: RootState) => 
    state.calculator.tabs[state.calculator.activeTab].version
  );

  const [showMsg, setShowMsg] = useState<boolean>(false);
  const msgCnt = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLImageElement>(null);
  const inpCnt = useRef<HTMLDivElement>(null);

  const [targetElem] = useState<number>(2);
  const [targetData, setTargetData] = useState<CalculatorChildType>();
  const [lastInvValue, setLastInvValue] = useState<number>(0);
  const [showPopup2, setPopUp2] = useState<boolean>(false);
  const [supSub, setSupSub] = useState<EntryType[][]>([]);
  const [innerError, setInnerError] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('click', windowClickFunction);
    return () => {
      window.removeEventListener('click', windowClickFunction);
    };
  }, []);

  useEffect(() => {
    if (!calcList || !targetElem) return;

    const prep = [...calcList];
    const prep2 = prep[outerIndex];
    if (!prep2) return;

    const ggi = [...prep2];
    const gg = ggi[activeIndex].child[targetElem];
    if (!gg) return;

    setTargetData(gg);
    if (gg.entries) {
      setLastInvValue(gg.entries.length);
    }

    const entries = gg.entries || [];
    if (entries.length > 0) {
      setSupSub(entries);
    }
  }, [calcList, targetElem]);

  const windowClickFunction = (e: MouseEvent): void => {
    if (msgCnt.current && msgRef.current) {
      if (!msgCnt.current.contains(e.target as Node) && !msgRef.current.contains(e.target as Node)) {
        setShowMsg(false);
      }
    }
  };

  const changefunc = async (e: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const ret = await dispatch(boolInputchange({
      value: e.target.value,
      outerIndex,
      innerIndex: index,
      lastInpValue: lastInvValue
    }));

    const retOb = JSON.parse(ret.payload.value);
    setPopUp2(retOb.popup2 || false);
  };

  const closeFunc = (): void => {
    dispatch(boolClose({
      value: "",
      outerIndex,
      innerIndex: index
    }));
    setPopUp2(false);
  };

  const handleDoneClick = (): void => {
    const res: CalculationResult = {
      csp: 0,
      cbc: 0,
    };

    let err = false;

    for (let i = 0; i < supSub.length; i++) {
      for (let j = 0; j < supSub[i].length; j++) {
        if (!supSub[i][j].value) {
          err = true;
        }
        if (j === 0) {
          res.csp += parseFloat(supSub[i][j].value) || 0;
        }
        if (j === 1) {
          res.cbc += parseFloat(supSub[i][j].value) || 0;
        }
      }
    }

    if (res.cbc < 1 || res.csp < 1) {
      err = true;
    }

    if (!err) {
      setPopUp2(false);
      dispatch(boolOk({
        value: JSON.stringify({ res, supLength: supSub.length, error: err }),
        outerIndex,
        innerIndex: index
      }));
    } else {
      setInnerError(true);
    }
  };

  return (
    <>
      <Modal
        className="modal"
        open={showPopup2}
        onClose={closeFunc}
      >
        <div className="modalPopUpGrid">
          <CloseOutlined
            className="popupClose"
            onClick={closeFunc}
          />
          <div className="mpugTop">
            {targetData?.entries?.map((elem, ind) => (
              <div key={`$nestedBool${ind}`}>
                <h4 style={{ margin: "10px 0", color: "#24336D" }}>
                  {`${targetData?.popupArrayName} ${ind + 1}`}
                </h4>
                {elem.map((ielem, iind) => (
                  <InputBox
                    key={`nestedBool${ind}${iind}`}
                    type={ielem.type}
                    placeholder={ielem.name}
                    value={ielem.value}
                    image={<CircleNotifications />}
                    currency={ielem.currency}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch(boolInnerInputChange({
                        value: JSON.stringify({
                          targetElem,
                          data: e.target.value,
                          ind,
                          iind
                        }),
                        outerIndex,
                        innerIndex: index
                      }));
                    }}
                  />
                ))}
              </div>
            ))}
          </div>

          <div className="mpugBot">
            <Button className="mybtn-blue" type="text" onClick={handleDoneClick}>
              Done <RightOutlined />
            </Button>
          </div>
        </div>
      </Modal>

      {innerError && (
        <ModalBox
          open={innerError}
          onClose={() => setInnerError(false)}
          onOk={() => setInnerError(false)}
          header="Improper Entries"
          message="You have one or more empty fileds. Enter 0 if there is no value !"
        />
      )}

      <div className="formMainCnt">
        <p className="formTitle">{data?.name}</p>
        <div ref={inpCnt} className={`formInputCnt ${data?.error ? "error" : ""}`}>
          <select
            className="mainInput myselect"
            value={data?.value}
            disabled={data?.disabled}
            onChange={changefunc}
          >
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>

          <img
            className="formQuestion"
            src={question_ico}
            alt="show input field description button"
            onClick={() => setShowMsg(true)}
            ref={msgRef}
          />
        </div>

        {data?.error && (
          <Alert
            style={{ fontSize: "12px", padding: "5px 10px" }}
            description="Mandatory Field"
            type="error"
            showIcon={false}
          />
        )}

        {showMsg && (
          <>
            {parseInt(version?.replaceAll("v", "") || "0") > 10 ? (
              <HintGuide
                onClose={() => setShowMsg(false)}
                targetElem={msgRef?.current}
                heading={data?.name}
                msg={data?.msg}
              />
            ) : (
              <div className="formMsg" ref={msgCnt}>
                {parseInt(version?.replaceAll("v", "") || "0") >= 3 ? (
                  Array.isArray(data?.msg) && data?.msg.map((elem: string, index: number) => (
                    <p key={`msg${index}`}>{elem}</p>
                  ))
                ) : (
                  <p>{data?.msg}</p>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default memo(BoolDrop);