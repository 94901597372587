// External libraries
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';

// React
import { useRef, useState } from 'react';

// Local imports
import PopupMenu from '../menu/PopupMenu';
import styles from './tdm.module.css';

interface TripleDotMenuProps {
  items: any[];
  rowId: string;
}

const TripleDotMenu = ({ items, rowId }: TripleDotMenuProps): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const mTarget = useRef<HTMLButtonElement>(null);

  const handleClick = (): void => {
    setShowMenu(false);
  };

  return (
    <>
      {showMenu && (
        <PopupMenu
          targetElem={mTarget.current}
          onCloseTrigger={() => setShowMenu(prev => !prev)}
        >
          <ul className={styles.cnt}>
            {items.map((elem, index) => (
              <li key={index} onClick={handleClick}>
                {elem}
              </li>
            ))}
          </ul>
        </PopupMenu>
      )}
      <IconButton
        sx={{ margin: 'auto 15px auto 0', height: '35px', width: '35px' }}
        ref={mTarget}
        onClick={() => setShowMenu(true)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
    </>
  );
};

export default TripleDotMenu;