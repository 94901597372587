import { Route, Routes } from "react-router-dom";
import './App.css';
import Forget from './pages/Forget';
import Histories from './pages/Histories';
import Home from './pages/Home';
import Login from './pages/Login';
import Otp from './pages/Otp';
import Settings from "./pages/Settings";

const App = ()=>{

  return (
    <>

      <Routes>

        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/histories' element={<Histories />} />
        <Route path='/forget-password' element={<Forget />} />
        <Route path='/verify' element={<Otp />} />
        <Route path='/settings' element={<Settings />} />
        
      </Routes>

    </>
  );
}

export default App;
