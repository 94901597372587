import { FC } from 'react';

import Ndollar from '../svg/currency/naked/Ndollar';
import Neuro from '../svg/currency/naked/Neuro';
import Npound from '../svg/currency/naked/Npound';
import Nrupee from '../svg/currency/naked/Nrupee';
import Nyen from '../svg/currency/naked/Nyen';

interface CurrencyIconRendererProps {
    type: string;
    className?: string;
    color?: string;
}

const CurrencyIconRenderer: FC<CurrencyIconRendererProps> = ({ type, color, className }) => {
    const defaultColor = color || '#656a72';

    const renderIcon = () => {
        switch (type) {
            case 'USD':
                return <Ndollar className={className} color={defaultColor} />;
            case 'INR':
                return <Nrupee className={className} color={defaultColor} />;
            case 'GBP':
                return <Npound className={className} color={defaultColor} />;
            case 'EUR':
                return <Neuro className={className} color={defaultColor} />;
            case 'JPY':
            case 'CNY':
                return <Nyen className={className} color={defaultColor} />;
            default:
                return null;
        }
    };

    return (
        <div className="CCurrencyHolder">
            {renderIcon()}
        </div>
    );
};

export default CurrencyIconRenderer;