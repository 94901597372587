// External libraries
import { Timeline } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// React
import { useCallback } from 'react';

// Local imports
import { CalculatorType } from '../../@types/user';
import { verifyFormPage } from '../../redux/calculatorSlice';
import { RootState } from '../../redux/store';
import styles from '../../styles/timeline.module.css';

// Assets
import empty_circle from '../../svg/empty_circle.svg';
import form_err from '../../svg/form_err.svg';
import hollow_circle from '../../svg/hollow_circle.svg';
import tick from '../../svg/tick.svg';

interface TimelineContainerProps {
  calcList: CalculatorType;
  myFunc?: () => void;
}

const TimelineContainer = ({
  calcList,
  myFunc
}: TimelineContainerProps): JSX.Element => {
  const dispatch = useDispatch();
  const page = useSelector((state: RootState) => 
    state.calculator.activeScreen?.jobs[state.calculator.activeJob].activeIndex || 0
  );

  const handleIndexClick = useCallback((index: number): void => {
    dispatch(verifyFormPage(index));
    myFunc?.();
  }, [dispatch, myFunc]);

  const getTimelineItem = (elem: CalculatorType[number], index: number) => {
    const getClassName = (): string => {
      if (elem.cmp) {
        return page === index ? styles.lineCurrent : styles.lineActive;
      }
      if (page === index) {
        return styles.lineCurrent;
      }
      return elem.pageErr ? styles.lineErr : styles.lineInactive;
    };

    const getDotIcon = (): string => {
      if (elem.cmp) return tick;
      if (elem.pageErr) return form_err;
      return page === index ? hollow_circle : empty_circle;
    };

    return {
      children: (
        <p
          onClick={() => handleIndexClick(index)}
          className={`${styles.tPara} ${getClassName()}`}
        >
          {elem.name}
        </p>
      ),
      dot: (
        <img
          onClick={() => handleIndexClick(index)}
          className={styles.dot}
          src={getDotIcon()}
          style={{ width: '20px' }}
          alt="icon"
        />
      )
    };
  };

  return (
    <Timeline
      rootClassName={styles.timelineCnt}
      items={calcList.map(getTimelineItem)}
    />
  );
};

export default TimelineContainer;