import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { CalculatorChildType, Payload, resultType, scenarioType, submitScenarioType, tabType } from "../@types/user";
import Style from "../styles/calc.module.css";
import BoolDrop from "./inputs/BoolDrop";
import Decisive from "./inputs/Decisive";
import DoublePopup from "./inputs/DoublePopup";
import Number from "./inputs/Number";
import Text from "./inputs/Text";
// import OutputScreen from "./output/OutputScreen";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NavigationIcon from '@mui/icons-material/Navigation';
import SettingsIcon from '@mui/icons-material/Settings';
import { Checkbox, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Axios } from "../Axios";
import { VERSION } from "../config/env";
import useOutputByVersion from "../hooks/useOutputByVersion";
import { getData, insertScenarioWiseJob, replaceJobs, resetFormEntries, setIds, verifyAllJobs, verifyFormPage } from "../redux/calculatorSlice";
import { RootState } from "../redux/store";
import Cdollar from "../svg/currency/circular/Cdollar";
import Ceuro from "../svg/currency/circular/Ceuro";
import Cpound from "../svg/currency/circular/Cpound";
import Crupee from "../svg/currency/circular/Crupee";
import Cyen from "../svg/currency/circular/Cyen";
import CalcOperation from "../utils/Calculator";
import FieldKeys from "../utils/getFieldKey";
import PasswordGenerator from "../utils/PasswordGenerator";
import Clock from "./Clock";
import FSLoader from "./FSLoader";
import OneScreenPopup from "./inputs/OneScreenPopup";
import Popup from "./inputs/Popup";
import ModalBox from "./modal";
import OutputScreen from "./output/OutputScreen";
import TimelineContainer from "./timeline";

const currencies=[
    {value:"USD",label:<div className="selecHolder"><Cdollar /> Dollar</div>},
    {value:"GBP",label:<div className="selecHolder"><Cpound /> Pound</div>},
    {value:"EUR",label:<div className="selecHolder"><Ceuro /> Euro</div> },
    {value:"JPY",label:<div className="selecHolder"><Cyen /> Yen</div>},
    {value:"CNY",label:<div className="selecHolder"><Cyen /> Chinese Yuan</div>},
    {value:"INR",label:<div className="selecHolder"><Crupee /> Rupee</div>}

]

type calcProp = {
    className:string

}
const Calculator = ({className}:calcProp)=>{

    const user = useSelector((state: RootState) => state.user.user );
    const [loading,setLoading] = useState<boolean>(false);
    const [currencyLoading,setCurrencyLoading] = useState<boolean>(false);
    
    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version ));
    const [ OutputScreenbyVersion ] = useOutputByVersion(version);
    
    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    const tabs = useSelector((state: RootState) => state.calculator.tabs[activeTab]);
    const activeScenarioIndex = useSelector((state: RootState) => state.calculator.activeScenario);
    const activeJob = useSelector((state: RootState) => state.calculator.activeJob);
    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs);   
    const splitData = useSelector((state: RootState) => state.calculator.activeScreen?.jobs[state.calculator.activeJob].splitData);
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "USD");
    const calcList = useSelector((state: RootState) => state.calculator.activeScreen?.jobs[state.calculator.activeJob].data || []);
    const activeIndex = useSelector((state: RootState) => (state.calculator.activeScreen?.jobs[state.calculator.activeJob].activeIndex || 0));
    const [submitableScenario,setSubmitableScenario] = useState<submitScenarioType[]>([]);


    const dispatch = useDispatch();

    const [error,setError] = useState(false);
    const [localData,setLocalData] = useState<CalculatorChildType[][]>([]);
    const [confirmRestart,setConfirmRestart] = useState(false);

    const [settingOpen,setSettingOpen] = useState(false);
    const allScenarios = useSelector((state: RootState) => state.calculator.activeTabScenarios );
    const [selectedScenariosIndex,setSelectedScenariosIndex] = useState<{ind:number,jobs:number[]}[]>([]);
    const [changableScenariosIndex,setChangableScenariosIndex] = useState<{ind:number,jobs:number[]}[]>([{
        ind:activeScenarioIndex,
        jobs:[activeJob]
    }]);

    const [customError,setCustomError] = useState("");
    const [result,setResult] = useState<resultType>([]);
    const [openMobileIndex,setOpenMobileIndex] = useState(false);

    const [payload,setPayload] = useState<Payload>([]);
    const [customCount,setCustomCount] = useState(0);
    const [myTo,setMyTo] = useState<any>();

    const [activeSetTab,setActiveSetTab] = useState(0);

    const [mergeIntoNew,setMergeIntoNew] = useState<number[]>([]);


   

    useEffect(()=>{

        // for(let i=0; i<=100; i++){
        //     console.log("random key "+i, PasswordGenerator.getUniqid())
        // }

        console.log("version", VERSION)
        let newx:CalculatorChildType[][] = [];

        calcList.forEach(element => {
            newx.push( element.filter((felem,index)=> ( index === activeIndex) )[0].child );
        });

        setLocalData(newx);
        setResult([]);

        // console.log("version", version);

    },[activeIndex,calcList]);

    useEffect(()=>{

        let dec = activeTabJobs[activeJob].type == 1 ? 
        activeTabJobs[activeJob].splitData?.map(elem=> parseInt(elem))  : 
        [activeJob];

        setChangableScenariosIndex([{
            ind:activeScenarioIndex,
            jobs:dec!
        }]);

    },[activeScenarioIndex,activeJob]);

    useEffect(()=>{
        if(settingOpen){
            let err = dispatch( verifyFormPage(calcList[0].length) );
        }

    },[settingOpen])


    useEffect(()=>{
        setSelectedScenariosIndex([]);
    },[activeSetTab]);

    const reCalc = useCallback((val:Payload)=>{

        clearTimeout(myTo);
        setMyTo(setTimeout(()=>{
        
            submitData(val);
        },700));
    },[payload]);

    const restartForm = useCallback(()=>{

        dispatch( resetFormEntries() );
    },[])

    const submitData = (val:(null | Payload) = null)=>{
        
        setLoading(true);
        
        let pre = dispatch(getData(""));
        let data = JSON.parse(pre.payload);
        let calc:tabType = data.tabs;


        type Sender={
            tabId:string,
            tabName:string,
            scenarios:submitScenarioType[],
            version:string,
            timeZone:string,
            payload:Payload | null,
            compareJobs : boolean
        }
        
        let allSelectedScenarios:scenarioType[] = [];
        let choose = selectedScenariosIndex.length > 0 ? selectedScenariosIndex : changableScenariosIndex;
        let aind:number[] = [];

        choose.forEach((element) => {

            let selec = calc[data.activeTab].scenario[element.ind];
            allSelectedScenarios.push( selec );

            if(mergeIntoNew.indexOf(element.ind) !== -1){

                aind.push(allSelectedScenarios.length - 1);
            }
         
        });

        let allScenarios:submitScenarioType[] = allSelectedScenarios.map((selem,eindex)=> {
           
            return {
                id: selem.id || "",
                key: selem.key,
                name:selem.name,
                currency:selem.currency,
                type:selem.type,
                jobs:selem.jobs.filter((elem,i)=> choose[eindex].jobs.indexOf(i) !== -1 ).map((jelem,jindex)=>(
                     {
                        id: jelem.id || "",
                        key: jelem.key,
                        name:jelem.name,
                        data:JSON.stringify( jelem.data[0] )
                    }
                ))
            }
        
        });  

        // Checking Merge
        let mergeOb:any = aind.map((element) => {

            let selec:any = allScenarios[element];
    
            let zeroth = CalcOperation.createNewCopyOfCalcFields({...selec.jobs[0],data:JSON.parse(selec.jobs[0].data)}.data);
            let name = selec.jobs[0].name;

            console.log("zeroth prev",zeroth);

            selec.jobs.forEach((inner:any,ind:number) => {
                
                if(ind === 0 ) return;

                let newInner = {...inner,data:JSON.parse(inner.data)};
                let d2 = CalcOperation.createNewCopyOfCalcFields(newInner.data);
                name += ` + ${newInner.name}`;

                zeroth.forEach((outerElem,outerInd)=>{
                    outerElem.child.forEach((innerElem,innerInd) => {

                        if(innerElem.type === "text" && innerElem.id === FieldKeys.project_description){
                            zeroth[outerInd].child[innerInd].value += ` + ${d2[outerInd].child[innerInd].value}`;
                        }

                        if(innerElem.type === "nestedNum"){

                            let val = (parseFloat(zeroth[outerInd].child[innerInd].value) || 0) +
                            (parseFloat(d2[outerInd].child[innerInd].value) || 0);

                            zeroth[outerInd].child[innerInd].value = val !== 0 ? val.toFixed(2) : "";

                            zeroth[outerInd].child[innerInd].fields = [
                                ...(zeroth[outerInd].child[innerInd].fields)!,
                                ...(d2[outerInd].child[innerInd].fields)!
                            ]
                        }

                        if(innerElem.type === "bool" && innerElem.value === "no" && zeroth[outerInd].child[innerInd].value === "no"){
                            zeroth[outerInd].child[innerInd - 1].entries = [
                                ...zeroth[outerInd].child[innerInd - 1].entries,
                                ...d2[outerInd].child[innerInd - 1].entries
                            ];
                        }

                        if( (innerElem.type === "num" || innerElem.type === "decisive") && 
                            !innerElem.percent &&
                            innerElem.id !== FieldKeys.beginning_fiscal &&
                            innerElem.id !== FieldKeys.months_remaining
                        ){

                            if(innerElem.id === FieldKeys.profit_loss){

                                let prev = zeroth[outerInd].child[innerInd].secDrop?.value;
                                let curr = d2[outerInd].child[innerInd].secDrop?.value;
                                let prevVal = 0;
                                let currVal = 0;

                                (prev === 12)
                                ?
                                prevVal = (parseFloat(zeroth[outerInd].child[innerInd].value) * -1)
                                :
                                prevVal = parseFloat(zeroth[outerInd].child[innerInd].value);
                                

                                (curr === 12)
                                ?
                                currVal = (parseFloat(d2[outerInd].child[innerInd].value) * -1)
                                :
                                currVal = parseFloat(d2[outerInd].child[innerInd].value);
                                

                                let val = parseFloat((prevVal + currVal).toFixed(2));

                                zeroth[outerInd].child[innerInd].value = val !== 0 ? Math.abs(val).toFixed(2) : "";
                                

                                if(val < 0){
                                    zeroth[outerInd].child[innerInd]!.secDrop!.value = 12;
                                }
                                else{
                                    zeroth[outerInd].child[innerInd]!.secDrop!.value = 11;
                                }
                            }
                            else{

                                let val = (parseFloat(zeroth[outerInd].child[innerInd].value) || 0) +
                                (parseFloat(d2[outerInd].child[innerInd].value) || 0);

                                zeroth[outerInd].child[innerInd].value = val !== 0 ? val.toFixed(2) : "";
                            }
                        }

                        if(innerElem.id === FieldKeys.months_remaining){
                            let prevVal = (parseFloat(zeroth[outerInd].child[innerInd].value) || 0);
                            let currVal = (parseFloat(d2[outerInd].child[innerInd].value) || 0);

                            zeroth[outerInd].child[innerInd].value = prevVal > currVal ? 
                            prevVal !== 0 ? prevVal.toFixed(2) : ""
                            :
                            currVal !== 0 ? currVal.toFixed(2) : "";
                        }

                        if(innerElem.type === "popup"){

                            zeroth[outerInd].child[innerInd].data?.forEach((element,locInd) => { 
                                if(!zeroth[outerInd].child[innerInd].data![locInd].percent){
                                    let val = (parseFloat(zeroth[outerInd].child[innerInd].data![locInd].value) || 0) +
                                    (parseFloat(d2[outerInd].child[innerInd].data![locInd].value) || 0);
                                    
                                    zeroth[outerInd].child[innerInd].data![locInd].value = val !== 0 ? val.toFixed(2) : "";
                               
                                }
                            });

                        }

                        if(innerElem.type === "oneScreenPopup"){

                            zeroth[outerInd].child[innerInd].dataArr?.forEach((locElem,locInd) => { 

                                locElem?.data?.forEach((locInnerElem,locInnerInd) => {

                                    if(!zeroth[outerInd].child[innerInd].dataArr![locInd].data![locInnerInd].perc){
                                        let val = (parseFloat(zeroth[outerInd].child[innerInd].dataArr![locInd].data![locInnerInd].value) || 0) +
                                        (parseFloat(d2[outerInd].child[innerInd].dataArr![locInd].data![locInnerInd].value) || 0);

                                        zeroth[outerInd].child[innerInd].dataArr![locInd].data![locInnerInd].value =  val !== 0 ? val.toFixed(2) : "";
                                    }
                             
                                });

                            });

                        }

                        if(innerElem.type === "DoublePopup"){

                            let val = (parseFloat(zeroth[outerInd].child[innerInd].value) || 0) +
                            (parseFloat(d2[outerInd].child[innerInd].value) || 0);

                            zeroth[outerInd].child[innerInd].value = val !== 0 ? val.toFixed(2) : "";

                            zeroth[outerInd].child[innerInd].entries = [
                                ...(zeroth[outerInd].child[innerInd].entries)!,
                                ...(d2[outerInd].child[innerInd].entries)!
                            ]

                        }

                    });
                })

            });

            console.log("zeroth after", zeroth);

            return {
                scenario:element,
                newJob:{
                    key:PasswordGenerator.getUniqid(),
                    name:name,
                    data:[ zeroth ],
                    activeIndex : 0,
                    type:0
                }
            }

            
        });

        let selecSceIndcopy = [...selectedScenariosIndex];

        if(mergeOb.length > 0){

            dispatch( insertScenarioWiseJob(mergeOb) );

            allScenarios.forEach((element,ind) => {

                if(aind.indexOf(ind) !== -1){

                    allScenarios[ind].jobs = mergeOb.filter((elem:any,i:number)=> elem?.scenario === ind ).map((jelem:any,jindex:number)=>(
                        {
                        id: jelem?.newJob?.id || "",
                        key: jelem?.newJob?.key,
                        name:jelem?.newJob?.name,
                        data:JSON.stringify( jelem?.newJob.data[0] )
                    }
                    ));

                    // console.log("tabs",tabs.scenario[ind].jobs.length);
                    // console.log(selecSceIndcopy);

                    selecSceIndcopy.forEach((element,cind) => {
                        if(element.ind === ind){
                            selecSceIndcopy[cind].jobs = [tabs.scenario[ind].jobs.length]
                        }
                    });

                }
                
            });

            setSelectedScenariosIndex(selecSceIndcopy);
            
        }

        
    console.log("final payload", allScenarios);
    // console.log("Selected Scenario Index", selectedScenariosIndex);

        setSubmitableScenario(allScenarios);

        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
        let sendOb:Sender = {
            tabId : calc[data.activeTab].id || "",
            tabName : calc[data.activeTab].tabName,
            scenarios : allScenarios,
            version : data.tabs[data.activeTab].version || "v2",
            timeZone: tz,
            payload : val,
            compareJobs : (activeSetTab === 1 && selectedScenariosIndex.length !== 0) ? true : false
        }
    
        // setLoading(false);
        // return;

        Axios.post(`/api/v1/calculator`,{
            ...sendOb
        },{
            headers:{
                Authorization: user?.token
            }
        })
        .then((result:any)=>{

            console.log("result from api", result);

            setLoading(false);
            let ob = {...result.data.data};

            // setting Id to active tab and all its scenarios and jobs.
            dispatch( setIds({ids:selectedScenariosIndex.map(elem=> elem.ind),received:ob.received,tabId:ob.tabId}) )
            let x:resultType = [...ob.output];
            
            if(selectedScenariosIndex.length !== 0 && activeSetTab === 1){

                let loc = calc[data.activeTab].scenario[selectedScenariosIndex[0].ind].jobs;

                let prep2:resultType = loc.map((element:any,ind:number) => {

                    let naPrep:any[] = Object.keys(x[0].output.nonAmmortLoans);
                    let obprep:any = {};

                    naPrep.forEach((element) => {
                        obprep[element] = [x[0].output.nonAmmortLoans[element][ind]]
                    });

                    return{
                        name: element.name,
                        output:{
                            cashflowTable: [x[0].output.cashflowTable[ind]],
                            loans:[x[0].output.loans[ind]],
                            nonAmmortLoans:obprep,
                            result: x[0].output.result.map((el,indd)=>({
                                ...el,
                                data:el.data.map((iel,ieind)=>(
                                    {
                                        ...iel,
                                        value: x[0].output.result[indd].data[ieind].value[ind]
                                    }
                                ))
                            }))
                        }
                    }
                });

                let xx:Payload=prep2.map((elem:any,ind:number)=> ({
                    name:elem.name,
                    data:{
                        abcAnnualHours:elem?.output?.result[3].data[2].value.toFixed(2),
                        abcAnnualHoursNc:elem?.output?.result[3].data[3].value.toFixed(2)
                    }
                }));

                setPayload(xx);
                setResult([...prep2]);

            }
            else{

                // re arranging quoted and current scenarios at starting of array.
                let prep:any = [];
                let quoted=null;
                let current=null;

                x.forEach(element => {
                    if(element.name.toLowerCase() === "quoted"){
                        quoted = element;
                    }
                    else if(element.name.toLowerCase() === "current"){
                        current = element;
                    }
                    else{
                        prep = [...prep,element];
                    }
                });

                if(current !== null){
                    prep.unshift(current);
                }
                if(quoted !== null){
                    prep.unshift(quoted);
                }


                  let xx:Payload=prep.map((elem:any,ind:number)=> ({
                    name:elem.name,
                    data:{
                        abcAnnualHours:elem?.output?.result[3].data[2].value.toFixed(2),
                        abcAnnualHoursNc:elem?.output?.result[3].data[3].value.toFixed(2)
                    }
                }));
                  setPayload(xx);
                  setResult([...prep]);       
            }

          
        })
        .catch(err=>{
            setLoading(false);
            console.log("err", err);
        })
    }

    const verifyForm = useCallback(()=>{

        dispatch( verifyFormPage(activeIndex + 1) ); 
      
    },[activeIndex]);

    const calculate = ()=>{

        let err = dispatch( verifyFormPage(calcList[0].length) );
        let res:any = dispatch( verifyAllJobs(selectedScenariosIndex.length > 1 ? selectedScenariosIndex : changableScenariosIndex) ).payload;
        
        if(!res.status){

            setError(true);
        }
        else{
            submitData();
        }
    }

    const selectedScenarioChangeHandler = (checked:boolean,ind:number)=>{ 

        let locSelectedScenario = [...selectedScenariosIndex];
        if(checked){
            let x ={
                ind : ind,
                jobs:[]
            }
            let res = verifySelectedScenarios([x]);
          
            if(!res.status){
    
                return setCustomError(`
                Scenario '${res.scName}' Job '${res.jbName}' entries are incomplete. 
                Please complete all the required form entries before proceeding !
                `);
            }
            locSelectedScenario.push(x);
        }
        else{

            locSelectedScenario = locSelectedScenario.filter(el=> el.ind !== ind );
        }
 
        setSelectedScenariosIndex(locSelectedScenario);
        
    }

    const jobChangeHandler = (index:number,e:string[])=>{

        let parsed = e.map(elem=> parseInt(elem));

        let x = selectedScenariosIndex.map(el=> ({...el,jobs:[...el.jobs]}) );

        x.forEach(element => {

            if(element.ind === index){
                element.jobs = parsed;
            }
            
        });

        let res = verifySelectedScenarios(x);

        if(!res.status){
    
            return setCustomError(`
            (${res.jbName}) 
            Please complete all the required form entries before proceeding !
            `);
        }
        else{
            setSelectedScenariosIndex(x);
        }
        

        

    }

    const selectedScenarioSaveHandler = ()=>{

        if(selectedScenariosIndex.length < 1){
            setCustomError(`
            All of the Scenarios can't be unselected!`);


            return;
        }
        
        let proceed = true;
        selectedScenariosIndex.forEach((element) => {
            if(element.jobs.length < 1){
                proceed = false;
            }
        });

        if(!proceed){

            setCustomError(`
            All of the Jobs can't be unselected !
            `);

            return;
        }
        else{

            let res = verifySelectedScenarios( selectedScenariosIndex);
            if(!res.status){

                setCustomError(`
                One or more scenario entries are incomplete. 
                Please complete all the required form entries before proceeding !
                `);
                return;
            }
            else{
                setSettingOpen(false);
            }

        }
    
  
    }

    const verifySelectedScenarios = (e:{ind:number,jobs:number[]}[])=>{

        const sce:scenarioType[] = e.map((elem,index)=> ({...allScenarios[elem.ind],jobs: allScenarios[elem.ind].jobs.filter((el,ind)=> elem.jobs.indexOf(ind) !== -1 )  }));

        let proceed = true;
        let scName = "";
        let jbName = "";

        sce.forEach(element => {
            
            element.jobs.forEach(elem => {

                elem.data[0].forEach(ielem => {

                    if(!ielem.cmp){
                        proceed = false;
                        scName = element.name;
                        jbName = elem.name;
                    }
                    
                });
                
            });

        });

        if(!proceed){

            return {scName,jbName,status:false};
        }
        else{
            return {scName,jbName,status:true};
        }
       
    }

    const settingCloseHandler = ()=>{
        setSelectedScenariosIndex([]);
        setSettingOpen(false);
        setActiveSetTab(0);

    }


    const currencyChangeHandler = (e:any)=>{

        let pre = dispatch(getData(""));
        let data = JSON.parse(pre.payload);
        let calc:tabType = data.tabs;        

        type Sender={
            tabIndex:string,
            scenarioIndex:string,
            jobs:string,
            from:string,
            to: string
        }

        let prep:Sender = {
            tabIndex : data.activeTab,
            scenarioIndex: data.activeScenario,
            jobs: JSON.stringify(data.activeScenarioJobs),
            from: myCurrency,
            to: e

        }

        setCurrencyLoading(true);

        Axios.post(`/api/v1/currency-converter`,{
            ...prep
        },{
            headers:{
                Authorization: user?.token
            }
        })
        .then((result)=>{

            let data = result.data.data;

            dispatch( replaceJobs({tabIndex:data.tabIndex,sceIndex:data.scenarioIndex,jbs:data.newData,currency:e}) )
            console.log("curr res", result);

            setCurrencyLoading(false);

        })
        .catch((err)=>{

            // dispatch( setCurrency(e) );

            setCurrencyLoading(false);
            console.log("curr err", err);

        })

        

    }

    const triggerMobileIndex = ()=>{
        setOpenMobileIndex(prev=> !prev);
    }

    useEffect(()=>{
        
        let merge = [...mergeIntoNew];
        let prep:number[] = [];
        let newmerge:number[] = [];

        selectedScenariosIndex.forEach((elem)=>{
            prep.push(elem?.ind);

            if(elem.jobs.length <= 1 && merge.indexOf(elem?.ind) !== -1){
                merge.splice(merge.indexOf(elem?.ind),1);
            }
        })

        merge.forEach((elem)=>{
            if(prep.indexOf(elem) !== -1){
                newmerge.push(elem);
            }
        })

        setMergeIntoNew(newmerge);

    },[selectedScenariosIndex]);

    useEffect(()=>{
        console.log("mergeintonew", mergeIntoNew);
    },[mergeIntoNew])

    return (
        <div className={className}>
            {
                currencyLoading &&
                <FSLoader>
                     <p className={"loaderTxt"}>Converting Currency...</p>
                </FSLoader>
            }
            {
                error &&
                <ModalBox 
                open={error}
                header="Incomplete Details" 
                message="You have incomplete fields in one or more Jobs which are crucial for calculation !"
                onOk={()=>{
                    setError(false)
                }}
                onClose={()=>{
                    setError(false);
                }}
                />
            }
            {
                confirmRestart &&
                <ModalBox 
                open={confirmRestart}
                header="Confirm !" 
                message="Are you sure you want to clear all form entries !"
                onOk={()=>{
                    restartForm();
                    setConfirmRestart(false);
                }}
                onClose={()=>{
                    setConfirmRestart(false);
                }}
                onCancel={()=>{
                    setConfirmRestart(false);
                }}
                />
            }
             {
                customError &&
                <ModalBox 
                open={customError ? true : false}
                header="Error !" 
                message={customError}
                onOk={()=>{
                    setCustomError("");
                }}
                onClose={()=>{
                    setCustomError("");
                }}
                />
            }
            {
                settingOpen &&
                <Modal
                sx={{
                    zIndex:100
                }}
                className="modal"
                open={true}
               >
       
               <div className="modalPopUp">
       
                       <CloseOutlined
                       className="popupClose" 
                       onClick={settingCloseHandler}
                       />
                      
                           <div className={Style.setCnt}>

                            <div className={Style.tabCnt}>
                                <div 
                                className={`${Style.tab} ${activeSetTab === 0 && Style.tabActive}`} 
                                onClick={()=> setActiveSetTab(0)}>
                                    Compare Scenarios
                                </div>
                                <div 
                                className={`${Style.tab} ${activeSetTab === 1 && Style.tabActive}`} 
                                onClick={()=> setActiveSetTab(1)}>
                                    Compare Jobs
                                </div>
                            </div>

                            {
                                activeSetTab === 0 &&
                                allScenarios.map((elem,index)=>(
                                <div className={Style.setScenarioCnt} key={`setSceInd${index}`}>
                                    <div className={Style.setScenarioLeft}>
                                        <Checkbox 
                                        className={Style.setChkBox}
                                        id={`setLabel${index}`}
                                        checked={selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? true : false}
                                        onChange={(e)=> selectedScenarioChangeHandler(e.target.checked,index) }
                                        />
                                        <label htmlFor={`setLabel${index}`}>{elem.name}</label>
                                    </div>
                                    <div className={Style.setScenarioRight}>

                                        <Select
                                        rootClassName={Style.setJobSelectCnt}
                                        disabled={selectedScenariosIndex.filter(el=> el.ind === index).length === 0 ? true : false}
                                        mode="multiple"
                                        value={ selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? 
                                        selectedScenariosIndex.filter(el=> el.ind === index)[0].jobs.map(el=> `${el}`) : 
                                        []
                                        }
                                        maxTagCount={1}
                                        placeholder="Include Jobs"
                                        options={elem.jobs.map((el,ind)=> ({label:el.name,value:`${ind}`}) ) } 
                                        onChange={(e)=> jobChangeHandler(index,e) }
                                        />

                                        {
                                        //     (
                                        //     selectedScenariosIndex.filter(el=> el.ind === index).length > 0 &&
                                        //     selectedScenariosIndex.filter(el=> el.ind === index)[0].jobs?.length > 1 ) &&
                                        //     <div className={Style.setScenarioMergeCnt} >
                                        //     <Checkbox 
                                        //     className={Style.setChkBox}
                                        //     style={{margin:"0 0 0 0"}}
                                        //     id={`merge${index}`}
                                        //     checked={mergeIntoNew.indexOf(index) !== -1 ? true : false}
                                        //     onChange={(e)=>{

                                        //         let currInd = selectedScenariosIndex.filter(el=> el.ind === index)[0];
                                        //         if(e.target.checked){
                                        //             if(currInd && mergeIntoNew.indexOf(currInd?.ind) === -1){
                                        //                 setMergeIntoNew(prev=> [...prev,currInd?.ind]);
                                        //             }
                                        //         }
                                        //         else{
                                        //             if(currInd && mergeIntoNew.indexOf(currInd?.ind) !== -1){
                                        //                 setMergeIntoNew(prev=> prev.filter(el=> el !== currInd?.ind ));
                                        //             }
                                        //         }
                                        //     }}
                                        //     />
                                        //     <label htmlFor={`merge${index}`}>
                                        //     Merge into new tab
                                        //     </label>
                                        // </div>
                                        }
                                    </div>
                                </div>
                                ))
                            }
                            {
                                activeSetTab === 1 &&
                                allScenarios.map((elem,index)=>(
                                    <div className={Style.setScenarioCnt} key={`setSceInd${index}`}>
                                        <div className={Style.setScenarioLeft}>
                                            <Checkbox 
                                            disabled={ ((selectedScenariosIndex.length !== 0) && (selectedScenariosIndex[0].ind !== index)) ? true : false }
                                            className={Style.setChkBox}
                                            id={`setLabel${index}`}
                                            checked={selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? true : false}
                                            onChange={(e)=> selectedScenarioChangeHandler(e.target.checked,index) }
                                            />
                                            <label htmlFor={`setLabel${index}`}>{elem.name}</label>
                                        </div>
                                        <div className={Style.setScenarioRight}>
                                            <Select
                                            rootClassName={Style.setJobSelectCnt}
                                            disabled={selectedScenariosIndex.filter(el=> el.ind === index).length === 0 ? true : false}
                                            mode="multiple"
                                            value={ selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? 
                                            selectedScenariosIndex.filter(el=> el.ind === index)[0].jobs.map(el=> `${el}`) : 
                                            []
                                            }
                                            maxTagCount={1}
                                            placeholder="Include Jobs"
                                            options={elem.jobs.map((el,ind)=> ({label:el.name,value:`${ind}`}) ) } 
                                            onChange={(e)=> jobChangeHandler(index,e) }
                                            />
                                        </div>
                                    </div>
                                    ))
                            }
                           
                           </div>

                           <div className="mpuBot">
       
                           <Button className="mybtn-blue" type="text" onClick={selectedScenarioSaveHandler}>
                               Save Settings <RightOutlined />
                           </Button>
       
                           </div>
       
                       </div>        
               </Modal>
            }
            {
                result.length > 0 &&
                <>
                {
                    parseInt(version?.replaceAll("v","") || "0") >= 9 ?
                    <OutputScreenbyVersion 
                    result={result}
                    compareJobs={(selectedScenariosIndex.length !== 0 && activeSetTab === 1) ? true : false}
                    setResult={setResult}
                    myCurrency={myCurrency}
                    submitableScenario={submitableScenario}
                    payload={payload}
                    setPayload={setPayload}
                    loading={loading}
                    reCalc={reCalc}
                    />
                    :
                    <OutputScreen 
                    result={result}
                    setResult={setResult}
                    myCurrency={myCurrency}
                    submitableScenario={submitableScenario}
                    payload={payload}
                    setPayload={setPayload}
                    loading={loading}
                    reCalc={reCalc}
                    />
                }
                  
                </>
          

            }
            {
                openMobileIndex &&
                <div className={Style.mobileIndexCnt}>

                    <TimelineContainer 
                    calcList={calcList![0]}
                    myFunc={()=> setOpenMobileIndex(false)}
                    />
                    <div style={{marginRight:"15px",display:"flex",alignItems:"center"}}>
                        <div className={Style.backBtn} onClick={triggerMobileIndex}>
                            <KeyboardDoubleArrowLeftIcon />
                            <p>Back</p>
                        </div>
                    </div>

                </div>
            }
            {
                result.length < 1 &&
                <>
                <div className={Style.container}>
                    
                    <div className={Style.left}>
                        <div className={Style.leftInner}>
                            <h1>Quantum Leap<sup>TM</sup></h1>
                            <img src="/images/trio.png" alt="background logo" />
                            <h4>Sales & Product Information Input Form</h4>
                            <p className={Style.indexMob} style={{margin:"20px 0 0 0"}}>
                                Step <span style={{color:"#24336D"}}>{activeIndex+1}</span> of <span style={{color:"#24336D"}}>{calcList?.length}</span>
                            </p>

                        <div className={Style.timelineCnt} style={{margin:"40px 0 0 0"}}>

                                <TimelineContainer 
                                calcList={calcList![0]}
                                />
                        </div>
                        
                        </div>
                    </div>

                    <div className={Style.mobileLeft}>

                        {/* <MobileTimeline 
                        calcList={calcList![0]}
                        /> */}
                        <div className={Style.indexBtn} onClick={triggerMobileIndex}>
                            <p>index</p>
                            <KeyboardDoubleArrowRightIcon />
                        </div>

                    </div>

                    <div className={Style.right}>

                    <div className={Style.rightTopCnt}>
                        
                        <div className={Style.vholder}>
                            <p>Latest Version : <b>{VERSION}</b></p>
                            <p>Active Version : <b>{version}</b></p>
                        </div>
                        <div className={Style.rightTopUpper}>
                            <div className={Style.rightTop}>
                            <Button type="text" className={Style.rtBtn} onClick={()=> setConfirmRestart(true)}>
                                <AutorenewIcon fontSize="inherit" />
                                <p>Restart Form</p>
                            </Button>
                            <Select
                                options={currencies}
                                className={`${Style.currencyDrop}`}
                                onChange={currencyChangeHandler} 
                                value={myCurrency}
                                defaultValue={myCurrency} 
                                size="large"
                                bordered={false}
                                dropdownStyle={{fontSize:"12px"}}
                                />
                            </div>
                            
                            <Clock />
                        
                        </div>
                    </div>
                        
                        <div className={Style.innerRight}>

                        <div className={Style.calcCnt}>

                            <div className={Style.calcHeader}>
                                <div>
                                    <div className={Style.bullet} />
                                    <p>{calcList![0][activeIndex].name}</p>

                                </div>
                            </div>

                            <div className={Style.calcMain} style={{paddingBottom:"80px"}}>
                                <div className={Style.calcContent}>
                                    
                                    {

                                    localData.map((outerElem,outerIndex)=>(
                                        
                                        <div className={Style.calcDivisionCnt} key={`ou${activeTab}${activeScenarioIndex}${activeJob}${outerIndex}${activeIndex}`}>
                                            {
                                                (splitData && splitData.length >= 2 && localData.length > 1) &&
                                                <div className={Style.dataHeaderCnt}>
                                                    <p className={Style.dataHeader}>
                                                    {
                                                        activeTabJobs[parseInt(splitData[outerIndex])].name
                                                    }
                                                    </p>
                                                    <NavigationIcon
                                                    sx={{
                                                        transform:"rotate(180deg)",
                                                        height:"17px",
                                                        color:"lightgray"
                                                    }}
                                                    />
                                                </div>
                                            }
                                            {
                                                outerElem.map((elem,index)=>(
                                                    <div key={`ouin${activeTab}${activeScenarioIndex}${activeJob}${outerIndex}${activeIndex}${index}`}>
                                                {
                                                    (elem.type === "DoublePopup") &&
                                                    <DoublePopup
                                                    calcList={calcList}
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "popup" &&
                                                    <Popup
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "nestedNum" &&
                                                    <Number
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
            
                                                {
                                                    elem.type === "text" &&
                                                    <Text
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "num" &&
                                                    <Number
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "bool" &&
                                                    <BoolDrop
                                                    calcList={calcList}
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "decisive" &&
                                                    <Decisive
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                 {
                                                    elem.type === "oneScreenPopup" &&
                                                    <OneScreenPopup
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                
                                    }        

                                </div>

                                <div className={Style.calcBtnCnt}>
                                    <Button 
                                    type="text" 
                                    className={Style.calcBtn} 
                                    disabled={activeIndex === 0 ? true : false}
                                    onClick={()=> dispatch( verifyFormPage(activeIndex - 1) ) }
                                    >
                                        {activeIndex > 0 && <LeftOutlined style={{fontSize:"10px"}} /> } Back
                                    </Button>
                                    
                                    <Button 
                                    loading={loading}
                                    type="text" 
                                    className={Style.calcBtn} 
                                    onClick={()=> verifyForm() }
                                    disabled={activeIndex === calcList![0].length-1 ? true : false}
                                    >
                                    Next {activeIndex < calcList![0].length-1 && <RightOutlined style={{fontSize:"10px"}} /> }
                                    </Button>
                                        
                                    
                                </div>

                            </div>

                        </div>

                        </div>

                    </div>

                </div>

                <div className="calculateBtnCnt">
                    <Button loading={loading} className={`calculateBtn ${selectedScenariosIndex.length > 0 && "calculateBtnActive"}`} type="text" onClick={calculate}>calculate</Button>
                    <SettingsIcon 
                    className={`calculateSettingBtn ${selectedScenariosIndex.length > 0 && "calculateSettingBtnActive"}`}
                    onClick={()=> setSettingOpen(true)}
                    />
                </div>
                
                </>
            }
        </div>
    )

}

export default memo(Calculator);