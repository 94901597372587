// React
import { useEffect, useState } from 'react';

// React Router
import { Link } from 'react-router-dom';

// Redux
import { useDispatch } from 'react-redux';

// Ant Design
import { Alert } from 'antd';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox/Checkbox';

// Third Party

// Local imports
import { Axios } from '../Axios';
import Flynaut from '../components/branding/Flynaut';
import InputBox from '../components/input';
import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';
import { resetCalc } from '../redux/calculatorSlice';
import { login } from '../redux/userSlice';
import Style from '../styles/login.module.css';
import email_prefix from '../svg/email-prefix.svg';
import pass_prefix from '../svg/pass-prefix.svg';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';

interface LoginFormData {
  email: string;
  password: string;
  rememberMe: boolean;
  otp: string;
}

const Login: React.FC = () => {
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
    rememberMe: true,
    otp: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const dispatch = useDispatch();

  // Clearing Otp when  verify modal is closed.
  useEffect(()=>{

    if(!showOtpModal){
      setFormData(prev=>({
        ...prev,
        otp:""

      }))
    }

  },[showOtpModal]);

  const handleInputChange = (field: keyof LoginFormData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData(prev => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleLogin = async (): Promise<void> => {
    setLoading(true);
    setError("");

    try {
      const result = await Axios.post('/api/v1/login', {
        email: formData.email,
        password: formData.password,
        otp: formData.otp
      });

      const user = {
        ...result.data.data.user,
        token: result.data.data.token,
      };

      if(result?.data?.type === 1){
        setShowOtpModal(true);
      }
      else{

        dispatch(resetCalc());
        dispatch(login(user));
  
        if (formData.rememberMe) {
          localStorage.setItem('ccouser', JSON.stringify(user));
        } else {
          sessionStorage.setItem('ccouser', JSON.stringify(user));
        }
      }

      
    } catch (err: any) {
      if (err.response) {
        if(err.response.data.type === 3){

          toast.error("Invalid OTP");
        }
        else{

          setError(err.response.data.message || err.response.data.error);
        }
      }
      else{
        setError("Network Error");
      }
    } finally {
      setLoading(false);
    }
  };

  const containerStyle: React.CSSProperties = {
    margin: '20px 0 0 0',
  };

  const checkboxStyle: React.CSSProperties = {
    color: '#656A72',
    fontSize: '12px',
    alignItems: 'center',
  };

  const linkStyle: React.CSSProperties = {
    textDecoration: 'none',
  };

  const linkTextStyle: React.CSSProperties = {
    color: '#2B80FF',
    fontSize: '12px',
  };

  const alertStyle: React.CSSProperties = {
    minHeight: '25px',
    margin: '5px 0 20px 0',
    fontSize: '12px',
  };

  const checkboxContainerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
  };

  const footerTextStyle: React.CSSProperties = {
    color: '#656A72',
    fontSize: '12px',
    margin: '30px 0 0 0',
  };

  const rightPanelStyle: React.CSSProperties = {
    background: 'url("/images/login_bk.png")',
    backgroundPosition: 'right center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  };

  const modalStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  };

  const otpContainerStyle: React.CSSProperties = {
    margin: '0 auto 20px auto',
    display: 'flex',
    justifyContent: 'space-between',
  };

  return (
    <NotAuthenticatedLayout>
      <div id="main">
        <div className={Style.container}>
          <div className={Style.left}>
            <div className={Style.form}>
              <div className={Style.logoCnt}>
                <Flynaut />
              </div>
              <h1 className={Style.title}>Sign in</h1>
              <h4 className={Style.subtitle}>Sign in with your email</h4>

              <div style={containerStyle}>
                <InputBox
                  type="text"
                  image={<img src={email_prefix} alt="email" />}
                  placeholder="Your email"
                  value={formData.email}
                  onChange={handleInputChange('email')}
                />
                <InputBox
                  type="password"
                  image={<img src={pass_prefix} alt="password" />}
                  placeholder="Your password"
                  value={formData.password}
                  onChange={handleInputChange('password')}
                />
              </div>

              {error && (
                <Alert
                  type="error"
                  message={error}
                  showIcon
                  style={alertStyle}
                />
              )}

              <div style={checkboxContainerStyle}>
                <Checkbox
                  checked={formData.rememberMe}
                  onChange={() => setFormData(prev => ({ ...prev, rememberMe: !prev.rememberMe }))}
                  style={checkboxStyle}
                >
                  Remember me
                </Checkbox>
                <Link to="/forget-password" style={linkStyle}>
                  <p style={linkTextStyle}>Forget password?</p>
                </Link>
              </div>

              <Button
                className="btn"
                type="text"
                loading={loading}
                onClick={handleLogin}
              >
                Login
              </Button>

              <p style={footerTextStyle}>
                Don't have an account? <span style={linkTextStyle}>Contact Us</span>
              </p>
            </div>
          </div>

          <div className={Style.right} style={rightPanelStyle} />
        </div>
      </div>

      <Modal
        open={showOtpModal}
        onClose={() => setShowOtpModal(false)}
      >
        <div style={modalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">Enter OTP</Typography>
          <IconButton 
            style={{ 
              position: 'absolute',
              right: '10px',
              top: '10px'
            }}
            onClick={() => setShowOtpModal(false)}
          >
            <CloseOutlined />
          </IconButton>

          </Box>
          <Typography variant="body2" color="textSecondary">
            Please enter the OTP sent to your email
          </Typography>
          
          <OTPInput
            value={formData.otp}
            onChange={(value) => setFormData(prev => ({ ...prev, otp: value }))}
            inputType='number'
            numInputs={4}
            containerStyle={otpContainerStyle}
            inputStyle={"otpInputStyle w-20"}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            />

          <Button
            className="btn"
            type="text"
            loading={loading}
            onClick={handleLogin}
            style={{ marginTop: '10px' }}
          >
            Verify OTP
          </Button>
        </div>
      </Modal>

    </NotAuthenticatedLayout>
  );
};

export default Login;