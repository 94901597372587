import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";
import { useEffect, useRef } from "react";
import Style from "../../styles/output.module.css";
import Flynaut from "../branding/Flynaut";
import DynamicNavbar from "../navbar/DynamicNavbar";


type propType ={
    onBackPress:()=> void,
    children: any,
    name:string[],
    leftButtons?:any[]
}

const WrapperOutput = ({onBackPress,children,name,leftButtons}:propType)=>{

    const stickyCnt = useRef<any>();

    useEffect(()=>{

        window.addEventListener("scroll",scrollFunc);
        scrollFunc();

        return ()=>{
            window.removeEventListener("scroll",scrollFunc);
        }

    },[])


    const scrollFunc = ()=>{
        if(!stickyCnt.current) return;

        let scroll = getscroll();

            if(scroll > 35){
                stickyCnt.current.style.position="fixed";
                stickyCnt.current.style.top="0px";
                stickyCnt.current.style.left="0px";
                stickyCnt.current.style.width="100%";
                stickyCnt.current.style.justifyContent="flex-start";
            }
            else{
                stickyCnt.current.style.position="relative";
                stickyCnt.current.style.justifyContent="center";
                stickyCnt.current.style.width="auto";
            }

    }

    function getscroll(){
		var b=document.body;
        var d=document.documentElement;
        d=(d.clientHeight)? d : b;
        return d.scrollTop;
	  }

    return(
        <>
        
        <div className={Style.container}>

            <div className={`${Style.left}`} ref={stickyCnt}>
                <div className={Style.leftInner}>
                    <div>
                        <IconButton 
                        className={`${Style.backBtn} non-printable`}
                        onClick={onBackPress}
                        >
                            <ArrowBackIcon htmlColor="white" />
                        </IconButton>
                        <h1 className="non-printable">
                            {
                                name.map((el,ind)=>(
                                    <>
                                    {
                                        (ind < name.length - 1) ?
                                        <span className={Style.mDisabled}>{el}{" >"} </span>
                                        :
                                        <span className={Style.mEnabled}>{el}</span>
                                    }
                                    </>
                                ))
                            }
                        </h1>
                    </div>
                    {/* <img className="outputlogo" src="/images/cco.png" alt="cco_logo" /> */}
                    <Flynaut className={`printable ${Style.leftInnerImg}`} />
                    
                </div>
            </div>
            <div className={`${Style.right} non-printable`} style={{width:"100%"}}>
{/* 
                <div className={Style.rightTopCnt} style={{width:"100%"}}>

                    <div className={`barScrollCnt`} style={{width:"auto"}}>
                        {
                            leftButtons?.map((elem,ind)=> <React.Fragment key={ind}>{elem}</React.Fragment> )
                        }
                    </div>
                </div> */}
                <DynamicNavbar leftButtons={leftButtons} />
            </div>
        </div>
        {children}
        </>
    )
}

export default WrapperOutput;