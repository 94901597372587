import styles from './flynaut.module.css';

interface FlynautProps {
    className?: string;
}

const Flynaut = ({ className }: FlynautProps): JSX.Element => {
    return (
        <div className={`${styles.mainCnt} ${className}`}>
            <a
                href="https://www.ccoconsulting.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <img
                    className="logo"
                    src="/images/cco.png"
                    alt="cco_logo"
                />
            </a>
            <div className={styles.divider} />
            <p className={styles.devTxt}>Developed By</p>
            <a
                href="https://flynaut.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    height: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                    alignItems: 'flex-end',
                    justifyContent: 'center'
                }}
            >
                <img
                    className="logo"
                    src="/images/flynaut.png"
                    alt="flynaut_logo"
                />
                <p className={styles.partnerTxt}>
                    A <span>Cornerstone</span> Partner Company
                </p>
            </a>
        </div>
    );
};

export default Flynaut;