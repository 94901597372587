import { Button } from "antd";
import Style from "./output.module.css";
import { filteredResulttype, scenarioType, submitScenarioType } from "../../../@types/user";
import React, { useEffect, useState } from "react";
import NumberFormat from "../../../utils/NumberFormat";
import GraphScreen from "../GraphScreen";
import WrapperOutput from "../WrapperOutput";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import ScrollerWrapper from "../../scrollerWrapper";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import PrintBtn from "../../PrintBtn";
import CurrencyIconRenderer from "../../CurrencyIconRendered";
import DeltaTableScreen from "./DeltaTableScreen";


type comparedScreenPropType={
    result:filteredResulttype[],
    myCurrency:string,
    setCompareEnabled:(res:boolean)=> void,
    mapper:{name:string,data:string[]}[],
    submitableScenario:submitScenarioType[]
}


const ComparedScreen = ({result,myCurrency,setCompareEnabled,mapper,submitableScenario}:comparedScreenPropType)=>{

    const allScenario = useSelector((state: RootState) => state.calculator.tabs[state.calculator.activeTab].scenario);
    const [myresult,setMyresult] = useState<filteredResulttype[]>([]);
    const [forwardResult,setForwardResult] = useState<filteredResulttype[]>([]);

    const [prep,setPrep] = useState<{
        name:string,
        data:{
            name:string,
            value:string[]
        }[],
        servicableYear:number,
        yearsRemaining:number
    }[][]>([]);

    const [showGraph,setShowGraph] = useState(false);
    const [showTable,setShowTable] = useState(false);
    const [deltaTable,setDeltaTable] = useState(false);


    useEffect(()=>{

        window.scrollTo(0,0);
        if(myresult.length < 1)
        setMyresult([...result]);

    },[])

    useEffect(()=>{
        if(myresult.length < 1) return;


        let x:filteredResulttype[]= [[]];
        myresult.forEach(element => {
            x[0].push(element[element.length -1]);
        });

        setForwardResult(x);
        // console.log("x",x);

    },[myresult])

    useEffect(()=>{

        if(forwardResult.length < 1) return;


        let localPrep : {
            name:string,
            data:{
                name:string,
                value:string[]
            }[],
            servicableYear:number,
            yearsRemaining:number
        }[][] = [];

        forwardResult.forEach((elem,index) => {

            let x:{
                name:string,
                data:{name:string,value:string[]}[],
                servicableYear:number,
                yearsRemaining:number
            }[] = [];

            elem.forEach((element,ind) => {
    
                let inflation = (element.cashFlow.map(elem=> elem.inflation).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let tax_rate = (element.cashFlow.map(elem=> elem.tax).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let wacc = (element.cashFlow.map(elem=> elem.wacc).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let volume = (element.cashFlow.map(elem=> elem.volume).reduce((a,b)=> a+b) || 0) / element.cashFlow.length;
                let month = (element.cashFlow.map(elem=> elem.tables.ds.length).reduce((a,b)=> a>b ? a : b));
    
                let Volume:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b) ? "0" : (volume ? volume.toString() : "0") );
                let Inflation:string[] = Array.from({length:month}).map((elem,ind)=> ind === 0 || ind > element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b) ? "0" : (inflation ? inflation.toString() : "0") );
                let Lta:string[] = Array.from({length:month}).map((elem,ind)=> ("0") );
                let Tax:string[] = Array.from({length:month}).map((elem,ind)=> (ind > element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b) ? "0" : (tax_rate ? tax_rate.toString() : "0") ) );
                let Wacc:string[] = Array.from({length:month}).map((elem,ind)=> (ind > element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b) ? "0" : (wacc ? wacc.toString() : "0" ) ) );
    
                x = [
                    ...x,
                    {
                        name:element.name,
                        data:[
                            {
                                name:'Volume',
                                value:Volume
                            },
                            {
                                name:'Inflation',
                                value:Inflation
                            },
                            {
                                name:'Long term agreement',
                                value:Lta
                            },
                            {
                                name:'Tax Rate',
                                value:Tax
                            },
                            {
                                name:'WACC',
                                value:Wacc
                            }
                        ],
                        servicableYear: element.cashFlow.map(elem=> elem.greatestTimeFrame).reduce((a,b)=> a>b ? a : b),
                        yearsRemaining: element.cashFlow.map(elem=> elem.years_remaining).reduce((a,b)=> a>b ? a : b)
                    }
                ]
                
            });
        
            localPrep.push(x);
        });

        setPrep(localPrep);


    },[forwardResult])


    useEffect(()=>{

        console.log("Compared prep",prep);

    },[prep])

    return(
        <div className="outputScreen">
        {
            (!showGraph && !showTable) &&
            <WrapperOutput
            name={["Home","Output","Delta"]}
            onBackPress={()=> setCompareEnabled(false)}
            leftButtons={[
                <PrintBtn />,
                <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowTable(true) }
                >
                    <MonetizationOnIcon />
                    Delta Cash Flows
                </Button>,                
                <Button 
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={()=> setShowGraph(true) }
                >
                    <BarChartIcon />
                    Delta Graph
                </Button>
            ]}
            >
                <div className={`${Style.resHolder} printable`}>
                {
                    myresult.map((aelem,aind)=>(
                    <ScrollerWrapper>
                    <>

                    {
                        mapper.map((outerElem,outerInd)=>(
                            <div key={outerInd} className={Style.groupcntwrapper}>
                                <div className={Style.groupcnt}>
                                    <div className={Style.gcHeader}>
                                        <p>{outerElem.name}</p>
                                    </div>

                                    <div className={`${Style.gcContent}`}>
                                 

                                    <div className={Style.inpTrow}>

                                        <div className={Style.inpTcell}>

                                        </div>


                                            {
                                                aelem.map((elem,ind)=>(
                                                    <div 
                                                    className={Style.inpTcell}
                                                    key={`fieldsname-${outerInd}${ind}`}
                                                    >
                                                        <div className={Style.scenarioSelector}>
                                                            {
                                                                (ind === aelem.length-1) ?
                                                                "Delta"
                                                                :
                                                                elem.name
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        {
                                            outerElem.data.map((innerElem,innerInd)=>(
                                                <React.Fragment key={`fieldsname-${outerInd}${innerInd}`}>
                                                    {
                                                        ((outerInd === 0 && innerInd === 1) || (outerInd === 0 && innerInd === 2)) ?
                                                        <></>
                                                        :
                                                        <div className={
                                                            `
                                                            ${Style.inpTrow}
                                                            ${
                                                                (
                                                                    (outerInd === 0 && innerInd === 0) ||
                                                                    (outerInd === 0 && innerInd === 7) ||
                                                                    (outerInd === 0 && innerInd === 9) ||
                                                                    (outerInd === 0 && innerInd === 11) ||
                                                                    (outerInd === 0 && innerInd === 15) ||
                                                                    (outerInd === 0 && innerInd === 17) ||
                                                                    (outerInd === 0 && innerInd === 19) ||
                                                                    (outerInd === 0 && innerInd === 21) ||
                                                                    (outerInd === 0 && innerInd === 23) ||
                                                                    (outerInd === 0 && innerInd === 25) ||
    
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 0) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 5) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 7) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 9) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 11) ||
                                                                    ((outerInd === 1 || outerInd === 2) && innerInd === 13) ||
    
                                                                    
                                                                    (outerInd === 3 && innerInd === 1) ||
                                                                    (outerInd === 3 && innerInd === 3) ||
                                                                    (outerInd === 3 && innerInd === 5) ||
                                                                    (outerInd === 3 && innerInd === 7) ||
                                                                    (outerInd === 3 && innerInd === 8)
                                                                ) ?
                                                                Style.rowDivider
                                                                :
                                                                ""
                                                            }
                                                            `
                                                        }>
                                                           <div className={Style.inpTcell}>
                                                                <div className={Style.resultInnerLeft}>
                                                                    {innerElem}
                                                                </div>
                                                            </div>
                                                    {
                                                        aelem.map((reselem,resind)=>(
                                                            <div 
                                                            className={Style.inpTcell}
                                                            key={`fieldsname-${outerInd}${resind}`}
                                                            >
                                                                <div className={`
                                                                inputCnt
                                                                ${Style.resultBox} 
                                                                ${resind === aelem.length-1 && Style.deltaTd}
                                                                ${
                                                                    (outerInd === 0 && innerInd ===0 && resind !== aelem.length-1) &&
                                                                    ((
                                                                        (reselem.output[outerInd].data[2].value != 0 && reselem.output[outerInd].data[innerInd].value < reselem.output[outerInd].data[2].value) || 
                                                                        (reselem.output[outerInd].data[1].value != 0 && reselem.output[outerInd].data[innerInd].value > reselem.output[outerInd].data[1].value )
                                                                    )
                                                                        ?
                                                                        "danger"
                                                                        :
                                                                        "green"
                                                                    )
                                                                }
                                                                ${
                                                                    (
                                                                        (outerInd === 0 && innerInd ===8 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===9 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===10 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===11 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===19 && resind !== aelem.length-1) ||
                                                                        (outerInd === 0 && innerInd ===20 && resind !== aelem.length-1) ||
                                                                        // (outerInd === 0 && innerInd ===19 && resind !== aelem.length-1) ||

                                                                        (outerInd === 1 && innerInd ===6 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===7 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===8 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===9 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===10 && resind !== aelem.length-1) ||
                                                                        (outerInd === 1 && innerInd ===11 && resind !== aelem.length-1) ||
                                                                        
                                                                        (outerInd === 2 && innerInd ===6 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===7 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===8 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===9 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===10 && resind !== aelem.length-1) ||
                                                                        (outerInd === 2 && innerInd ===11 && resind !== aelem.length-1) 
                                                   
                                                                    ) &&
                                                                    (
                                                                        reselem.output[outerInd].data[innerInd].value < 0 ? "danger" : "green"
                                                                    )
                                                                } 
                                                                ${
                                                                    
                                                                    (outerInd === 0 && (innerInd === 17 || innerInd === 18) && resind !== aelem.length-1) && 
                                                                    ((((reselem.output[outerInd].data[innerInd].value/reselem.output[0].data[0].value) * 100 > ( (reselem.cashFlow.map(elem=> elem.atrosRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  ) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }
                                                                ${
                                                                    
                                                                    ((outerInd === 1 || outerInd === 2) && (innerInd === 12 || innerInd === 13) && resind !== aelem.length-1) && 
                                                                    ((((reselem.output[0].data[innerInd+2].value/reselem.output[0].data[0].value) * 100 > ( (reselem.cashFlow.map(elem=> elem.atrosRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  ) ) ?
                                                                    "green"
                                                                    :
                                                                    "danger")
                                                                }

                                                                ${
                                                                    
                                                                    (outerInd === 2 && innerInd ===14 && resind !== aelem.length-1) &&
                                                                    (reselem.output[outerInd].data[innerInd].value < ( (reselem.cashFlow.map(elem=> elem.irrRate).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  ? "danger" : reselem.output[outerInd].data[innerInd].value != 11011 && "green")
                                                                    
                                                                }
                                                                ${
                                                                    
                                                                    ((outerInd === 3 && innerInd ===0 && resind !== aelem.length-1) || (outerInd === 3 && innerInd ===1 && resind !== aelem.length-1)) &&
                                                                    (
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > ( (reselem.cashFlow.map(elem=> elem.ot_above_x).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  && 
                                                                            reselem.output[outerInd].data[innerInd].value <= ( (reselem.cashFlow.map(elem=> elem.ot_above_y).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length ) 
                                                                        )? "danger" :
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > ( (reselem.cashFlow.map(elem=> elem.ot_above_y).reduce((a,b)=> a+b) || 0)/reselem.cashFlow.length )  && 
                                                                            reselem.output[outerInd].data[innerInd].value <= 8500
                                                                        ) ? "superdanger" : 
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].value > 8500 && "notpossiblevalue"
                                                                        )
                                                                    )
                                                                    
                                                                }

                                                                `}>
                                                                    {
                                                                        reselem.output[outerInd].data[innerInd].type === "currency" &&
                                                                        <CurrencyIconRenderer type={myCurrency} />
                                                                    }
                                                                    
                                                                    {
                                                                        (reselem.output[outerInd].data[innerInd].value) ? 
                                                                            (reselem.output[outerInd].data[innerInd].value != 11011) ?
                                                                                myCurrency === "INR" ?
                                                                                    NumberFormat.toINDstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                :
                                                                                    NumberFormat.toUSstandard((reselem.output[outerInd].data[innerInd].value).toFixed(2))
                                                                                    
                                                                            :
                                                                                // <AllInclusiveIcon />
                                                                                "NA"
                                                                        :
                                                                            0
                                                                    }
                                                                   
                                                                    {
                                                                        (
                                                                            reselem.output[outerInd].data[innerInd].type === "perc" && 
                                                                            reselem.output[outerInd].data[innerInd].value != 11011 
                                                                        ) &&
                                                                        <span className="signs">
                                                                        %
                                                                        </span>
                                                                    }
                                                                    </div>
                                                                
                                                            </div>
                                                        ))
                                                    }
                                                    </div>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                
                                    </div>

                                </div>
                            </div>
                        ))
                    }

                    </>
                </ScrollerWrapper>))
                }

                
                </div>
            </WrapperOutput>
        }
        {
            
            (showGraph) &&
            <WrapperOutput
            onBackPress={()=> setShowGraph(false) }
            name={["Home","Output","Delta","Graphs"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <GraphScreen 
                mapper={mapper}
                myresult={myresult.map(el=> [el[el.length -1]] )}
                />
            </WrapperOutput>
            
            
        }
        {
            showTable &&
            <WrapperOutput
            onBackPress={()=> setShowTable(false) }
            name={["Home","Output","Delta","Cash Flows"]}
            leftButtons={[
                <PrintBtn />
            ]}
            >
                <DeltaTableScreen
                    myresult={forwardResult}
                    prep={prep}
                    setPrep={setPrep}
                    submitableScenario={submitableScenario}
                />
            </WrapperOutput>
        }
        </div>
    )
}

export default ComparedScreen;