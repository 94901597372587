class StringOptimizer{

    updateCurrencyFormat(str:string,currency:string){

        switch(currency){
            case "GBP":
                return str.replace(/\$/g,"£");
            case "EUR":
                return str.replace(/\$/g,"€");
            case "JPY":
                return str.replace(/\$/g,"¥");
            case "INR":
                return str.replace(/\$/g,"₹");
            case "CNY":
                return str.replace(/\$/g,"¥");
            default:
                return str;
        }

    }

    nameToGraphicalName(val:string){
        let arr = val.trim().split(" ");

        let newarr = [];
        let innerArr = [];

        for(var i=0; i<arr.length; i++){
            if(i % 3 === 0){
                newarr.push(innerArr);
                innerArr = [];
            }

            innerArr.push(arr[i]);
        }

        if(innerArr.length > 0)
        newarr.push(innerArr);

        return newarr.map(elem=> (elem.join(" ")) );
    }
}

const StringOptimizerOb = new StringOptimizer();
export default StringOptimizerOb;