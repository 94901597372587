// React
import { useState } from 'react';

// Material UI Icons
import {
    Key as KeyIcon,
    Menu as MenuIcon,
    Person as PersonIcon,
    Security as SecurityIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';

// Material UI Components
import {
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    styled,
    Typography,
} from '@mui/material';

// Local Components
import Header from '../components/Header';
import AuthenticatedLayout from '../components/layouts/AuthenticatedLayout';
import Access from '../components/settings/Access';
import Profile from '../components/settings/Profile';
import Security from '../components/settings/Security';

const drawerWidth = 300;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

interface MainContentProps {
  open: boolean;
}

const MainContent = styled('div')<MainContentProps>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginLeft: open ? drawerWidth : 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: 'calc(100vh - 125px)',
  backgroundColor: 'white',
}));

interface MenuItem {
  text: string;
  icon: JSX.Element;
  component: JSX.Element;
}

const Settings = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>('Profile');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const menuItems: MenuItem[] = [
    { text: 'Profile', icon: <PersonIcon />, component: <Profile /> },
    { text: 'Access', icon: <KeyIcon />, component: <Access /> },
    { text: 'Security', icon: <SecurityIcon />, component: <Security /> },
  ];

  const handleDrawerToggle = () => setIsDrawerOpen(!isDrawerOpen);
  const handleMenuSelect = (text: string) => setSelectedMenu(text);

  return (
    <AuthenticatedLayout>
        <Header />
        <Box sx={{ display: 'flex', paddingTop:"77px"}}>
            <Drawer
            sx={{
                zIndex:1,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                },
            }}
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            >
            <DrawerHeader sx={{
                marginTop:"77px"
            }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <SettingsIcon /> Settings
                </Typography>
            </DrawerHeader>

            <List>
                {menuItems.map((item) => (
                <ListItem
                    key={item.text}
                    disablePadding
                >
                    <ListItemButton
                    selected={selectedMenu === item.text}
                    onClick={() => handleMenuSelect(item.text)}
                    sx={{
                        '&.Mui-selected': {
                        backgroundColor: 'rgba(25, 118, 210, 0.08)',
                        },
                    }}
                    >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
            </Drawer>

            <MainContent open={isDrawerOpen}>
            <Box sx={{ mb: 2 }}>
                <IconButton 
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
                >
                <MenuIcon />
                </IconButton>
            </Box>
            {menuItems.find(item => item.text === selectedMenu)?.component}
            </MainContent>
        </Box>
      <footer id="footer" style={{zIndex:"10",position:"relative"}}>
        &copy; Cornerstone Consulting Organizations
      </footer>
    </AuthenticatedLayout>
  );
};


export default Settings;