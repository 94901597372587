import { Modal } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import styles from "./style.module.css";

type propType={
  onClose:()=> void,
  targetElem:any,
  heading:string | undefined,
  msg:any
}
function HintGuide({onClose,targetElem, heading, msg}:propType) {

  const [msgCnt,setMsgcnt] = useState();



  const customRef = useCallback((ref:any) => {
    if(!ref) return;

    setMsgcnt(ref);

  }, []);

  const setPosition = ()=>{

    if(!msgCnt) return;

      let curr:any = msgCnt;

      const bound = targetElem.getBoundingClientRect();
      const elemHeight = targetElem.offsetHeight;
      const elemWidth = targetElem.offsetWidth;
      const elemX = bound.left;
      const elemY = bound.top;
      const cntWidth = curr?.offsetWidth;
      const cntHeight = curr?.offsetHeight;

      if( ((elemX + elemWidth + cntWidth) > window.innerWidth) && ( cntWidth > (elemX+elemWidth) )){
          curr!.style.left = "0px";
      }
      else if( ((elemX + elemWidth + cntWidth) > window.innerWidth) ){
          curr!.style.left = (elemX-cntWidth!)+"px";
      }
      else{
          curr!.style.left = (elemX+elemWidth)+"px";
      }


      if( bound.top + elemHeight + cntHeight > window.innerHeight){
          curr!.style.top = (elemY-cntHeight!)+"px";
        
      }
      else{
          curr!.style.top = (elemY+elemHeight)+"px";
      }
  }


  useEffect(()=>{

    if(!msgCnt) return;

    setPosition();
    window.addEventListener('resize', setPosition);

  },[msgCnt]);


  useEffect(()=>{

   return ()=>{

     window.removeEventListener('resize', setPosition);
   }

 },[]);


  return (
    <Modal
    open={true}
    onClose={onClose}
    >
      <div 
      className={styles.container} 
      ref={customRef}>
        <h4>{heading}</h4>
        <div dangerouslySetInnerHTML={{__html:msg}} />
      </div>
      
    </Modal>
  )
}

export default HintGuide;
