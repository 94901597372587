// External libraries
import { Print } from '@mui/icons-material';
import { Button } from 'antd';

// Local imports
import styles from '../styles/output.module.css';

interface PrintBtnProps {}

const PrintBtn: React.FC<PrintBtnProps> = () => {
    const printHandler = (): void => {
        window.print();
    }

    return (
        <Button
            type="text" 
            className={styles.rtBtn}
            onClick={printHandler}
        >
            <Print />
            Print
        </Button>
    )
}

export default PrintBtn;