// React
import { useState } from 'react';

// React Router
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

// Ant Design
import { Alert } from 'antd';
import Button from 'antd/es/button';

// Third Party
import OtpInput from 'react-otp-input';

// Local imports
import { Axios } from '../Axios';
import InputBox from '../components/input';
import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';
import Style from '../styles/login.module.css';
import pass_prefix from '../svg/pass-prefix.svg';

interface OtpFormData {
  otp: string;
  password: string;
  confirmPassword: string;
}

const Otp = ()=>{

    const [searchParams] = useSearchParams();
    const refId = searchParams.get("key");
    const navigator = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error,setError] = useState<string | undefined>();
    const [reError,setReError] = useState<string | undefined>();
    const [allowView,setAllowView] = useState(false);

    const [formData, setFormData] = useState<OtpFormData>({
        otp: '',
        password: '',
        confirmPassword: '',
    });

    const [reSuccess,setResuccess] = useState<boolean>(false);
    const [verified,setVerified] = useState<boolean>(false);
    const [success,setSuccess] = useState<boolean>(false);

    const [npassErr, setNpassErr] = useState<string | undefined>();

    const handleInputChange = (field: keyof OtpFormData) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormData(prev => ({
            ...prev,
            [field]: e.target.value,
        }));
    };

    const handleResend = async (): Promise<void> => {
        setReError(undefined);

        try {
            await Axios.post(`/api/v1/resend-otp`, {
                refId,
                type: 'forget',
            });

            setResuccess(true);
            setTimeout(() => {
                setResuccess(false);
            }, 2000);
        } catch (err: any) {
            if (err.response) {
                setReError(err.response.data.message || err.response.data.error);
            }
            setTimeout(() => {
                setReError(undefined);
            }, 2000);
        }
    };

    const handleSubmit = async (): Promise<void> => {
        setLoading(true);
        setError(undefined);

        try {
            await Axios.post(`/api/v1/verify`, {
                refId,
                type: 'forget',
                otp: formData.otp,
            });

            setVerified(true);
        } catch (err: any) {
            console.error('Verification error:', err);
            if (err.response) {
                if (err.code === 'ERR_BAD_REQUEST' && err.response.data.type === 0) {
                    setError('OTP cannot be empty!');
                } else {
                    setError(err.response.data.message || err.response.data.error);
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (): Promise<void> => {
        setLoading(true);

        try {
            await Axios.post(`/api/v1/reset-password`, {
                refId,
                otp: formData.otp,
                newPassword: formData.password,
                cPassword: formData.confirmPassword,
            });

            setSuccess(true);
        } catch (err: any) {
            if (err.response) {
                setNpassErr(err.response.data.message || err.response.data.err);
            }
        } finally {
            setLoading(false);
        }
    };

    const containerStyle: React.CSSProperties = {
        margin: '20px 0 0 0',
    };

    const otpContainerStyle: React.CSSProperties = {
        margin: '0 auto 20px auto',
        display: 'flex',
        justifyContent: 'space-between',
    };

    const alertStyle: React.CSSProperties = {
        minHeight: '20px',
        margin: '5px 0 20px 0',
        fontSize: '12px',
    };

    const successAlertStyle: React.CSSProperties = {
        ...alertStyle,
        fontSize: '16px',
        textAlign: 'center',
    };

    const linkStyle: React.CSSProperties = {
        color: '#656A72',
        textDecoration: 'none',
    };

    const linkTextStyle: React.CSSProperties = {
        color: '#2B80FF',
        fontSize: '14px',
    };

    const footerTextStyle: React.CSSProperties = {
        color: '#656A72',
        fontSize: '14px',
        margin: '30px 0 0 0',
        textAlign: 'center',
    };

    const rightPanelStyle: React.CSSProperties = {
        background: 'url("/images/forget_bk.png")',
        backgroundPosition: 'right center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };

    return (
        <>
        <NotAuthenticatedLayout>
        {
            <>
            <div id="main">
                <div className={Style.container}>

                <div className={Style.left}>
                    {
                        (!success && !verified) &&
                        <div className={Style.form}>
                        <img src='/images/cco-small.png' alt="logo" />
                        <h1 className={Style.title}>OTP</h1>
                        <h4 className={Style.subtitle}>we have sent one-time-password to your email.</h4>
                        <h4 className={Style.subtitle}>OTP gets expired in 2 minutes.</h4>

                        <div style={containerStyle}>
                        <OtpInput
                                value={formData.otp}
                                onChange={(value) => setFormData(prev => ({ ...prev, otp: value }))}
                                inputType='number'
                                numInputs={4}
                                containerStyle={otpContainerStyle}
                                inputStyle="otpInputStyle"
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                                />
                        </div>

                        {
                            error &&
                            <Alert 
                            type='error' 
                            message={error} 
                            showIcon 
                            style={alertStyle}
                            />
                        }
                    

                        <Button className='btn' type='text' loading={loading} onClick={handleSubmit}>Submit</Button>


                        <p style={{color:"#656A72",fontSize:"12px",margin:"30px 0 0 0",cursor:"pointer"}}>
                            Didn't receive the code? 
                                <span style={{color:"#2B80FF"}} onClick={handleResend}> Resend</span>
                            
                        </p>

                        {
                            reError &&
                            <Alert 
                            type='error' 
                            message={reError} 
                            showIcon 
                            style={alertStyle} 
                            />
                        }
                        
                        {
                            reSuccess &&
                            <Alert 
                            type='success'
                            message="OTP Sent Successfully!"
                            showIcon 
                            style={alertStyle} 
                            />
                        }

                    </div>
                    }

                    {

                        (!success && verified) &&
                        <div className={Style.form}>
                        <img src='/images/cco-small.png' alt="logo" />
                        <h1 className={Style.title}>Password</h1>
                        <h4 className={Style.subtitle}>Set a new password.</h4>

                        <div style={containerStyle}>
                        <InputBox 
                        type='password' 
                        image={<img src={pass_prefix} 
                        alt='logo' />} 
                        placeholder='Password'
                        value={formData.password}
                        onChange={handleInputChange('password')}
                        />
                         <InputBox 
                        type='password' 
                        image={<img src={pass_prefix} 
                        alt='logo' />} 
                        placeholder='Confirm Password'
                        value={formData.confirmPassword}
                        onChange={handleInputChange('confirmPassword')}
                        />
                        </div>

                        {
                            npassErr &&
                            <Alert 
                            type='error' 
                            message={npassErr} 
                            showIcon 
                            style={alertStyle} 
                            />
                        }
                        

                        <Button className='btn' type='text' loading={loading} onClick={handleResetPassword}>Set Password</Button>

                    </div>
                    }

                    {
                        success &&
                        <>

                       <div className={Style.form}>
                       <Alert 
                            type='success'
                            message="Password Reset Successfully"
                            showIcon 
                            style={successAlertStyle} 
                            />
                        <Link to="/" style={linkStyle}>
                        <p style={footerTextStyle}>
                            <span style={linkTextStyle}>Please Login to Continue!</span>
                        </p>
                        </Link>
                       </div>                            
                        </>
                    }
                    

                </div>

                <div className={Style.right} style={rightPanelStyle}></div>

                </div>

            </div>            
            </>
        }
        </NotAuthenticatedLayout>
        </>
    )
}

export default Otp;