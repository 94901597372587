import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from "react";

interface PassToggleProps {
    value: string;
}

const PassToggle = ({ value }: PassToggleProps): JSX.Element => {
    const [showPass, setShowPass] = useState<boolean>(false);

    const hidePassword = (text: string): string => {
        return text.split('').map(() => '*').join('');
    };

    const handleToggleVisibility = (): void => {
        setShowPass((prev) => !prev);
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <div>{showPass ? value : hidePassword(value)}</div>
            <div style={{ margin: '0 0 0 7px' }}>
                {!showPass ? (
                    <VisibilityIcon
                        style={{cursor:"pointer"}}
                        fontSize="small"
                        onClick={handleToggleVisibility}
                    />
                ) : (
                    <VisibilityOffIcon
                        style={{cursor:"pointer"}}
                        fontSize="small"
                        onClick={handleToggleVisibility}
                    />
                )}
            </div>
        </div>
    );
};

export default PassToggle;