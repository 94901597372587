class NumberFormat{

    toUSstandard(val:string=""){
        let isNegative = (val && val[0]) === "-" ? true : false;
        let data = val.replace(/-/g,"").split(".");
        let beforeDec = data[0];
        let decimal = data.length > 1 ? true : false;
        let afterDec = data.length > 1 ? data[1] : "";
        let prep = "";
        let count=0;
        for(var i=beforeDec.length-1; i>=0; i--){

            if(count === 3){
                prep = ","+prep;
                count = 0;
            }

            prep = beforeDec[i]+prep;
            count++;

        }
        if(isNegative)
        prep = "-"+prep;
        if(decimal)
        return prep+"."+afterDec;
        else
        return prep;
    }

    toINDstandard(val:string=""){
        let isNegative = (val && val[0]) === "-" ? true : false;
        let data = val.replace(/-/g,"").split(".");
        let beforeDec = data[0];
        let decimal = data.length > 1 ? true : false;
        let afterDec = data.length > 1 ? data[1] : "";
        let prep = "";
        let count=0;
        let matcher=3;
        for(var i=beforeDec.length-1; i>=0; i--){

            if(count === matcher){
                prep = ","+prep;
                count = 0;
                matcher = 2;
            }

            prep = beforeDec[i]+prep;
            count++;

        }
        if(isNegative)
        prep = "-"+prep;
        if(decimal)
        return prep+"."+afterDec;
        else
        return prep;
    }

    convertAccordingCurrency(curr:string,val:number){
       return curr === "INR" ?
        this.toINDstandard((val.toFixed(2)))
    :
        this.toUSstandard((val).toFixed(2))
    }

    toNumber(val:string){
        
        return val.replace(/[,a-zA-Z]+/g,"");
    }

    weekToMonth(val:number): number{
        return Math.round( (val * 4.3) );
    }
    weekToYear(val:number):number{
        return Math.round( (val * 4.3 * 12) );
    }
    monthToYear(val:number):number{
        return Math.round( (val * 12) );
    }
    monthToWeek(val:number):number{
        return Math.round( (val / 4.3) );
    }
    yearToWeek(val:number):number{
        return Math.round( (val / (4.3 * 12)) );
    }
    yearToMonth(val:number):number{
        return Math.round( (val / 12) );
    }

}

const NumberFormatOb = new NumberFormat();
export default NumberFormatOb;