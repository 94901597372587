import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ReactNode } from 'react';
import styles from './index.module.css';

interface ScrollerWrapperProps {
  children: ReactNode;
}

interface ScrollOptions {
  top: number;
  left: number;
  behavior: ScrollBehavior;
}

const ScrollerWrapper = ({ children }: ScrollerWrapperProps): JSX.Element => {
  const handleScroll = (element: HTMLElement, direction: 'left' | 'right'): void => {
    const scrollOptions: ScrollOptions = {
      top: 0,
      left: direction === 'left' ? -200 : 200,
      behavior: 'smooth'
    };
    element.scrollBy(scrollOptions);
  };

  const LeftArrow = (): JSX.Element => {
    const handleLeftClick = (e: React.MouseEvent<HTMLDivElement>): void => {
      const scrollContainer = (e.target as HTMLElement).parentNode?.children[1] as HTMLElement;
      handleScroll(scrollContainer, 'left');
    };

    return (
      <div
        className={`${styles.arrowBtn} non-printable`}
        style={{ marginRight: '5px' }}
        onClick={handleLeftClick}
      >
        <ArrowForwardIosIcon
          fontSize="small"
          style={{
            pointerEvents: 'none',
            transform: 'rotate(180deg)'
          }}
        />
      </div>
    );
  };

  const RightArrow = (): JSX.Element => {
    const handleRightClick = (e: React.MouseEvent<HTMLDivElement>): void => {
      const scrollContainer = (e.target as HTMLElement).parentNode?.children[1] as HTMLElement;
      handleScroll(scrollContainer, 'right');
    };

    return (
      <div
        className={`${styles.arrowBtn} non-printable`}
        style={{ marginLeft: '5px' }}
        onClick={handleRightClick}
      >
        <ArrowForwardIosIcon
          fontSize="small"
          style={{ pointerEvents: 'none' }}
        />
      </div>
    );
  };

  return (
    <div className={styles.scrollWrapper}>
      <LeftArrow />
      <div className={`${styles.scrollWrapperInner} scroller`}>
        <div className={styles.scrollWrapperContentHolder}>
          {children}
        </div>
      </div>
      <RightArrow />
    </div>
  );
};

export default ScrollerWrapper;