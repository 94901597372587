// External dependencies
import { PercentageOutlined } from "@ant-design/icons";
import { Alert, Input, InputRef } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

// Types and interfaces
import { newFormInpPropType } from "../../@types/user";

// Redux and state management
import { blurNumberInput, changeNumberInput, dropChangeNumberInput } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";

// Components
import CurrencyIconRenderer from "../CurrencyIconRendered";
import HintGuide from "../guide/HintGuide";

// Utilities and helpers
import NumberFormat from "../../utils/NumberFormat";
import StringOptimizer from "../../utils/StringOptimizer";

// Assets
import question_ico from "../../svg/question.svg";


const Number: React.FC<newFormInpPropType> = ({ activeIndex, index, data, currency, outerIndex }) => {
  const version = useSelector((state: RootState) => 
    state.calculator.tabs[state.calculator.activeTab].version
  );

  const [showMsg, setShowMsg] = useState<boolean>(false);
  const msgCnt = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<InputRef>(null);
  
  const myCurrency = useSelector((state: RootState) => 
    state.calculator.activeScreen?.currency || "USD"
  );
  
  const dispatch = useDispatch();

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      if (msgCnt.current && msgRef.current) {
        if (!msgCnt.current.contains(e.target as Node) && !msgRef.current.contains(e.target as Node)) {
          setShowMsg(false);
        }
      }
    };

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeNumberInput({
      value: NumberFormat.toNumber(e.target.value),
      outerIndex,
      innerIndex: index
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    dispatch(blurNumberInput({
      value: e.target.value,
      outerIndex,
      innerIndex: index
    }));
  };

  const handleDropChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(dropChangeNumberInput({
      value: e.target.value,
      outerIndex,
      innerIndex: index
    }));
  };

  const renderMessage = () => {
    if (!showMsg) return null;

    const versionNumber = parseInt(version?.replaceAll("v", "") || "0", 10);

    if (versionNumber > 10) {
      return (
        <HintGuide
          onClose={() => setShowMsg(false)}
          targetElem={msgRef?.current}
          heading={StringOptimizer.updateCurrencyFormat(data?.name || "", currency!)}
          msg={data?.msg}
        />
      );
    }

    return (
      <div className="formMsg" ref={msgCnt}>
        {versionNumber >= 3 ? (
          Array.isArray(data?.msg) && data?.msg.map((elem, idx) => (
            <p key={`msg${idx}`}>{elem}</p>
          ))
        ) : (
          <p>{data?.msg}</p>
        )}
      </div>
    );
  };

  return (
    <div className="formMainCnt">
      <p className="formTitle">
        {StringOptimizer.updateCurrencyFormat(data?.name || "", currency!)}
      </p>
      <div className={`formInputCnt ${data?.error ? "error" : ""}`}>
        {data?.currency && <CurrencyIconRenderer type={myCurrency} />}

        <Input
          className="mainInput"
          type="text"
          min={1}
          style={{ flex: "1" }}
          value={
            myCurrency === "INR"
              ? NumberFormat.toINDstandard(String(data?.value || ""))
              : NumberFormat.toUSstandard(String(data?.value || ""))
          }
          onChange={handleChange}
          disabled={data?.disabled}
          onBlur={handleBlur}
          ref={inputRef}
        />

        {data?.secDrop && (
          <select
            className="mainInput myselect"
            value={data?.secDrop.value}
            onChange={handleDropChange}
            style={{ maxWidth: "100px" }}
          >
            {data.secDrop.list.map((elem, idx) => (
              <option key={idx} value={elem.value}>
                {elem.label}
              </option>
            ))}
          </select>
        )}

        {data?.percent && <PercentageOutlined className="percentIcon" />}

        <img
          className="formQuestion"
          src={question_ico}
          alt="show input field description button"
          onClick={() => setShowMsg(true)}
          ref={msgRef}
        />
      </div>

      {data?.error && (
        <Alert
          style={{ fontSize: "12px", padding: "5px 10px" }}
          description="Mandatory Field"
          type="error"
          showIcon={false}
        />
      )}
      {renderMessage()}
    </div>
  );
};

export default Number;