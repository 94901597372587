const keys = {
     job_and_tool:"1R0L7S5H1B21545",
     sales_vol_mat: "6Z7V5H4J0M56817",
     machine_operator_summary: "3A9F8R3Z7U19758",
     std_dl: "4A9Z4Q5R4A739",
     abc_dl: "8S6F6C4T4A2734",
     operation_overview: "5H1G2W6L6M89149",
     operating_pattern:"2P9I8B6C5P83468",
     act_based_var: "6I4P1S3T5X68924",
     ent_act_based_var:"2C2B8V7K9L43605",
     investment: "6Y0B2J5R4S58032",
     off_std: "4X0T5P8P9T27522",
     financing_loan: "9A5W6U8O3Y53353"
}

export default keys;