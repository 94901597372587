import { CloseOutlined } from "@ant-design/icons";
import type { InputRef } from 'antd';
import { Input } from "antd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTouchHandler from "../../hooks/useTouchHandler";
import { removeJob, setActiveJob, updateJobName } from "../../redux/calculatorSlice";
import { RootState } from "../../redux/store";
import ModalBox from "../modal";

interface JobTabProps {
    name: string;
    index: number;
    type: number;
}

const JobTab = ({
    name,
    index,
    type
}: JobTabProps): JSX.Element => {
    const dispatch = useDispatch();
    const activeJob = useSelector((state: RootState) => state.calculator.activeJob);
    const allJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs);
    
    const [nameEdit, setNameEdit] = useState<boolean>(false);
    const [removeJobAlert, setRemoveJobAlert] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    
    const inp = useRef<InputRef>(null);
    const [touchAndHoldHandler, touchEnd] = useTouchHandler();

    const handleTabClick = (): void => {
        dispatch(setActiveJob(index));
    };

    const handleDoubleClick = (): void => {
        if (nameEdit) return;
        if (type === 0) {
            setNameEdit(true);
        } else {
            setError('Cannot change name for split screen !');
        }
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        dispatch(updateJobName(e.target.value));
    };

    const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        if (!e.target.value) {
            dispatch(updateJobName('untitled'));
        }
        setNameEdit(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            setNameEdit(false);
        }
    };

    const handleCloseClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
        setRemoveJobAlert(true);
    };

    const handleRemoveTab = (): void => {
        const result = dispatch(removeJob(index));
        if (result.payload === 0) {
            setError('Cannot remove job because it is open in Split Screen');
        }
        setRemoveJobAlert(false);
    };

    const clearError = (): void => {
        setError('');
    };

    const canShowClose = type === 0 
        ? allJobs.filter(elem => elem.type === 0).length > 1
        : true;

    return (
        <div
            className={`jobTab ${activeJob === index ? 'jobTabActive' : 'jobTabDeactive'}`}
            onClick={handleTabClick}
            onDoubleClick={handleDoubleClick}
            onTouchStart={(e) => {
                e.preventDefault();
                touchAndHoldHandler(handleDoubleClick);
            }}
            onTouchEnd={() => touchEnd()}
        >
            {nameEdit && type === 0 ? (
                <Input
                    type="text"
                    ref={inp}
                    style={{ color: 'black', minWidth: '180px' }}
                    value={name}
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    onKeyDownCapture={handleKeyDown}
                    autoFocus
                />
            ) : (
                <p className="tabName">{name}</p>
            )}

            {canShowClose && (
                <CloseOutlined
                    className="tabCloseBtn"
                    onClick={handleCloseClick}
                />
            )}

            {removeJobAlert && (
                <ModalBox
                    open={removeJobAlert}
                    header={type === 0 ? 'Job Removal !' : name}
                    message={
                        type === 0
                            ? `Are you sure you want to delete ${name} permanently ?`
                            : 'Are you sure you want to close this split screen ?'
                    }
                    onClose={() => setRemoveJobAlert(false)}
                    onOk={handleRemoveTab}
                    onCancel={() => setRemoveJobAlert(false)}
                />
            )}

            {error && (
                <ModalBox
                    open={Boolean(error)}
                    header="Alert !"
                    message={error}
                    onClose={clearError}
                    onOk={clearError}
                />
            )}
        </div>
    );
};

export default JobTab;