// External libraries
import { useDispatch, useSelector } from 'react-redux';

// React
import { useRef, useState } from 'react';

// UI Components & Icons
import { RightOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@mui/icons-material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton, Modal } from '@mui/material';
import { Button } from 'antd';

// Local imports
import { insertScenario } from '../../redux/calculatorSlice';
import { RootState } from '../../redux/store';
import InputBox from '../input';
import PopupMenu from '../menu/PopupMenu';
import ModalBox from '../modal';
import BottomTab from '../tabs/BottomTab';

interface Scenario {
  name: string;
  type: number;
}

interface ScenarioOption {
  value: string;
  label: string;
}

const ScenarioBar = (): JSX.Element => {
  const activeTabScenarios = useSelector((state: RootState) => state.calculator.activeTabScenarios);
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [newScenarioModal, setNewScenarioModal] = useState<boolean>(false);
  const [selectedNewScenario, setSelectedNewScenario] = useState<string>('0');
  const [newScenarioName, setNewScenarioName] = useState<string>('');
  const [newScenarioDesc, setNewScenarioDesc] = useState<string>('');
  const [error, setError] = useState<string>('');

  const addSBtn = useRef<HTMLButtonElement>(null);

  const newScenario = (): void => {
    setNewScenarioModal(true);
    setShowMenu(false);
  };

  const confirmScenarioCreation = (): void => {
    if (!newScenarioName) {
      setError('Please enter a name for new Scenario !');
      return;
    }
    if (!newScenarioDesc) {
      setError('Please enter a description for new Scenario !');
      return;
    }

    dispatch(insertScenario({
      from: parseInt(selectedNewScenario),
      myname: newScenarioName,
      mydesc: newScenarioDesc
    }));

    setNewScenarioModal(false);
    setSelectedNewScenario('0');
    setNewScenarioName('');
    setNewScenarioDesc('');
  };

  const handleCloseModal = (): void => {
    setNewScenarioModal(false);
  };

  const handleClearError = (): void => {
    setError('');
  };

  return (
    <>
      <div className="bottomBarCnt">
        <div className="barScrollCnt barScrollCollapsed">
          {activeTabScenarios.map((elem: Scenario, index: number) => (
            <BottomTab
              key={`bt${index}`}
              index={index}
              name={elem.name}
              type={elem.type}
              showClose={activeTabScenarios.length > 1}
            />
          ))}
        </div>

        <IconButton
          sx={{ margin: 'auto 15px auto 0', height: '35px', width: '35px' }}
          ref={addSBtn}
          onClick={() => setShowMenu(true)}
        >
          <ControlPointIcon
            fontSize="medium"
            className="addScenarioBtn"
          />
        </IconButton>
      </div>

      {showMenu && (
        <PopupMenu
          targetElem={addSBtn.current}
          onCloseTrigger={() => setShowMenu(false)}
        >
          <ul>
            <li onClick={newScenario}>New Scenario</li>
          </ul>
        </PopupMenu>
      )}

      {newScenarioModal && (
        <Modal
          className="modal"
          open
          onClose={handleCloseModal}
        >
          <div className="modalPopUp">
            <CloseOutlined
              className="popupClose"
              onClick={handleCloseModal}
            />
            <div className="mpuTop">
              <div>
                <InputBox
                  type="select"
                  placeholder="New Scenario From"
                  value={selectedNewScenario}
                  options={[
                    { value: '-1', label: 'Blank' },
                    ...activeTabScenarios
                      .filter((elem) => elem.type !== 2)
                      .map((elem, index) => ({
                        value: `${index}`,
                        label: elem.name
                      }))
                  ]}
                  onChange={(e) => setSelectedNewScenario(e.target.value)}
                />
                <InputBox
                  type="string"
                  placeholder="Scenario Name"
                  value={newScenarioName}
                  onChange={(e) => setNewScenarioName(e.target.value)}
                />
                <InputBox
                  type="textarea"
                  placeholder="Scenario Description"
                  value={newScenarioDesc}
                  onChange={(e) => setNewScenarioDesc(e.target.value)}
                />
              </div>
            </div>
            <div className="mpuBot">
              <Button
                className="mybtn-blue"
                type="text"
                onClick={confirmScenarioCreation}
              >
                Add Scenario <RightOutlined />
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {error && (
        <ModalBox
          open={Boolean(error)}
          header="Scenario Warning !"
          message={error}
          onClose={handleClearError}
          onOk={handleClearError}
        />
      )}
    </>
  );
};

export default ScenarioBar;