import { useEffect, useState } from 'react'
import { resultType } from '../../../@types/user'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import NumberFormat from '../../../utils/NumberFormat';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LoanNameFormatter from '../../../utils/LoanNameFormatter';

type loanType = {
    result:resultType,
    myCurrency:string,
    selectedLoan:string,
    compareJobs:boolean
}
type prepType = {
    sceName:string,
    loans:{
        jobName:string,
        data:any
    }[]

}[]

export default function SingleLoanScreen({result,myCurrency,selectedLoan,compareJobs}:loanType) {

    const activeTabSce = useSelector((state: RootState) => state.calculator.activeTabScenarios);
    const currentAllJobs =  useSelector((state: RootState) => state.calculator.activeScenarioJobs);
    const [prepLoans,setPrepLoans] = useState<prepType>([]);


    useEffect(()=>{

        let outerPrep:prepType = [];

        console.log("result from single loans", result);

        result.forEach((oelem,oind)=>{

            let prep:any ={
                sceName : oelem?.name,
                loans:[]
            }
    
            oelem?.output?.loans?.forEach((elem:any,ind:number) => {

                let jobname = compareJobs ? oelem?.name : currentAllJobs[ind].name;
                let data = getObjectFromArray(elem,selectedLoan);

                prep.loans.push({
                    jobName: jobname,
                    data:data
                })

            });

            outerPrep.push(prep);

        })

        setPrepLoans(outerPrep);

    },[]);

    useEffect(()=>{
        console.log("prep loans", prepLoans);
    },[prepLoans])


    const getObjectFromArray = (data:any[],name:string)=>{
        for( let i=0; i<data.length; i++ ){
            if(data[i].name?.toLowerCase() === name){
                return data[i];
            }
        }

        return {};
    }

  return (
    <div>
        {
            prepLoans.map((el,ind)=>(
                <div key={`tabAmm${ind}`} style={{margin:"50px 20px"}}>
                    <p className='loanScenarioHeader'>{!compareJobs ? "Scenario Name " : "Job Name"}: {el.sceName}</p>
                    {
                        el?.loans?.map((elem:any,index:number)=>(
                            <div key={`ltt${index}`}>
                                {
                                    !compareJobs &&
                                    <p className='loanJobHeader'>Job Name : {elem?.jobName}</p>
                                }
                            
                                {
             
                                    elem?.data?.data?.length > 0 ?
                                        <div>
                                            <div className='loanInnerHeader'>
                                                <p>Loan Name : {elem?.data?.name}</p>
                                                <p>Overall Interest : {NumberFormat.convertAccordingCurrency(myCurrency,elem?.data?.totalData.interest)}</p>
                                                <p>Overall Principal Redux: {NumberFormat.convertAccordingCurrency(myCurrency,elem?.data?.totalData.principalRedux)}</p>
                                            </div>
                                            <div
                                            className='loanContainer'
                                            >
                                            {
                                                elem?.data?.data.map((mel:any,mind:number)=>(
                                                    <div 
                                                    key={`mo${mind}`} 
                                                    >
                                                        <TableContainer sx={{padding:2,width:700}} component={Paper}>
                                                            <p>({mel.fiscalStart}) - ({mel.fiscalEnd})</p>
                                                            <Table aria-label="simple table">
                                                                <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Loan No.</TableCell>
                                                                    <TableCell align="right">PMT</TableCell>
                                                                    <TableCell align="right">Principal</TableCell>
                                                                    <TableCell align="right">Interest</TableCell>
                                                                    <TableCell align="right">PrincipalRedux</TableCell>
                                                                    <TableCell align="right">PrincipalBalance</TableCell>
                                                                </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                        
                                                                {
                                                                    mel.data.map((r:any,ri:number)=>(
                                                                        <TableRow 
                                                                        key={`lttnt${ri}`}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0} }}
                                                                        >
                                                                            <TableCell component="th" scope="row">{r.index}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PMT)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.Principal)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.Interest)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PrincipalRedux)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,r.PrincipalBalance)}</TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                }

                                                                    <TableRow 
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <TableCell component="th" scope="row">Year Total</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,mel.totalData.interest)}</TableCell>
                                                                            <TableCell align="right">{NumberFormat.convertAccordingCurrency(myCurrency,mel.totalData.principalRedux)}</TableCell>
                                                                            <TableCell align="right">-</TableCell>
                                                                        </TableRow>

                                                                    
                                                            
                                                                </TableBody>
                                                            </Table>
                                                    
                                                        </TableContainer>
                                                    </div>
                                                ))
                                            }
                                                
                                            </div>

                                        </div>
                                   
                                    :
                                    <div className='noLoanDataCnt'>
                                        <CreditScoreIcon />
                                        <p>No Loan Data found for <b>{LoanNameFormatter.format(selectedLoan)}</b></p>
                                    </div>
                                }
                            </div>
                        ))
                    }
                
                </div>
            ))
        }
      
    </div>
  )
}
