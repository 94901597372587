// External libraries
import { useSelector } from 'react-redux';

// React
import { FC } from 'react';

// Local imports
import { RootState } from '../redux/store';
import styles from '../styles/calc.module.css';

interface ClockProps {}

const Clock: FC<ClockProps> = () => {
    const time = useSelector((state: RootState) => state.calculator.time );

    return(
        <>
        <div className={styles.clock}>{time}</div>
        <div className={styles.clockCircle}>
        {
            time.split(",").map((elem,index)=> (<p key={index}>{elem}</p>))
        }
        </div>
        </>
    )
}

export default Clock;