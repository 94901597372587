// External libraries
import { PercentageOutlined } from '@ant-design/icons';
import { DatePicker, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';

// React
import { CSSProperties, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Local imports
import { RootState } from '../redux/store';
import NumberFormat from '../utils/NumberFormat';
import CurrencyIconRenderer from './CurrencyIconRendered';

interface InputProps {
    image?: React.ReactNode,
    type: string,
    placeholder: string,
    value?: any,
    defaultValue?: string[],
    onChange?: (e: any) => void,
    onDeselect?: (e: any) => void,
    onInputKeyDown?: (e: any) => void,
    index?: number,
    currency?: boolean,
    percent?: boolean,
    options?: { label: string; value: string | number }[],
    style?: CSSProperties,
    containerStyle?: CSSProperties,
    disabled?: boolean
}

const InputBox: React.FC<InputProps> = (props) => {
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "USD");

    useEffect(() => {
        if (props?.type === "date" && !props?.value) {
            if (props.onChange) props.onChange({ target: { value: dayjs().format("YYYY-MM-DD") } });
        }
    }, []);

    const renderInput = () => {
        switch (props.type) {
            case "text":
            case "password":
                return (
                    <div className="formInputCnt" style={props.containerStyle}>
                        {props.image && props.image}
                        {props.type === "text" ? (
                            <Input 
                                className="mainInput" 
                                placeholder={props.placeholder} 
                                autoComplete="none" 
                                value={props.value} 
                                onChange={props.onChange}
                                style={props.style}
                            />
                        ) : (
                            <Input.Password 
                                className="mainInput" 
                                placeholder={props.placeholder} 
                                autoComplete="none"
                                value={props.value}
                                onChange={props.onChange}
                                style={props.style}
                            />
                        )}
                    </div>
                );

            case "string":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{ height: "35px" }}>
                            <Input 
                                type="text"
                                className="mainInput"
                                autoComplete="none" 
                                value={props.value} 
                                onChange={props.onChange} 
                                style={props.style}
                            />
                        </div>
                    </>
                );

            case "textarea":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{ height: "auto" }}>
                            <TextArea 
                                className="mainInput"
                                rows={3}
                                autoComplete="none" 
                                value={props.value} 
                                onChange={props.onChange}
                                style={props.style}
                            />
                        </div>
                    </>
                );

            case "Cnumber":
            case "Cnumber2":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{ height: "35px" }}>
                            {props.currency && <CurrencyIconRenderer type={myCurrency} />}
                            <Input 
                                type="text"
                                className="mainInput"
                                autoComplete="none" 
                                value={
                                    myCurrency === "INR" ? NumberFormat.toINDstandard(props.value) : NumberFormat.toUSstandard(props.value)
                                }
                                onChange={(e) => {
                                    e.target.value = NumberFormat.toNumber(e.target.value);
                                    if (props.onChange) props.onChange(e);
                                }}
                                style={props.style}
                                disabled={props.disabled}
                            />
                            {props.percent && <PercentageOutlined className="percentIcon" />}
                        </div>
                    </>
                );

            case "select":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{ height: "35px" }}>
                            <select
                                className="mainInput"
                                onChange={(e) => {
                                    props.onChange && props.onChange(e);
                                }}
                                value={props.value}
                                style={props.style}
                            >
                                {props.options?.map((elem, index) => (
                                    <option key={index} value={elem.value}>{elem.label}</option>
                                ))}
                            </select>
                        </div>
                    </>
                );

            case "multiSelect":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{
                            minHeight: "35px",
                            width: "auto",
                            height: "auto"
                        }}>
                            <Select
                                mode="multiple"
                                className="mainInput"
                                style={props.style}
                                placeholder="Please select"
                                defaultValue={props.defaultValue || []}
                                onChange={props.onChange}
                                dropdownStyle={{
                                    zIndex: 101,
                                    width: "100%"
                                }}
                                size='large'
                                variant={"borderless"}
                                options={props.options}
                                onDeselect={props.onDeselect}
                                onInputKeyDown={props.onInputKeyDown}
                            />
                        </div>
                    </>
                );

            case "date":
                return (
                    <>
                        <label className="label">{props.placeholder}</label>
                        <div className="formInputCnt" style={{ height: "35px" }}>
                            <DatePicker 
                                className="mainInput"
                                style={{ position: "relative", ...props.style }}
                                popupStyle={{ zIndex: 10000 }}
                                value={dayjs(props.value, "YYYY-MM-DD")} 
                                onChange={(date, dateString) => {
                                    const prep = !dateString ? dayjs().format("YYYY-MM-DD") : dateString;
                                    if (props.onChange) props.onChange({ target: { value: prep } });
                                }} />
                        </div>        
                    </>
                );

            default:
                return null;
        }
    };

    return <>{renderInput()}</>;
};

export default InputBox;