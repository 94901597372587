import { useEffect, useState } from 'react';

interface ModalBoxProps {
  header: string;
  message: string;
  onOk?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  open: boolean;
}

const ModalBox = ({
  header,
  message,
  onOk,
  open,
  onClose,
  onCancel
}: ModalBoxProps): JSX.Element => {
  const [active, setActive] = useState<boolean>(open);

  useEffect(() => {
    setActive(open);
  }, [open]);

  const handleOk = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    onOk?.();
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    onCancel?.();
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (onClose) {
      setActive(false);
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  if (!active) {
    return <></>;
  }

  return (
    <div className="modalBox" onClick={handleClose}>
      <div className="modalInner" onClick={handleModalClick}>
        <div className="modalHeader">
          <p>{header}</p>
        </div>

        <div className="modalContent">
          {message}
        </div>

        <div className="modalBtnCnt">
          {onCancel && (
            <button
              className="modalBtn"
              onClick={handleCancel}
            >
              Cancel
            </button>
          )}
          {onOk && (
            <button
              className="modalBtn"
              onClick={handleOk}
            >
              OK
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalBox;