// External dependencies
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

// Types and interfaces
import { newFormInpPropType } from "../../@types/user";
import { RootState } from "../../redux/store";

// Redux and state management
import { 
  doublePopUpSecSubmit, 
  oneScreenPopupValueChange 
} from "../../redux/calculatorSlice";

// Components
import HintGuide from "../guide/HintGuide";
import InputBox from "../input";
import Question from "../../svg/question";

interface DataItem {
  type: string;
  name: string;
  value: string;
  perc?: boolean;
  required?: boolean;
}

interface DataArray {
  columnName: string;
  data: DataItem[];
}


const OneScreenPopup: React.FC<newFormInpPropType> = ({
  activeIndex,
  index,
  data,
  outerIndex,
  currency
}) => {
  const version = useSelector((state: RootState) => 
    state.calculator.tabs[state.calculator.activeTab].version
  );

  const [showPopup, setPopUp] = useState<boolean>(false);
  const [showMsg, setShowMsg] = useState<boolean>(false);
  const [pass, setPass] = useState<boolean>(false);
  
  const msgCnt = useRef<HTMLDivElement>(null);
  const msgRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleWindowClick = (e: MouseEvent) => {
      if (msgCnt.current && msgRef.current) {
        if (!msgCnt.current.contains(e.target as Node) && !msgRef.current.contains(e.target as Node)) {
          setShowMsg(false);
        }
      }
    };

    window.addEventListener('click', handleWindowClick);
    return () => window.removeEventListener('click', handleWindowClick);
  }, []);

  useEffect(() => {
    checkPass();
  }, [data?.dataArr]);

  const checkPass = () => {
    let completedRequired = 0;
    let totalRequired = 0;

    data?.dataArr?.forEach(element => {
      element.data.forEach(elem => {
        if (elem.required) {
          if (elem.value) {
            completedRequired++;
          }
          totalRequired++;
        }
      });
    });

    setPass(completedRequired === totalRequired);
  };

  const handleVerify = () => {
    if (data?.required) {
      const hasError = data.dataArr?.some(element =>
        element.data.some(elem => !elem.value)
      );

      setPass(!hasError);

      dispatch(doublePopUpSecSubmit({
        value: JSON.stringify({ error: hasError }),
        outerIndex,
        innerIndex: index
      }));
    } else {
      dispatch(doublePopUpSecSubmit({
        value: JSON.stringify({ error: false }),
        outerIndex,
        innerIndex: index
      }));
      
      checkPass();
    }

    setPopUp(false);
  };

  const handleInputChange = (columnIndex: number, itemIndex: number, value: string) => {
    dispatch(oneScreenPopupValueChange({
      value: JSON.stringify({
        index: columnIndex,
        iindex: itemIndex,
        data: value
      }),
      outerIndex,
      innerIndex: index
    }));
  };

  const renderMessage = () => {
    if (!showMsg) return null;

    const versionNumber = parseInt(version?.replaceAll("v", "") || "0", 10);

    if (versionNumber > 10) {
      return (
        <HintGuide
          onClose={() => setShowMsg(false)}
          targetElem={msgRef?.current}
          heading={data?.name}
          msg={data?.msg}
        />
      );
    }

    return (
      <div className="formMsg" ref={msgCnt}>
        {versionNumber >= 3 ? (
            Array.isArray(data?.msg) && data?.msg.map((elem, idx) => (
                <p key={`msg${idx}`}>{elem}</p>
            ))
        ) : (
          <p>{data?.msg}</p>
        )}
      </div>
    );
  };

  return (
    <>
      <Modal
        className="modal"
        open={showPopup}
        onClose={handleVerify}
      >
        <div className="modalPopUp">
          <CloseOutlined
            className="popupClose"
            onClick={() => setPopUp(false)}
          />
          <div className="mpugTop">
            {data?.dataArr?.map((elem, columnIndex) => (
              <div key={`column-${columnIndex}`}>
                <h4 style={{ margin: "10px 0", color: "#24336D" }}>
                  {elem.columnName}
                </h4>
                {elem.data.map((item, itemIndex) => (
                  <InputBox
                    key={`input-${columnIndex}-${itemIndex}`}
                    type={item.type}
                    placeholder={item.name}
                    value={item.value}
                    percent={item.perc}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                      handleInputChange(columnIndex, itemIndex, e.target.value)
                    }
                  />
                ))}
              </div>
            ))}
          </div>

          <div className="mpuBot">
            <Button className="mybtn-blue" type="text" onClick={handleVerify}>
              Done <RightOutlined />
            </Button>
          </div>
        </div>
      </Modal>

      <div className="formMainCnt">
        <p className="formTitle">{data?.name}</p>
        <div
          className={`
            formInputCnt 
            ${data?.error ? "doublePopupempty" : ""} 
            ${pass ? "doublePopupfilled" : ""}
          `}
        >
          <div
            className="mainInput myselect"
            style={{
              height: "100%",
              flex: "1",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              color: "gray"
            }}
            onClick={() => setPopUp(true)}
          >
            Click to edit
          </div>

          <Question
            className="formQuestion"
            onClick={() => setShowMsg(true)}
            myRef={msgRef}
            error={data?.error || false}
          />
        </div>

        {data?.error && (
          <Alert
            style={{ fontSize: "12px", padding: "5px 10px" }}
            description="One or more sub-fields are Empty!"
            type="error"
            showIcon={false}
          />
        )}
        {renderMessage()}
      </div>
    </>
  );
};

export default OneScreenPopup;